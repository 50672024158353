export const ProjectsReducer = (state = [], action) => {
  switch (action.type) {
    case "GETPROJECTS":
      return { ...action.payload };
    default:
      return state;
  }
};
export const ProjectSearchReducer = (state = [], action) => {
  switch (action.type) {
    case "SEARCHPROJECT":
      return { ...action.payload };
    default:
      return state;
  }
};

export const selectedProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDPROJECT":
      return { ...action.payload };
    default:
      return state;
  }
};

export const deleteProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETESELECTEDPROJECT":
      return { ...action.payload };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import AnnouncementsGridTable from "./AnnouncementsGridTable";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { actionGetAllAnnouncements } from "../../../actions";
import { useDispatch } from "react-redux";
import AddAnnouncementSlidingMenu from "./AddAnnouncementSlidingMenu";
import DeleteAnnouncementModal from "./DeleteAnnouncementModal";
import ImageModal from "../../../components/ui/modal/imagemodal/ImageModal";


export default function Announcements() {
  
  useEffect(() => {
    getAnnouncements();
  }, []);

  const dispatch = useDispatch();
  
  const [pageSize, setPageSize] = useState(8);
  const [currentAnnouncement, setCurrentAnnouncement] = useState();
  const [uploadedImage, setUploadedImage] = useState();

  const getAnnouncements = async (myPageIndex) => {
    dispatch(actionGetAllAnnouncements(1, pageSize));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [imageViewerIsOpen, setImageViewerIsOpen] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
    setImageViewerIsOpen(false);
  }
  function imageViewerHandler(image){
    setUploadedImage(image);
    setImageViewerIsOpen(true);
    setGrayoutHandler(true);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentAnnouncement(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }

  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler}  />}
        {slideMenuIsOpen && <AddAnnouncementSlidingMenu onClickCloseHandler={slideMenuCloserHandler} />}
        {deleteModalOpan && <DeleteAnnouncementModal CurrentAnnouncement={currentAnnouncement} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getAnnouncements} />}
        {imageViewerIsOpen && <ImageModal FileType={"Image"} UploadedImage={uploadedImage} onClickCloseHandler={slideMenuCloserHandler}/>}
        <AnnouncementsGridTable PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} onClickImageViewer={imageViewerHandler}  />
      </div>
    </div>
  );
}


import { React } from "react";
import { useSelector } from "react-redux";
import "./css/layout.css";
// import CardView from "./widgets/cardviews/CardView";
import CardViewUsers from "./widgets/cardviews/CardViewUsers";
import CardViewUnits from "./widgets/cardviews/CardViewUnits";
import CardViewExpenses from "./widgets/cardviews/CardViewExpenses";
import CardViewServices from "./widgets/cardviews/CardViewServices";
import PlateView from "./widgets/plateview/PlateView";

export default function LandingPage() {
  const mySelectedServiceRules = useSelector((state) => state.user);

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {/* {mySelectedServiceRules.decodedToken ? <div>{mySelectedServiceRules.decodedToken.unique_name}</div> : null} */}
        <div className="LayoutWidgetsContainer">
          <div className="WidgetContainer">1</div>
          <div className="WidgetContainer">
            <div className="LayoutWidgetsContainer">
              <CardViewUsers />
              <CardViewUnits />
              <CardViewServices />
              <CardViewExpenses />
            </div>
          </div>
          <div className="WidgetContainer">3</div>
          <div className="WidgetContainer">
            <PlateView />
          </div>
        </div>
      </div>
    </div>
  );
}

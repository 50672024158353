import React, { useState, useEffect } from "react";
import Loading from "../../../components/ui/loading/Loading";
import { withRouter } from "react-router-dom";
import { getNoneAssociatedPlateById, PutANoneAssociatedLicensePlate } from "../../../Services/managementServices";
import { toast } from "react-toastify";
import { PlateCharacters } from "../licenseplate/PlatesCharacters";

function EditNoneAssociatedLicensePlate({ history }) {
  const selectedCheckBox = history.location.state.id;

  const [NoneAssociatedLicensePlateFirstNumber, setNoneAssociatedLicensePlateFirstNumber] = useState("");
  const [NoneAssociatedLicensePlateSecondNumber, setNoneAssociatedLicensePlateSecondNumber] = useState("");
  const [NoneAssociatedLicensePlateCharacter, setNoneAssociatedLicensePlateCharacter] = useState("");
  const [NoneAssociatedLicensePlateCityCode, setNoneAssociatedLicensePlateCityCode] = useState("");
  const [NoneAssociatedLicensePlateNote, setNoneAssociatedLicensePlateNote] = useState("");
  const [character, setCharacter] = useState("");

  useEffect(() => {
    fillStates();
  }, []);

  const fillStates = async () => {
    const { data, status } = await getNoneAssociatedPlateById(selectedCheckBox);
    if (status === 200) {
      setNoneAssociatedLicensePlateFirstNumber(data.firstNumber);
      setNoneAssociatedLicensePlateSecondNumber(data.secondNumber);
      setNoneAssociatedLicensePlateCityCode(data.cityCode);
      setNoneAssociatedLicensePlateCharacter(PlateCharacters.find((x) => x.english === data.character).value);
    }
  };

  const updateNoneAssociatedLicensePlateHandler = async (e) => {
    e.preventDefault();

    try {
      const { status } = await PutANoneAssociatedLicensePlate(
        selectedCheckBox,
        NoneAssociatedLicensePlateFirstNumber,
        NoneAssociatedLicensePlateSecondNumber,
        PlateCharacters.find((x) => x.farsi === NoneAssociatedLicensePlateCharacter).value,
        NoneAssociatedLicensePlateCityCode,
        NoneAssociatedLicensePlateNote
      );

      if (status === 200) {
        toast.success("تغییرات با موفقیت اعمال شد");
        fillStates();
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.ErrorDescription : "Something went wrong!");
    }
  };

  return (
    <div className="ContentBody">
      <div className="BodyPadding">
        <div className="EditPageThreeColumnNoBG">
          <div className="TabInfoContainer">
            <div class="PostHeaderTitle">اطلاعات پلاک</div>
            <div className="TabInfoGridNum">
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">شماره اول </div>
                <input className="SlidingMenuTextBox" value={NoneAssociatedLicensePlateFirstNumber} onChange={(e) => setNoneAssociatedLicensePlateFirstNumber(e.target.value)} />
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">شماره دوم </div>
                <input className="SlidingMenuTextBox" value={NoneAssociatedLicensePlateSecondNumber} onChange={(e) => setNoneAssociatedLicensePlateSecondNumber(e.target.value)} />
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">نوع کاربری</div>
                <div className="SlidingMenuTextBox DropDownContainer">
                  <select
                    value={NoneAssociatedLicensePlateCharacter}
                    className="DropDownSelectNoFlag"
                    name="UsertypeId"
                    id="UsertypeId"
                    defaultValue="Choose a Device Type"
                    onChange={(e) => {
                      setNoneAssociatedLicensePlateCharacter(e.target.value);
                    }}>
                    <option value="Choose a Device Type" disabled>
                      لطفا انتخاب کنید
                    </option>

                    {PlateCharacters
                      ? PlateCharacters.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.farsi}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">کد شهر </div>
                <input className="SlidingMenuTextBox" value={NoneAssociatedLicensePlateCityCode} onChange={(e) => setNoneAssociatedLicensePlateCityCode(e.target.value)} />
              </div>

              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">پیام ورود پلاک</div>
                <textarea className="SlidingMenuTextArea" id="ProjectDescription" value={NoneAssociatedLicensePlateNote} onChange={(e) => setNoneAssociatedLicensePlateNote(e.target.value)} />
                <div className="SlidingMenuMoreDetail">این پیام هنگام ورود پلاک به پارکینگ به مدیران واحد نمایش داده می شود.</div>
              </div>
            </div>
            {NoneAssociatedLicensePlateFirstNumber && NoneAssociatedLicensePlateSecondNumber && NoneAssociatedLicensePlateCharacter && NoneAssociatedLicensePlateCityCode ? (
              <div className="LabelAndTextBoxContainer">
                <button type="sumbit" onClick={(e) => updateNoneAssociatedLicensePlateHandler(e)} class="SaveButton" id="SaveButton">
                  <span class="icon-SaveButton"></span>ذخیره
                </button>
              </div>
            ) : (
              <div className="LabelAndTextBoxContainer">
                <button type="sumbit" disabled class="SaveButton ButtonDisabled" id="SaveButton">
                  <span class="icon-SaveButton"></span>ذخیره
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EditNoneAssociatedLicensePlate);

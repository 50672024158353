import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { postABillingManagement } from "../../../Services/managementServices";

function AddBillingManagementSlidingMenu(props) {
  const [BillingManagementTitle, setBillingManagementTitle] = useState("");
  const [BillingManagementCost, setBillingManagementCost] = useState("");

  const reset = () => {
    setBillingManagementTitle("");
    setBillingManagementCost("");
  };

  const addBillingManagementItemHandler = async (e) => {
    e.preventDefault();
    const { status } = await postABillingManagement(BillingManagementTitle, BillingManagementCost);

    if (status === 200) {
      reset();
      toast.success("شارژ با موفقیت ثبت شد.");
      props.onClickCloseHandler();
      props.onClickRefresh();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اعمال شارژ نسبت متر مربع</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">عنوان شارژ</div>
          <input className="SlidingMenuTextBox" id="announcementTitle" value={BillingManagementTitle} onChange={(e) => setBillingManagementTitle(e.target.value)} />
        </div>
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">مبلغ شارژ به ازای هر متر مربع</div>
          <input className="SlidingMenuTextBox" id="announcementTitle" value={BillingManagementCost} onChange={(e) => setBillingManagementCost(e.target.value)} />
        </div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            لغو
          </div>

          {BillingManagementTitle && BillingManagementCost ? (
            <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addBillingManagementItemHandler(e)}>
              ذخیره
            </button>
          ) : (
            <button disabled className="SlidingMenuSavebutton ButtonDisabled">
              ذخیره
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddBillingManagementSlidingMenu);

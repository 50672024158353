import http from "./httpServices";
import config from "./config.json";

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Residents     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllResidents = (username, name, projectId, userType, unitId, page, size) => {
  return http.get(
    `${config.PardikURL}/Resident?${username !== "" ? "username=" + username + "&" : ""}${name !== "" ? "name=" + name + "&" : ""}${projectId !== "" ? "projectId=" + projectId + "&" : ""}${userType !== "" ? "userType=" + userType + "&" : ""}${
      unitId !== "" ? "unitId=" + unitId + "&" : ""
    }page=${page}&size=${size}`
  );
};
export const getResidentById = (id) => {
  return http.get(`${config.PardikURL}/Resident/${id}`);
};
export const PostAResident = (username, password, firstName, lastName, mobile, units, gender, projectId, userType) => {
  return http.post(`${config.PardikURL}/Resident`, {
    username: username,
    password: password,
    firstName: firstName,
    lastName: lastName,
    mobile: mobile,
    projectId: projectId,
    units: units,
    gender: gender,
    userType: userType,
  });
};
export const PostAResidentInProject = (username, password, firstName, lastName, mobile, gender, projectId, userType) => {
  return http.post(`${config.PardikURL}/Resident`, {
    username: username,
    password: password,
    firstName: firstName,
    lastName: lastName,
    mobile: mobile,
    projectId: projectId,
    gender: gender,
    userType: userType,
  });
};
export const deleteResidentById = (residentId, UnitId) => {
  return http.delete(`${config.PardikURL}/Resident/${residentId}/${UnitId}`);
};
export const PutAssignRoleToResident = (id, roles) => {
  return http.put(`${config.PardikURL}/Resident/AssignRoleToUser/${id}`, { roleIds: roles });
};
// export const PutAResident = (id, fullName, password, mobileNumber) => {
//   return http.put(`${config.PardikURL}/Resident/UpdateResident/${id}`, { fullName, password, mobileNumber });

export const PutAResident = (id, password, firstName, lastName, mobile, userType, projectId, gender, userName) => {
  return http.put(`${config.PardikURL}/Resident/${id}`, { password, firstName, lastName, mobile, userType, projectId, gender, userName });
};
export const getAllResidentsUnitAdmin = () => {
  return http.get(`${config.PardikURL}/Resident/unit-admins`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙐𝙨𝙚𝙧𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getStaffById = (id) => {
  return http.get(`${config.PardikURL}/Users/${id}`);
};
export const PostAStaff = (email, mobile, userName, password, firstName, lastName) => {
  return http.post(`${config.PardikURL}/Users`, { email, mobile, userName, password, firstName, lastName });
};
export const getAllStaffs = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Users/GetAll`, { pageIndex, pageSize });
};
export const deleteStaffById = (id) => {
  return http.delete(`${config.PardikURL}/Users/${id}`);
};
export const PutAssignRoleToStaff = (id, roles) => {
  return http.put(`${config.PardikURL}/Users/AssignRoleToUser/${id}`, { roleIds: roles });
};
export const PutAStaff = (id, name, lastName, email, password) => {
  return http.put(`${config.PardikURL}/Users/UpdateUser/${id}`, { name, lastName, email, password });
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙤𝙡𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllRoles = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Roles/GetAll`, { pageIndex, pageSize });
};
export const PostARole = (title, description, userType) => {
  return http.post(`${config.PardikURL}/Roles`, { title, description, userType });
};
export const getRoleById = (id) => {
  return http.get(`${config.PardikURL}/Roles/${id}`);
};
export const deleteRoleById = (id) => {
  return http.delete(`${config.PardikURL}/Roles/${id}`);
};
export const PutARole = (id, title, description, resources, userType) => {
  return http.put(`${config.PardikURL}/Roles/${id}`, { title, description, resources: { resourceItemIds: resources }, userType });
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙤𝙡𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllProjects = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Project/GetAll`, { pageIndex, pageSize });
};
export const getSearchedProject = (name) => {
  return http.get(`${config.PardikURL}/Project/by-name/${name}`);
};
export const PostAProject = (name, information, amenities, imageKeys) => {
  return http.post(`${config.PardikURL}/Project`, { name, information, amenities, imageKeys });
};
export const getProjectById = (id) => {
  return http.get(`${config.PardikURL}/Project/${id}`);
};
// export const deleteProjectById = (id) => {
//   return http.delete(`${config.PardikURL}/Projects/${id}`);
// };
export const PutAProject = (id, name, information, numberOfUnits, numberOfPlates, amenities, imageKeys, logoFileKey) => {
  return http.put(`${config.PardikURL}/Project/${id}`, { name, information, numberOfUnits, numberOfPlates, amenities, imageKeys, logoFileKey });
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙚𝙨𝙤𝙪𝙧𝙘𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllResources = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Resources/GetAll`, { pageIndex, pageSize });
};
export const getResourceById = (id) => {
  return http.get(`${config.PardikURL}/Resources/${id}`);
};
export const PostAResource = (title, description) => {
  return http.post(`${config.PardikURL}/Resources`, { title, description });
};
export const PutAResourceItem = (resourceId, resourceName, resourceKey) => {
  return http.put(`${config.PardikURL}/Resources/AddResourceItem/${resourceId}`, { resourceName, resourceKey });
};
export const deleteResourceById = (id) => {
  return http.delete(`${config.PardikURL}/Resources/${id}`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Service Types     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllServiceTypes = (pageIndex, pageSize, searchList) => {
  return http.post(`${config.PardikURL}/ServiceTypes/GetAll`, { pageIndex, pageSize, searchList });
};
export const PostAServiceRule = (ServiceRulesTitle, ServiceRulesPrice, startTimeRestriction, endTimeRestriction, serviceRulesisPrivate, isTimeAttached) => {
  return http.post(`${config.PardikURL}/ServiceRules`, { title: ServiceRulesTitle, price: ServiceRulesPrice, fromTime: startTimeRestriction + ":00", endTime: endTimeRestriction + ":00", isPrivate: serviceRulesisPrivate, isTimeAttached });
};
export const PostAServiceType = (fileKey, title, description) => {
  return http.post(`${config.PardikURL}/ServiceTypes`, { fileKey, title, description });
};
export const getServiceTypeById = (id) => {
  return http.get(`${config.PardikURL}/ServiceTypes/${id}`);
};
export const PutAServiceType = (id, title, fileKey, description, isActive, isScheduleRequired, isMapIncluded) => {
  return http.put(`${config.PardikURL}/ServiceTypes/${id}`, { title, fileKey, description, isActive, isScheduleRequired, isMapIncluded });
};
export const deleteServiceTypeById = (id) => {
  return http.delete(`${config.PardikURL}/ServiceTypes/${id}`);
};
export const deleteServiceTypeScheduleById = (id, day, ruleId) => {
  return http.delete(`${config.PardikURL}/ServiceTypes/RemoveSchedule/${id}`, { data: { day, ruleId } });
};

export const getServicetypeDevicesById = (id) => {
  return http.get(`${config.PardikURL}/ServiceTypes/GetServiceTypeDevices/${id}`);
};
export const PutServicetypeDevicesById = (id, deviceName, deviceIp, deviceTypeId) => {
  return http.put(`${config.PardikURL}/ServiceTypes/AddServiceTypeDevice/${id}`, { deviceName, deviceIp, deviceTypeId });
};
export const PutServicetypeDeviceItemById = (id, deviceId, deviceName, deviceIp, deviceTypeId) => {
  return http.put(`${config.PardikURL}/ServiceTypes/UpdateServiceTypeDevice/${id}/${deviceId}`, { deviceName, deviceIp, deviceTypeId });
};
export const DeleteServicetypeDeviceItemById = (id, deviceId) => {
  return http.delete(`${config.PardikURL}/ServiceTypes/DeleteServiceTypeDevice/${id}/${deviceId}`);
};
export const PutServiceTypeMapInformation = (id, mapInformation, mapImageKey, icon) => {
  return http.put(`${config.PardikURL}/ServiceTypes/SetServiceTypeMapInformation/${id}`, { mapInformation, mapImageKey, icon });
};
export const getServiceMapDetails = (id) => {
  return http.get(`${config.PardikURL}/ServiceTypes/GetServiceTypeMap/${id}`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Service Rules     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllServiceRules = (pageIndex, pageSize, searchList) => {
  return http.post(`${config.PardikURL}/ServiceRules/GetAll`, { pageIndex, pageSize, searchList });
};
export const getServiceRuleById = (id) => {
  return http.get(`${config.PardikURL}/ServiceRules/${id}`);
};
export const PutAServiceRule = (id, title, price, startTime, endTime, isPrivate) => {
  return http.put(`${config.PardikURL}/ServiceRules/${id}`, { title, price, startTime: startTime + ":00", endTime: endTime + ":00", isPrivate });
};
export const PutAServiceTariff = (id, day, ruleId) => {
  return http.put(`${config.PardikURL}/ServiceTypes/AddServiceSchedule/${id}`, { day, ruleId });
};
export const deleteServiceRuleById = (id) => {
  return http.delete(`${config.PardikURL}/ServiceRules/${id}`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Device types     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllDevicetypes = (pageIndex, pageSize, searchList) => {
  return http.get(`${config.PardikURL}/DeviceTypes`);
};
export const postADevicetype = (deviceModel, deviceType) => {
  return http.post(`${config.PardikURL}/DeviceTypes`, { deviceModel, deviceType });
};
export const getAllDevicecategories = () => {
  return http.get(`${config.PardikURL}/DeviceTypes/GetDeviceCategories`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Gates     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllGates = () => {
  return http.get(`${config.PardikURL}/gate`);
};
export const postAGate = (title, ip, command, code) => {
  return http.post(`${config.PardikURL}/gate`, { title, ip, command, code });
};
export const postAGateById = (id) => {
  return http.get(`${config.PardikURL}/gate/${id}`);
};
export const deleteAGateById = (id) => {
  return http.delete(`${config.PardikURL}/gate/${id}`);
};
export const getAllGatesStats = () => {
  return http.get(`${config.PardikURL}/gate/stats`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     BillingManagement     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllBillingManagements = (pageIndex, pageSize) => {
  return http.get(`${config.PardikURL}/Charge?page=${pageIndex}&size=${pageSize}`);
};
export const postABillingManagement = (title, cost) => {
  return http.post(`${config.PardikURL}/Charge/generate-unit-square`, { title, cost });
};
export const postABillingManagementById = (id) => {
  return http.get(`${config.PardikURL}/Charge/${id}`);
};

export const getAllTransactionsByChargeId = (chargeId, pageIndex, pageSize) => {
  return http.get(`${config.PardikURL}/Charge/transaction/${chargeId}?page=${pageIndex}&size=${pageSize}`);
};
export const DownloadAllTransactionsByChargeId = (chargeId) => {
  return http.get(`${config.PardikURL}/charge/charge-report/${chargeId}`);
};
export const getAllUnitTransactionsByChargeId = (unitId, pageIndex, pageSize) => {
  return http.get(`${config.PardikURL}/Charge/unit-charge-transaction/${unitId}?page=${pageIndex}&size=${pageSize}`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     License Plates     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllLicensePlates = (pageIndex, pageSize, fullText, unitId) => {
  return http.get(`${config.PardikURL}/Plate/${pageIndex}/${pageSize}${"?"}${fullText !== "" ? "fullText=" + fullText + "&" : ""}${unitId !== "" ? "unitId=" + unitId + "&" : ""}`);
};

export const getPlateById = (id) => {
  return http.get(`${config.PardikURL}/Plate/${id}`);
};

export const PutALicensePlate = (id, username, password, firstName, lastName, mobile, userType) => {
  return http.put(`${config.PardikURL}/Plate/${id}`, { username, password, firstName, lastName, mobile, userType });
};

export const PostALicensePlate = (firstNumber, secondNumber, character, cityCode, UnitId, plateNote) => {
  return http.post(`${config.PardikURL}/Plate`, { firstNumber, secondNumber, character, cityCode, UnitId, plateNote });
};

export const PostValidPlates = (validPlates) => {
  return http.post(`${config.PardikURL}/Plate/bulk`, validPlates);
};

export const Syncplate = () => {
  return http.get(`${config.PardikURL}/syncplate`);
};

export const deleteLicensePlateById = (licensePlateId) => {
  return http.delete(`${config.PardikURL}/Plate/${licensePlateId}`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     License Plates     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     None Associated License Plates     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllNoneAssociatedLicensePlates = (pageIndex, pageSize, fullText, unitId) => {
  return http.get(`${config.PardikURL}/GuestPlate/${pageIndex}/${pageSize}${"?"}${fullText !== "" ? "fullText=" + fullText + "&" : ""}${unitId !== "" ? "unitId=" + unitId + "&" : ""}`);
};

export const getNoneAssociatedPlateById = (id) => {
  return http.get(`${config.PardikURL}/GuestPlate/${id}`);
};

export const PutANoneAssociatedLicensePlate = (id, username, password, firstName, lastName, mobile, userType) => {
  return http.put(`${config.PardikURL}/GuestPlate/${id}`, { username, password, firstName, lastName, mobile, userType });
};

export const PostANoneAssociatedLicensePlate = (firstNumber, secondNumber, character, cityCode, UnitId, plateNote, title) => {
  return http.post(`${config.PardikURL}/GuestPlate`, { firstNumber, secondNumber, character, cityCode, UnitId, plateNote, title });
};

export const PostValidNoneAssociatedPlates = (validPlates) => {
  return http.post(`${config.PardikURL}/GuestPlate/bulk`, validPlates);
};

export const SyncNoneAssociatedplate = () => {
  return http.get(`${config.PardikURL}/syncplate`);
};

export const deleteNoneAssociatedLicensePlateById = (NoneAssociatedLicensePlateId) => {
  return http.delete(`${config.PardikURL}/GuestPlate/${NoneAssociatedLicensePlateId}`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     None Associated License Plates     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Units     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllUnits = (pageIndex, pageSize, searchList) => {
  return http.post(`${config.PardikURL}/unit/GetAll`, { pageIndex, pageSize, searchList });
};
export const getUnitById = (id) => {
  return http.get(`${config.PardikURL}/unit/${id}`);
};
export const PutAUnitById = (
  id,
  unitTitle,
  unitField,
  unitFloor,
  unitNumber,
  unitNumberOfPlates,
  numberOfActivePlates,
  unitInfo,
  unitPhone,
  unitType,
  unitResidency,
  unitDateOfResidence,
  unitNumberOfMembers,
  unitIsEmpty,
  unitSquare,
  unitLogo,
  unitBlock
) => {
  return http.post(`${config.PardikURL}/unit/${id}`, {
    title: unitTitle,
    field: unitField,
    floor: unitFloor,
    number: unitNumber,
    numberOfPlates: unitNumberOfPlates,
    numberOfActivePlates: numberOfActivePlates,
    information: unitInfo,
    phone: unitPhone,
    type: unitType,
    residency: unitResidency,
    dateOfResidence: unitDateOfResidence,
    numberOfMembers: unitNumberOfMembers,
    isEmpty: unitIsEmpty,
    square: unitSquare,
    logoFileKey: unitLogo,
    block: unitBlock,
  });
};
export const PostAUnit = (unitNumber, unitNumberOfPlates, numberOfActivePlates, unitFloor, unitType, unitResidency, unitDateOfResidenceGregorian, unitNumberOfMembers, unitIsEmpty, unitSquare, unitAdmin, unitBlock) => {
  return http.post(`${config.PardikURL}/unit`, {
    number: unitNumber,
    numberOfPlates: unitNumberOfPlates,
    numberOfActivePlates: numberOfActivePlates,
    floor: unitFloor,
    type: unitType,
    residency: unitResidency,
    dateOfResidence: unitDateOfResidenceGregorian,
    numberOfMembers: unitNumberOfMembers,
    isEmpty: unitIsEmpty,
    square: unitSquare,
    adminUser: unitAdmin,
    block: unitBlock,
  });
};

export const deleteUnitById = (UnitId) => {
  return http.delete(`${config.PardikURL}/unit/${UnitId}`);
};

export const ValidateLicensePlates = (units) => {
  return http.post(`${config.PardikURL}/unit/validate`, units);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Units     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

import React, { useState, useEffect } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { postADevicetype } from "../../../Services/managementServices";
import { actionGetAllDevicecategories } from "./../../../actions/index";
import { useDispatch, useSelector } from "react-redux";

function AddDeviceSlidingMenu(props) {
  useEffect(() => {
    getProductTypes();
  }, []);

  const currentProductTypes = useSelector((state) => state.allDevicesCategories);
  const dispatch = useDispatch();

  const [deviceModel, setDeviceModel] = useState("");
  const [deviceType, setDeviceType] = useState("");

  const getProductTypes = async () => {
    dispatch(actionGetAllDevicecategories(0, 100));
  };

  const reset = () => {
    setDeviceModel("");
    setDeviceType("");
  };

  const addNewDeviceHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await postADevicetype(deviceModel, deviceType);
    if (status === 200) {
      reset();
      toast.success("Product Type added Successfully");
      props.onClickCloseHandler();
      props.history.push("/Devices");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">دستگاه جدید اضافه کنید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">مدل دستگاه</div>
        <input className="SlidingMenuTextBox" id="resourceName" value={deviceModel} onChange={(e) => setDeviceModel(e.target.value)} />
        <div className="SlidingMenuMoreDetail">The name of the Resource should be written here. This name will later be used for defined staff and can be assigned to them.</div>
      </div>
      <div className="HalfWidthInsideFlex">
        <div className="FullWidthContainer">
          <div className="SlidingMenuItemTitle">نوع دستگاه</div>
          <div className="SlidingMenuTextBox DropDownContainer">
            <select
              className="DropDownSelectNoFlag"
              name="DevicetypeId"
              id="DevicetypeId"
              defaultValue="Choose a Device Type"
              onChange={(e) => {
                setDeviceType(e.target.value);
              }}>
              <option value="Choose a Device Type" disabled>
                لطفا انتخاب کنید
              </option>
              {currentProductTypes
                ? currentProductTypes.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="ButtonsContainer">
          <button type="sumbit" className="SaveButton" onClick={(e) => addNewDeviceHandler(e)}>
            <span className="icon-SaveButton"></span>
            ذخیره
          </button>
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddDeviceSlidingMenu);

import React, { useState } from "react";
import { toast } from "react-toastify";
import "../../../components/ui/modal/modal.css";
import { DeleteAMirrorCompliment } from "../../../Services/mirrorServices";

export default function DeleteFoodModal(props) {
  const [deleteTextBox, setDeleteTextBox] = useState("");
  const deleteHandler = async (e, id) => {
    e.preventDefault();

    try {
      const { status } = await DeleteAMirrorCompliment(id);
      if (status === 200) {
        toast.success("عملیات حذف با موفقیت انجام شد");
        props.onClickCloseHandler();
        props.onClickRefresh();
      }
    } catch (ex) {
      toast.error(ex.response.data.ErrorDescription);
    }
  };

  return (
    <form className="DeleteModalContainer">
      <div className="DeleteModalInsideContainer">
        <div className="DeleteIconInsideModal">
          <span className="icon-closeX DeleteIconBorder"></span>
        </div>
        <div className=" HorizontallyCenteredText">
          <div className="ModalDetail">
            شما در حال حذف کردن کامل خوشامدگویی <span className="DeleteText">{props.CurrentMirrorCompliments.title}</span> هستید. برای تایید لطفا کلمه <span className="DeleteText">Delete</span> را با حفظ کوچک یا بزرگ بودن حروف در کادر زیر بنویسید.
          </div>
          <input className="FullWidthContainer ModalTextBox HorizontallyCenteredText" onChange={(e) => setDeleteTextBox(e.target.value)} id="ServiceTypesTitle" />
        </div>
        <div className="DeleteModalButtons">
          {deleteTextBox === "Delete" ? (
            <button onClick={(e) => deleteHandler(e, props.CurrentMirrorCompliments.id)} type="sumbit" className="SaveButton">
              حذف
            </button>
          ) : (
            <div className="SaveButton ButtonDisabled">حذف</div>
          )}
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            بستن
          </div>
        </div>
      </div>
    </form>
  );
}

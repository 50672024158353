import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostARole } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddRoleSlidingMenu(props) {
  const [roleTitle, setRoleTitle] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [roleUserType, setRoleUserType] = useState("");

  const reset = () => {
    setRoleTitle("");
    setRoleDescription("");
  };

  const addNewRoleHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await PostARole(roleTitle, roleDescription, roleUserType);
    if (status === 200) {
      reset();
      toast.success("Role added Successfully");
      props.onClickCloseHandler();
      props.history.push("/Roles");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن نقش جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">عنوان نقش</div>
          <input className="SlidingMenuTextBox" id="roleTitle" value={roleTitle} onChange={(e) => setRoleTitle(e.target.value)} />
          <div className="SlidingMenuMoreDetail">این عنوان معرف نقش تعریف شده برای سهولت در تشخیص نقش در تخصیصبه کاربر می باشد.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuTextBox DropDownContainer">
            <select className="DropDownSelectNoFlag" name="RoleUserType" id="RoleUserType" onChange={(e) => setRoleUserType(e.target.value)}>
              <option value="Choose a Device Type" disabled selected>
                نوع کاربری
              </option>
              <option value="Admin">ادمین</option>
              <option value="BuildingAdmin ">ادمین ساختمان</option>
              <option value="UnitAdmin">ادمین واحد</option>
              <option value="Resident">ساکن</option>
            </select>
          </div>
          <div className="SlidingMenuMoreDetail">نوع کاربری مورد نظر را برای این نقش انتخاب کنید</div>
        </div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">توضیحات نقش</div>
        <textarea className="SlidingMenuTextArea" id="roleDescription" value={roleDescription} onChange={(e) => setRoleDescription(e.target.value)} />
        <div className="SlidingMenuMoreDetail">توضیحاتی مختصر برای نقش برای تعریف شده.</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="ButtonsContainer">
          {roleTitle && roleDescription && roleUserType !== null ? (
            <button type="sumbit" className="SaveButton" onClick={(e) => addNewRoleHandler(e)}>
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <button disabled type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </button>
          )}
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddRoleSlidingMenu);

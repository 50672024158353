import React, { useState, useEffect } from "react";
import Loading from "../../../components/ui/loading/Loading";
import { withRouter } from "react-router-dom";
import { getResidentById, PutAResident } from "../../../Services/managementServices";
import { toast } from "react-toastify";

function EditResident({ history }) {
  const selectedCheckBox = history.location.state.id;

  // const currentUserTypes = useSelector((state) => state.allDevicesCategories);
  const currentUserTypes = ["Admin", "BuildingAdmin", "UnitAdmin", "Resident"];

  const [residentUsername, setResidentUsername] = useState("");
  const [residentPasswrod, setResidentPassword] = useState("");
  const [residentRepeatPassword, setResidentRepeatPassword] = useState("");
  const [residentFirstName, setResidentFirstName] = useState("");
  const [residentLastName, setResidentLastName] = useState("");
  const [residentMobile, setResidentMobile] = useState("");
  const [userType, setUserType] = useState("");
  const [residentProjectId, setResidentProjectId] = useState("");
  const [residentProject, setResidentProject] = useState("");
  const [residentGender, setResidentGender] = useState("");

  useEffect(() => {
    fillStates();
  }, []);

  const fillStates = async () => {
    const { data, status } = await getResidentById(selectedCheckBox);
    if (status === 200) {
      console.log(data);
      setResidentUsername(data.username);
      setResidentFirstName(data.firstName);
      setResidentLastName(data.lastname);
      setResidentMobile(data.mobileNumber);
      setResidentProject(data.projectName);
      setResidentProjectId(data.projectId);
      setResidentGender(data.genderType);
      setUserType(data.userType);
    }
  };

  const updateResidentHandler = async (e) => {
    e.preventDefault();

    try {
      const { status } = await PutAResident(selectedCheckBox, residentPasswrod, residentFirstName, residentLastName, residentMobile, userType, residentProjectId, residentGender, residentUsername);
      if (status === 200) {
        toast.success("تغییرات با موفقیت اعمال شد");
        fillStates();
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.ErrorDescription : "Something went wrong!");
    }
  };

  return (
    <div className="ContentBody">
      <div className="BodyPadding">
        <div className="ContentBody">
          <div className="BodyPadding">
            <div className="EditPageTwoColumnNoBG">
              <div className="EditPageTwoColumnItemWithBG">
                <div className="ContentBody">
                  <form
                    className="formGrid"
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateResidentHandler(e);
                    }}>
                    <div className="WidthFull">
                      <div className="FullWidthGrid4Columns">
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">نام کاربری</div>
                          <input disabled className="SlidingMenuTextBox" value={residentUsername} id="residentUsername" />
                          <div className="SlidingMenuMoreDetail">نام کاربری مختص واحد ثبت شده و غیر قابل تغییر.</div>
                        </div>

                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">رمز عبور</div>
                          <input className="SlidingMenuTextBox" id="StaffPassword" type="password" value={residentPasswrod ? residentPasswrod : null} onChange={(e) => setResidentPassword(e.target.value)} />
                          <div className="SlidingMenuMoreDetail">فقط در صورت تمایل در تغییر رمز غبور کاربر پر شود</div>
                        </div>

                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">تکرار رمز عبور</div>
                          <input className="SlidingMenuTextBox" id="StaffRepeatPassword" type="password" value={residentRepeatPassword ? residentRepeatPassword : null} onChange={(e) => setResidentRepeatPassword(e.target.value)} />
                          <div className="SlidingMenuMoreDetail">تکرار رمز عبور جدید در صورت تمایل به تغییر رمز عبور</div>
                        </div>
                        <div className="FullWidthContainer ">
                          <div className="SlidingMenuItemTitle">نام </div>
                          <input className="SlidingMenuTextBox" value={residentFirstName} onChange={(e) => setResidentFirstName(e.target.value)} />
                          <div className="SlidingMenuMoreDetail">نام کاربر اینجا نوشته شود</div>
                        </div>
                        <div className="FullWidthContainer ">
                          <div className="SlidingMenuItemTitle">نام خانوادگی </div>
                          <input className="SlidingMenuTextBox" value={residentLastName} onChange={(e) => setResidentLastName(e.target.value)} />
                          <div className="SlidingMenuMoreDetail">نام خانوادگی کاربر اینجا نوشته شود</div>
                        </div>
                        <div className="FullWidthContainer ">
                          <div className="SlidingMenuItemTitle">موبایل</div>
                          <input className="SlidingMenuTextBox" value={residentMobile} onChange={(e) => setResidentMobile(e.target.value)} />
                          <div className="SlidingMenuMoreDetail">شماره موبایل کاربر </div>
                        </div>
                        <div className="FullWidthContainer ">
                          <div className="SlidingMenuItemTitle">نوع کاربری</div>
                          {console.log(userType)}
                          <div className="SlidingMenuTextBox DropDownContainer">
                            <select
                              value={userType}
                              className="DropDownSelectNoFlag"
                              name="UsertypeId"
                              id="UsertypeId"
                              defaultValue="Choose a Device Type"
                              onChange={(e) => {
                                setUserType(e.target.value);
                              }}>
                              <option value="Choose a Device Type" disabled>
                                لطفا انتخاب کنید
                              </option>

                              {currentUserTypes
                                ? currentUserTypes.map((item, index) => (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </div>
                          <div className="SlidingMenuMoreDetail">شماره موبایل کاربر </div>
                        </div>
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">پروژه</div>
                          <input className="SlidingMenuTextBox" disabled value={residentProject} />
                          <div className="SlidingMenuMoreDetail">پروژه (ساختمان) تخصیص داده شده به این کاربر. </div>
                        </div>
                      </div>
                    </div>

                    {residentPasswrod === residentRepeatPassword && residentFirstName && residentLastName && residentMobile ? (
                      <button type="button" onClick={(e) => updateResidentHandler(e)} class="SaveButton" id="SaveButton">
                        <span class="icon-SaveButton"></span>ذخیره
                      </button>
                    ) : (
                      <button type="button" className="SaveButton ButtonDisabled" id="SaveButton">
                        <span className="icon-SaveButton"></span>
                        ذخیره
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EditResident);

import React, { useEffect, useState } from "react";
import AddBuildingResidentSlidingMenu from "../../buildingresidents/AddBuildingResidentSlidingMenu";
import DeleteBuildingResidentModal from "../../buildingresidents/DeleteBuildingResidentModal";
import GrayedOut from "../../../../components/ui/grayedout/GrayedOut";
import { useDispatch } from "react-redux";
import { actionGetAllResidents } from "../../../../actions";
import UnitResidentsGridtable from "./UnitResidentsGridtable";

export default function UnitResidents(props) {
  useEffect(() => {
    getBuildingResidents();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentBuildingResidents, setCurrentBuildingResidents] = useState();

  const getBuildingResidents = async (myPageIndex) => {
    dispatch(actionGetAllResidents("", "", "", "", props.UnitId, 1, pageSize));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentBuildingResidents(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <>
      {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
      {slideMenuIsOpen && <AddBuildingResidentSlidingMenu UnitId={props.UnitId} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getBuildingResidents} />}
      {deleteModalOpan && <DeleteBuildingResidentModal UnitId={props.UnitId} CurrentBuildingResidents={currentBuildingResidents} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getBuildingResidents} />}
      <UnitResidentsGridtable UnitId={props.UnitId} onClickRefresh={getBuildingResidents} PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
    </>
  );
}

import React from "react";

export default function CardViewUsers() {
  return (
    <div className="CardViewContainerUsers">
      <div className="CardIconContainer">
        <span className="icon-user CardIconBackground"></span>
      </div>
      <div className="CardViewElements ">
        <div className="CardViewTitleInfo">تعداد کاربران مجموعه: 160</div>
        <div className="CardViewSupplementaryInfo">تعداد مدیران واحد: 123</div>
        <div className="CardViewSupplementaryInfo">تعداد ساکنین: 37</div>
      </div>
    </div>
  );
}

export const gatesReducer = (state = [], action) => {
  switch (action.type) {
    case "GETGATES":
      return [...action.payload];
    default:
      return state;
  }
};
export const selectedgateReducer = (state = [], action) => {
  switch (action.type) {
    case "GETSELECTEDGATE":
      return { ...action.payload };
    default:
      return state;
  }
};
export const gatesStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETGATESSTATS":
      return { ...action.payload };
    default:
      return state;
  }
};

import { combineReducers } from "redux";
import { rolesReducer, selectedRoleReducer, deleteRoleReducer } from "./roles";
import { usersReducer, selectedUserReducer, deleteUserReducer } from "./users";
import { resourcesReducer, selectedResourceReducer } from "./resources";
import { announcementsReducer, deleteAnnouncementItemReducer, deleteAnnouncementReducer, selectedAnnouncementReducer } from "./announcements";
import { isLoggedReducer, userReducer } from "./auth";
import { LanguagesReducer } from "./settings";
import { hotelServicesReducer, selectedhotelServiceReducer } from "./hotelservices";
import { mealTimesReducer, selectedMealTimeReducer } from "./mealtime";
import { foodsReducer, selectedFoodReducer } from "./foods";
import { foodCategoryReducer, selectedFoodCategoryReducer } from "./foodcategories";
import { mirrorComplimentsReducer, selectedMirrorComplimentReducer } from "./mirrorcompliments";
import { aboutHotelReducer } from "./abouthotel";
import { productTypesReducer } from "./productTypes";
import { featureTypesReducer } from "./featureTypes";
import { devicesCategoriesReducer, devicesReducer } from "./devices";
import { deleteResidentReducer, ResidentsReducer, selectedResidentReducer } from "./residents";
import { selectedServiceRulesReducer, selectedServiceTypesReducer, serviceRulesReducer, serviceTypeDevicesByIdReducer, serviceTypesReducer } from "./services";
import { mirrorWeatherCitiesReducer } from "./weatherforecast";
import { ProjectsReducer, selectedProjectReducer, ProjectSearchReducer } from "./projects";
import { LicensePlatesReducer } from "./licensePlate";
import { UnitsReducer, selectedUnitReducer } from "./units";
import { gatesReducer, gatesStatsReducer, selectedgateReducer } from "./gates";
import { NoneAssociatedLicensePlatesReducer } from "./NoneAssociatedlicensePlate";
import { billingManagementsReducer, selectedBillingManagementReducer, selectedTransactionByChargeId, selectedTransactionByUnitId } from "./billingmanagements";

export const allReducers = combineReducers({
  allRoles: rolesReducer,
  selectedRole: selectedRoleReducer,
  allUsers: usersReducer,
  selectedUser: selectedUserReducer,
  deleteUser: deleteUserReducer,
  allResidents: ResidentsReducer,
  selectedResident: selectedResidentReducer,
  deleteResident: deleteResidentReducer,
  allResources: resourcesReducer,
  selectedResources: selectedResourceReducer,
  deleteRoles: deleteRoleReducer,
  user: userReducer,
  isLogged: isLoggedReducer,
  allAnnouncements: announcementsReducer,
  selectedAnnouncement: selectedAnnouncementReducer,
  deleteAnnouncement: deleteAnnouncementReducer,
  deleteAnnouncementItem: deleteAnnouncementItemReducer,
  allLanguages: LanguagesReducer,
  allHotelServices: hotelServicesReducer,
  allMealTimes: mealTimesReducer,
  SelectedMealTime: selectedMealTimeReducer,
  allFoodCategories: foodCategoryReducer,
  selectedFoodCategory: selectedFoodCategoryReducer,
  allFoods: foodsReducer,
  selectedFood: selectedFoodReducer,
  SelectedHotelService: selectedhotelServiceReducer,
  allMirrorCompliments: mirrorComplimentsReducer,
  allAbouthotel: aboutHotelReducer,
  allServicetypeDevicesById: serviceTypeDevicesByIdReducer,
  selectedMirrorCompliment: selectedMirrorComplimentReducer,
  allProductTypes: productTypesReducer,
  allFeatureTypes: featureTypesReducer,
  allServiceRules: serviceRulesReducer,
  selectedServiceRules: selectedServiceRulesReducer,
  selectedServiceTypes: selectedServiceTypesReducer,
  allServiceTypes: serviceTypesReducer,
  allDevices: devicesReducer,
  allDevicesCategories: devicesCategoriesReducer,
  allWeatherCities: mirrorWeatherCitiesReducer,
  allProjects: ProjectsReducer,
  selectedProject: selectedProjectReducer,
  searchedProject: ProjectSearchReducer,
  allLicensePlates: LicensePlatesReducer,
  allNoneAssociatedLicensePlates: NoneAssociatedLicensePlatesReducer,
  allGatesStats: gatesStatsReducer,
  allUnits: UnitsReducer,
  selectedUnit: selectedUnitReducer,
  allGates: gatesReducer,
  selectedGate: selectedgateReducer,
  allBillingManagements: billingManagementsReducer,
  selectedBillingManagement: selectedBillingManagementReducer,
  selectedTransAction: selectedTransactionByChargeId,
  allSelectedTransactionByUnitId: selectedTransactionByUnitId,
});

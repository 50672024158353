import React, { useState } from "react";
import { toast } from "react-toastify";
import "../../../components/ui/modal/modal.css";
import { deleteAnnouncementById } from "../../../Services/mirrorServices";

export default function DeleteAnnouncementModal(props) {
  const [deleteTextBox, setDeleteTextBox] = useState("");
  const deleteHandler = async (e, id) => {
    e.preventDefault();

    const { status } = await deleteAnnouncementById(id);
    if (status === 200) {
      toast.success("Deleted Successfully");
      props.onClickCloseHandler();
      props.onClickRefresh();
    }
  };

  return (
    <form className="DeleteModalContainer">
      <div className="DeleteModalInsideContainer">
        <div className="DeleteIconInsideModal">
          <span className="icon-closeX DeleteIconBorder"></span>
        </div>
        <div className=" HorizontallyCenteredText">
          <div className="ModalDetail">
            شما در حال حذف کردن کامل اطلاعیه <span className="DeleteText">{props.CurrentAnnouncement.title}</span> هستید. برای تایید لطفا کلمه <span className="DeleteText">Delete</span> را با حفظ کوچک یا بزرگ بودن حروف در کادر زیر بنویسید.
          </div>
          <input className="FullWidthContainer ModalTextBox HorizontallyCenteredText" onChange={(e) => setDeleteTextBox(e.target.value)} id="ServiceTypesTitle" />
        </div>
        <div className="DeleteModalButtons">
          {deleteTextBox === "Delete" ? (
            <button onClick={(e) => deleteHandler(e, props.CurrentAnnouncement.id)} type="sumbit" className="SaveButton">
              حذف
            </button>
          ) : (
            <div className="SaveButton ButtonDisabled">حذف</div>
          )}
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            بستن
          </div>
        </div>
      </div>
    </form>
  );
}

import React, { useEffect, useState } from "react";
import ProjectsGridTable from "./ProjectsGridTable";
import AddProjectSlidingMenu from "./AddProjectSlidingMenu";
import DeleteProjectModal from "./DeleteProjectModal";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { actionGetAllProjects } from "../../../actions";
import { useDispatch } from "react-redux";

export default function Projects() {
  useEffect(() => {
    getProjects();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentProject, setCurrentProject] = useState();

  const getProjects = async (myPageIndex) => {
    dispatch(actionGetAllProjects(1, pageSize));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentProject(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddProjectSlidingMenu onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getProjects} />}
        {/* {deleteModalOpan && <DeleteProjectModal CurrentProject={currentProject} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getProjects} />} */}
        <ProjectsGridTable PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getAnnouncementById } from "../../../Services/mirrorServices";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../Services/config.json";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import AddAnnouncementItemSlidingMenu from "./AddAnnouncementItemSlidingMenu";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";
import { PutAnAnnouncement } from "../../../Services/mirrorServices";
import EditAnnouncementItemSlidingMenu from "./EditAnnouncementItemSlidingMenu";
import DeleteAnnouncementItemModal from "./DeleteAnnouncementItemModal";
import { PutAnAnnouncementItemEdit } from "./../../../Services/mirrorServices";

function EditAnnouncement(history) {
  const mySelectedAnnouncementId = history.location.state.id;
  const [myAnnouncementItems, setMyAnnouncementItems] = useState({});

  useEffect(() => {
    getSelectedAnnouncements();
  }, []);

  const getSelectedAnnouncements = async () => {
    const { status, data } = await getAnnouncementById(mySelectedAnnouncementId.id);
    if (status === 200) {
      setMyAnnouncementItems(data);
      setUploadedImage(data.imageUrl);
      setServiceTypesTitle(data.announcementItems[0].title);
      setDescription(data.announcementItems[0].description);
      setTimeDescription(data.announcementItems[0].timeDescription);
      setLocationDescription(data.announcementItems[0].locationDescription);
      Datafiller(data);
    }
  };
  const [announcementId, setAnnouncementId] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [isComingSoon, setIsComingSoon] = useState();
  const [announcementImageKey, setAnnouncementImageKey] = useState("");
  const [isActice, setIsActice] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [ServiceTypesTitle, setServiceTypesTitle] = useState("");
  const [description, setDescription] = useState("");
  const [timeDescription, setTimeDescription] = useState("");
  const [locationDescription, setLocationDescription] = useState("");

  const [selectedCheckBox, setSelectedCheckBox] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [supportedLanguagescount, setSupportedLanguagescount] = useState();
  const [totalLanguagescount, settotalLanguagescount] = useState();
  const [uploadedImage, setUploadedImage] = useState("");
  const [loadingHandler, setLoadingHandler] = useState(false);

  const [addItemHandler, setAddItemHandler] = useState(false);
  const [editItemHandler, setEditItemHandler] = useState(false);
  const [deleteItemHandler, setDeleteItemHandler] = useState(false);
  const [grayOutHandler, setGrayOutHandler] = useState(false);
  const [expireDateCheckBoxStatus, setExpireDateCheckBoxStatus] = useState(false);

  const Datafiller = (announcements) => {
    setLoadingHandler(true);
    setIsActice(announcements.isActive);
    setIsComingSoon(announcements.isComingSoon);
    setAnnouncementId(announcements.id);
    setIsHighlight(announcements.isHighlight);
    setAnnouncementImageKey("");

    if (announcements.expireDate !== null) {
      const fetchedDate = new Date(announcements.expireDate);
      const convertedDate = fetchedDate.toLocaleDateString("en-CA");
      setExpireDate(convertedDate);
      expireDateCheckedHandler(true);
    } else {
      expireDateCheckedHandler(false);
      setExpireDate(null);
    }

    setUploadedImage(announcements.imageUrl);

    let SupportedLanguagescount = 0;

    let languagesId = [];
    let languagesItemsId = [];
    announcements.languages.forEach((languages) => {
      announcements.announcementItems.forEach((announcementItems, index) => {
        if (languages.id === announcementItems.languageId && languages.isActive) {
          SupportedLanguagescount++;
        }
      });
    });
    announcements.languages.forEach((languages) => {
      languagesId.push(languages);
    });
    announcements.announcementItems.forEach((announcementItems) => {
      languagesItemsId.push(announcementItems);
    });

    let availableLanguages = languagesId.filter((itemA) => {
      return !languagesItemsId.find((itemB) => {
        return itemA.id === itemB.languageId;
      });
    });

    setAvailableLanguages(availableLanguages);
    setSupportedLanguagescount(SupportedLanguagescount);
    settotalLanguagescount(availableLanguages.length + SupportedLanguagescount);
    setLoadingHandler(false);
  };
  const fileValidTypes = ["image/png", "image/jpeg", "image/jpg"];
  const fileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("Format not supported");
      } else {
        setLoadingHandler(true);
        const response = await axios.post(`${config.PardikURL}/Images`, imageData, {
          headers: {
            accept: "text/plain",
            bucketName: config.BucketName,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          setUploadedImage(response.data.fileUrl);
          setAnnouncementImageKey(response.data.fileKey);
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoadingHandler(false);
    }
  };

  const expireDateCheckedHandler = (status) => {
    setExpireDateCheckBoxStatus(status);
    if (expireDateCheckBoxStatus === false) {
      setExpireDate(null);
    }
  };
  function slideMenuCloserHandler() {
    setAddItemHandler(false);
    setEditItemHandler(false);
    setDeleteItemHandler(false);
    setGrayOutHandler(false);
  }

  const updateAnnouncementHandler = async (e) => {
    try {
      const { status } = await PutAnAnnouncement(announcementId, expireDate, isComingSoon, announcementImageKey, isActice, isHighlight);

      const { languageId } = myAnnouncementItems.announcementItems[0];
      if (status === 200) {
        const response = await PutAnAnnouncementItemEdit(announcementId, languageId, ServiceTypesTitle, description, timeDescription, locationDescription);
        if (response.status) {
          toast.success("فرآیند به روز رسانی با موفقت انجام شد.");
        }
      } else {
        toast.warning("مشکلی در به روز رسانی پیش آمد. لطفا اینترنت خود را چک کرده و دوباره تلاش کنید.");
      }
    } catch (err) {
      toast.error(err.response.data.ErrorDescription);
    }
  };
  return (
    <>
      {addItemHandler && <AddAnnouncementItemSlidingMenu onClickCloseHandler={slideMenuCloserHandler} ResetPage={getSelectedAnnouncements} AnnouncementId={announcementId} AvailableLanguages={availableLanguages} />}
      {deleteItemHandler && <DeleteAnnouncementItemModal onClickCloseHandler={slideMenuCloserHandler} ResetPage={getSelectedAnnouncements} AnnouncementId={announcementId} SelectedItem={selectedCheckBox} />}
      {editItemHandler && <EditAnnouncementItemSlidingMenu onClickCloseHandler={slideMenuCloserHandler} ResetPage={getSelectedAnnouncements} AnnouncementId={announcementId} SelectedItem={selectedCheckBox} />}
      {grayOutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
      <div className="ContentBody">
        {myAnnouncementItems ? (
          <div className="BodyPadding">
            <div className="EditPageTwoColumnNoBG">
              <div className="EditPageTwoColumnItemWithBG">
                <div className="ContentBody">
                  <form
                    className="formGrid"
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateAnnouncementHandler(e);
                    }}>
                    <div className="WidthFull">
                      <div className="FullWidthGrid4Columns">
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">وضعیت فعال بودن</div>
                          <label className="switch">
                            <input type="checkbox" onChange={(e) => setIsActice(!isActice)} checked={isActice} />
                            <span className="switchslider round"></span>
                          </label>
                          <div className="SlidingMenuMoreDetail">در صورت غیر فعال کردن اطلاعیه، دیگر تا فعال کردن دوباره آن برای مخاطبین به نمایش گذاشته نمیشود.</div>
                        </div>

                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">نام سرویس</div>
                          <input className="SlidingMenuTextBox" id="ServiceTypesTitle" value={ServiceTypesTitle || ""} onChange={(e) => setServiceTypesTitle(e.target.value)} />
                        </div>

                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">زمان</div>
                          <input className="SlidingMenuTextBox" id="ServiceTypesTitle" value={timeDescription || ""} onChange={(e) => setTimeDescription(e.target.value)} />
                        </div>
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">مکان</div>
                          <input className="SlidingMenuTextBox" id="ServiceTypesTitle" value={locationDescription || ""} onChange={(e) => setLocationDescription(e.target.value)} />
                        </div>
                        <div className="FullWidthContainer">
                          <div className="EditMenuUploadImageContainer">
                            {uploadedImage ? (
                              loadingHandler ? (
                                <LoadingUpload />
                              ) : (
                                <img className="UploadImage" src={uploadedImage} alt=""></img>
                              )
                            ) : (
                              <div className="UploadNoImageText">
                                <LoadingUpload />
                              </div>
                            )}
                          </div>
                          <input type="file" id="file" className="uploadButton" onChange={fileSelectedHandler} />
                          <label htmlFor="file">بارگذاری عکس</label>
                        </div>
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">توضیحات</div>
                          <textarea className="SlidingMenuTextArea" id="ServiceTypesTitle" value={description || ""} onChange={(e) => setDescription(e.target.value)} />
                        </div>

                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">نمایش به عنوان هایلایت</div>
                          <label className="switch">
                            <input name="HighlightCheckbox" id="HighlightCheckbox" type="checkbox" onChange={(e) => setIsHighlight(!isHighlight)} checked={isHighlight} />
                            <span className="switchslider round"></span>
                          </label>
                          <div className="SlidingMenuMoreDetail">در صورت فعال کردن این بخش، اطلاعیه تعریف شده در صفحه اصلی اپلیکیشن نیز نمایان می شود.</div>
                        </div>

                        <div className="FullWidthContainer">
                          <div className="ExpiryDateContainer">
                            <div className="FullWidthContainer">
                              <div className="EditItemTitle">تاریخ انقضا</div>
                              <label className="switch">
                                <input name="DueDateCheckbox" id="DueDateCheckbox" type="checkbox" onChange={(e) => setExpireDateCheckBoxStatus(!expireDateCheckBoxStatus)} checked={expireDateCheckBoxStatus} />
                                <span className="switchslider round"></span>
                              </label>
                            </div>
                            <div className="FullWidthContainer">
                              <div className="SlidingMenuItemTitle">زمان اتمام</div>
                              {!expireDateCheckBoxStatus ? (
                                <input disabled value={""} className="SlidingMenuTextBox" type="Date" id="DueDate" />
                              ) : (
                                <input onChange={(e) => setExpireDate(e.target.value)} value={expireDate} className="SlidingMenuTextBox" type="Date" id="DueDate" />
                              )}
                              <div className="SlidingMenuMoreDetail ">از تاریخ نتظیم شده به بعد این اطلاعیه به کاربران نشان داده نخواهد شد</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="FullWidthContainer">
                      <div className="ButtonsContainer">
                        {description && ServiceTypesTitle !== null ? (
                          <button type="button" className="SaveButton" id="SaveButton" onClick={(e) => updateAnnouncementHandler(e)}>
                            <span className="icon-SaveButton"></span>
                            ذخیره
                          </button>
                        ) : (
                          <button type="button" className="SaveButton ButtonDisabled" id="SaveButton">
                            <span className="icon-SaveButton"></span>
                            ذخیره
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
export default withRouter(EditAnnouncement);

export const rolesReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETROLES':
            return {...action.payload}
        default:
           return state;
    }
}

export const selectedRoleReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETSELECTEDROLE':
            return {...action.payload}
        default:
           return state;
    }
}

export const deleteRoleReducer=(state={},action)=>{
    switch (action.type) {
        case 'DELETESELECTEDROLE':
            return {...action.payload}
        default:
           return state;
    }
}
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { PostValidPlates, ValidateLicensePlates } from "../../../Services/managementServices";
import { PlateCharacters } from "./PlatesCharacters";

export default function AddBatchLicensePlates(props) {
  const [items, setItems] = useState([]);
  const [validPlates, setValidPlates] = useState([]);
  const [invalidPlates, setInvalidPlates] = useState([]);

  const handleUploadExcelFile = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setItems(data);
      toast.success("پلاک ها با موفقیت بارگذاری شدند");
    });
  };

  const unitsValidateHandler = async () => {
    var ItemsUnits = items.map(function (item) {
      return item.unitNumber;
    });
    const { status, data } = await ValidateLicensePlates(ItemsUnits);

    if (status === 200) {
      toast.success("پلاک باموفقیت ارزیابی شدند");

      var tempValidPlates = [];
      var tempInValidPlates = [];

      items.forEach((e) => {
        var foundedPlate = data.find((x) => x.number === e.unitNumber);
        if (foundedPlate.isValid) {
          tempValidPlates.push({ firstNumber: e.firstNumber, secondNumber: e.secondNumber, character: PlateCharacters.find((chr) => chr.farsi === e.character).english, cityCode: e.cityCode, plateNote: e.plateNote, unitNumber: e.unitNumber });
          tempValidPlates[tempValidPlates.length - 1].unitId = foundedPlate.unitId;
        } else {
          tempInValidPlates.push(e);
        }
      });

      setValidPlates(tempValidPlates);
      setInvalidPlates(tempInValidPlates);
    }
  };

  const handleSavePlates = async () => {
    const { status } = await PostValidPlates(validPlates);
    if (status === 200) {
      //reset();
      toast.success("پلاک باموفقیت ثبت شد");

      props.onClickCloseHandler();
      props.onClickRefresh();
    }
  };

  return (
    <form className="BatchAddPlateContainer">
      <div className="BatchAddPlateHeader">
        <div className="SlidingMenuTitle">اضافه کردن پلاک جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="BatchAddPlateBody">
        <div className="UploadAndValidationContainer">
          <div className="UploadExcelButton">
            <div className="ClearButton">
              {/* <span className="icon-DropUpArrow"></span> */}
              <input
                type="file"
                id="excelFile"
                className="UploadExcelButton icon-DropUpArrow"
                style={{ height: "20px" }}
                onChange={(e) => {
                  const file = e.target.files[0];
                  handleUploadExcelFile(file);
                }}
              />
              بارگزاری فایل اکسل
            </div>
          </div>

          {/* <div className="UploadExcelButton">
            <div class="UploadExcelButtonfile-upload-wrapper" data-text="آپلود فایل">
              <span className="icon-DropUpArrow ExcelSpanIcon"></span>
              <input name="file-upload-field" type="file" class="UploadExcelButtonfile-upload-field" value="" />
            </div>
          </div> */}

          <div className="UploadExcelButton">
            <div className="ClearButton" onClick={() => unitsValidateHandler()}>
              {/* <span className="icon-DropUpArrow"></span> */}
              اعتبار سنجی
            </div>
          </div>
        </div>
        {/* ------------------------------------------- لیست اونایی که مشکل ندارن ------------------------------------------- */}
        <div className="RelativeHight BatchShadowbox">
          <div className="TableGridViewContainer RelativeHight">
            <ol className="collection collection-container OlRelativeHight">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">پلاک</div>
                <div className="attribute">شماره واحد</div>
              </li>
              <div className="ScrollableGridView" id="style-3">
                {/* ---------------------------------------------------------------------------------------------------------------------------------- */}
                {validPlates
                  ? validPlates.map((item, i) => (
                      <li className="item item-container">
                        <div className="attribute Firstchild" data-name="#">
                          {i + 1}
                        </div>

                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="License Plate fullText">
                            <div className="GridLicensePlate">
                              <div className="PlateMainContainerForGrid">
                                <span className="PlateCharactertemplate" />
                                <div className="PlateCharacterHolder">{item.firstNumber}</div>
                                <div className="PlateCharacterHolder">{PlateCharacters.find((x) => x.english === item.character).farsi}</div>
                                <div className="PlateCharacterHolder PlateDivider"> {item.secondNumber}</div>
                                <div className="PlateCharacterHolder">{item.cityCode}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="License Plate firstNumber">
                            {item.unitNumber}
                          </div>
                        </div>
                      </li>
                    ))
                  : null}
              </div>
            </ol>
          </div>
        </div>
        {/* ------------------------------------------- لیست اونایی که مشکل دارن ------------------------------------------- */}
        <div className="RelativeHight BatchShadowbox">
          <div className="TableGridViewContainer RelativeHight">
            <ol className="collection collection-container OlRelativeHight">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">پلاک</div>
                <div className="attribute">شماره واحد</div>
              </li>
              <div className="ScrollableGridView" id="style-3">
                {/* ---------------------------------------------------------------------------------------------------------------------------------- */}
                {invalidPlates
                  ? invalidPlates.map((item, i) => (
                      <li className="item item-container">
                        <div className="attribute Firstchild" data-name="#">
                          {i + 1}
                        </div>

                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="License Plate fullText">
                            <div className="GridLicensePlate">
                              <div className="PlateMainContainerForGrid">
                                <span className="PlateCharactertemplate" />
                                <div className="PlateCharacterHolder">{item.firstNumber}</div>
                                <div className="PlateCharacterHolder">{item.character}</div>
                                <div className="PlateCharacterHolder PlateDivider"> {item.secondNumber}</div>
                                <div className="PlateCharacterHolder">{item.cityCode}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="License Plate firstNumber">
                            {item.unitNumber}
                          </div>
                        </div>
                      </li>
                    ))
                  : null}

                {/* ---------------------------------------------------------------------------------------------------------------------------------- */}
              </div>
            </ol>
          </div>
        </div>
      </div>

      <div className="BatchAddPlateFooter FullWidthContainer">
        <hr></hr>

        <div className="ButtonsContainer">
          {validPlates.length > 0 ? (
            <button onClick={handleSavePlates} type="button" className="SaveButton">
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <div type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </div>
          )}
          <div onClick={props.onClickCloseHandler} className="CancelButton ">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

import React, { useState, useEffect } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { PutServicetypeDeviceItemById } from "../../../Services/managementServices";
import { actionGetAllDeviceTypes } from "./../../../actions/index";
import { useDispatch, useSelector } from "react-redux";

function EditServiceTypeDevice(props) {
  useEffect(() => {
    getProductTypes();
  }, []);

  const currentProductTypes = useSelector((state) => state.allDevices);
  const dispatch = useDispatch();

  const [deviceName, setDeviceName] = useState(props.SelectedDeviceItem.deviceName);
  const [deviceIp, setDeviceIp] = useState(props.SelectedDeviceItem.deviceIp);
  const [deviceTypeId, setDeviceTypeId] = useState(props.SelectedDeviceItem.deviceTypeId);

  const getProductTypes = async () => {
    dispatch(actionGetAllDeviceTypes(0, 100));
  };

  const reset = () => {
    setDeviceName("");
    setDeviceIp("");
    setDeviceTypeId("");
  };

  const addNewDeviceHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await PutServicetypeDeviceItemById(props.ServiceId, props.SelectedDeviceItem.id, deviceName, deviceIp, deviceTypeId);
    if (status === 200) {
      reset();
      toast.success("Product Type added Successfully");
      props.onClickCloseHandler();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">دستگاه جدید اضافه کنید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">نام دستگاه</div>
        <input className="SlidingMenuTextBox" id="resourceName" value={deviceName} onChange={(e) => setDeviceName(e.target.value)} />
        <div className="SlidingMenuMoreDetail">The name of the Resource should be written here. This name will later be used for defined staff and can be assigned to them.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">آی پی دستگاه</div>
        <input className="SlidingMenuTextBox" id="resourceName" value={deviceIp} onChange={(e) => setDeviceIp(e.target.value)} />
        <div className="SlidingMenuMoreDetail">The name of the Resource should be written here. This name will later be used for defined staff and can be assigned to them.</div>
      </div>
      <div className="HalfWidthInsideFlex">
        <div className="FullWidthContainer">
          <div className="SlidingMenuItemTitle">نوع دستگاه</div>
          <div className="SlidingMenuTextBox DropDownContainer">
            <select
              className="DropDownSelectNoFlag"
              name="DevicetypeId"
              id="DevicetypeId"
              value={deviceTypeId}
              onChange={(e) => {
                setDeviceTypeId(e.target.value);
              }}>
              <option value="Choose a Device Type" disabled>
                لطفا انتخاب کنید
              </option>
              {currentProductTypes
                ? currentProductTypes.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.deviceModel}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="ButtonsContainer">
          <button type="sumbit" className="SaveButton" onClick={(e) => addNewDeviceHandler(e)}>
            <span className="icon-SaveButton"></span>
            ذخیره
          </button>
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(EditServiceTypeDevice);

export const mealTimesReducer=(state=[],action)=>{
    switch (action.type) {
        case 'GETMEALTIMES':
            return [...action.payload]
        default:
           return state;
    }
}
export const selectedMealTimeReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETSELECTEDMEALTIME':
            return {...action.payload}
        default:
           return state;
    }
}
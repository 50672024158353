import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";

export default function StaffsGridTable(props) {
  const myStaffs = useSelector((state) => state.allUsers);
  const [selectedCheckBox, setSelectedCheckBox] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox.id} IsSelected={isSelected} NavlinkAdress={"/EditStaff"} />
      {myStaffs.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام کاربر</div>
                <div className="attribute">ایمیل</div>
                <div className="attribute">نقش منصوب شده</div>
              </li>

              {myStaffs.entities.map((item, index) => (
                <li key={index + 1} className="item item-container">
                  <div className="attribute Firstchild" data-name="#">
                    <input
                      className="Checkbox"
                      type="radio"
                      name="RoleItem"
                      value={item.id}
                      id={item.id}
                      onChange={() => {
                        setSelectedCheckBox(item);
                        setIsSelected(true);
                      }}
                    />
                    {index + 1}
                  </div>
                  <div className="attribute-container part-id">
                    <div className="attribute" data-name="Full Name">
                      {item.fullName}
                    </div>
                  </div>
                  <div className="attribute-container vendor-information">
                    <div className="attribute" data-name="Email">
                      {item.email}
                    </div>
                  </div>
                  <div className="attribute-container quantity">
                    <div className="attribute" data-name="Assigned Role/s">
                      {item.roles}
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
          <Pagination PageIndex={myStaffs.pageIndex} PageSize={props.PassPageSize} TotalCount={myStaffs.totalCount} TotalPages={myStaffs.totalPages} HasPreviousPage={myStaffs.hasPreviousPage} HasNextPage={myStaffs.hasNextPage} actionType="Staffs" />
        </div>
      ) : null}
    </>
  );
}

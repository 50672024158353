import React, { useEffect, useState } from "react";
import AddLicensePlatesSlidingMenu from "../licenseplate/AddLicensePlate";
import DeleteLicensePlateModal from "../licenseplate/DeleteLicensePlate";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { useDispatch } from "react-redux";
import { actionGetAllLicensePlates } from "../../../actions";
import UnitLicensePlatesGridTable from "./UnitLicensePlatesGridTable";

export default function UnitLicensePlate(props) {
  useEffect(() => {
    getLicensePlates();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentLicensePlates, setCurrentLicensePlates] = useState();

  const getLicensePlates = async (myPageIndex) => {
    dispatch(actionGetAllLicensePlates(1, pageSize, "", props.UnitId));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentLicensePlates(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  return (
    <>
      {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
      {slideMenuIsOpen && <AddLicensePlatesSlidingMenu UnitId={props.UnitId} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getLicensePlates} />}
      {deleteModalOpan && <DeleteLicensePlateModal CurrentLicensePlates={currentLicensePlates} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getLicensePlates} />}
      <UnitLicensePlatesGridTable UnitId={props.UnitId} onClickRefresh={getLicensePlates} PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
    </>
  );
}

import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { postAGate } from "../../../Services/managementServices";

function AddGateSlidingMenu(props) {
  const [gateTitle, setGateTitle] = useState("");
  const [gateIp, setGateIp] = useState("");
  const [gateCommand, setGateCommand] = useState("");
  const [gateCode, setGateCode] = useState("");

  const [languageDDlist, setLanguageDDlist] = useState("");

  const reset = () => {
    setGateTitle("");
    setGateIp("");
    setGateCommand("");
    setGateCode("");
  };

  const addGateItemHandler = async (e) => {
    e.preventDefault();
    const { status } = await postAGate(gateTitle, gateIp, gateCommand, gateCode);

    if (status === 200) {
      reset();
      toast.success("دروازه با موفقیت ثبت شد.");
      props.onClickCloseHandler();
      props.ResetPage();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن راهبند جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">عنوان راهبند</div>
        <input className="SlidingMenuTextBox" id="announcementTitle" value={gateTitle} onChange={(e) => setGateTitle(e.target.value)} />
        {/* <div className="SlidingMenuMoreDetail">This would be the translation of the compliment in the selected language.</div> */}
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">آی پی راهبند</div>
        <input className="SlidingMenuTextBox" id="announcementTitle" value={gateIp} onChange={(e) => setGateIp(e.target.value)} />
        {/* <div className="SlidingMenuMoreDetail">This would be the translation of the compliment in the selected language.</div> */}
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">کامند راهبند</div>
        <input className="SlidingMenuTextBox" id="announcementTitle" value={gateCommand} onChange={(e) => setGateCommand(e.target.value)} />
        {/* <div className="SlidingMenuMoreDetail">This would be the translation of the compliment in the selected language.</div> */}
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            لغو
          </div>

          {gateTitle && gateIp && gateCommand ? (
            <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addGateItemHandler(e)}>
              ذخیره
            </button>
          ) : (
            <button disabled className="SlidingMenuSavebutton ButtonDisabled">
              ذخیره
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddGateSlidingMenu);

import React, { useState, useEffect } from "react";
import Loading from "../../../components/ui/loading/Loading";
import { withRouter } from "react-router-dom";
import { getResidentById, PutAResident } from "../../../Services/managementServices";
import { toast } from "react-toastify";

function EditBuildingResident({ history }) {
  const selectedCheckBox = history.location.state.id;

  // const currentUserTypes = useSelector((state) => state.allDevicesCategories);
  const currentUserTypes = [
    { Type: "UnitAdmin", Text: "مدیر واحد" },
    { Type: "Resident", Text: "ساکن" },
  ];

  const [BuildingResidentUsername, setBuildingResidentUsername] = useState("");
  const [BuildingResidentPasswrod, setBuildingResidentPassword] = useState("");
  const [BuildingResidentRepeatPassword, setBuildingResidentRepeatPassword] = useState("");
  const [BuildingResidentFirstName, setBuildingResidentFirstName] = useState("");
  const [BuildingResidentLastName, setBuildingResidentLastName] = useState("");
  const [BuildingResidentMobile, setBuildingResidentMobile] = useState("");
  const [BuildingResidentGender, setBuildingResidentGender] = useState("");
  const [ProjectId, setProjectId] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    fillStates();
  }, []);

  const fillStates = async () => {
    const { data, status } = await getResidentById(selectedCheckBox);
    if (status === 200) {
      setBuildingResidentUsername(data.username);
      setBuildingResidentFirstName(data.firstName);
      setBuildingResidentLastName(data.lastname);
      setBuildingResidentMobile(data.mobileNumber);
      setBuildingResidentGender(data.gender);
      setUserType(data.userType);
      setProjectId(data.projectId);
    }
  };

  const updateBuildingResidentHandler = async (e) => {
    e.preventDefault();

    try {
      const { status } = await PutAResident(selectedCheckBox, BuildingResidentPasswrod, BuildingResidentFirstName, BuildingResidentLastName, BuildingResidentMobile, userType, ProjectId, BuildingResidentGender, BuildingResidentMobile);
      if (status === 200) {
        toast.success("تغییرات با موفقیت اعمال شد");
        fillStates();
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.ErrorDescription : "Something went wrong!");
    }
  };

  return (
    <div className="ContentBody">
      <div className="BodyPadding">
        <div className="EditPageThreeColumnNoBG">
          <div className="TabInfoContainer">
            <div class="PostHeaderTitle">اطلاعات واحد</div>
            <div className="TabInfoGridNum">
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">نام کاربری</div>
                <input disabled className="SlidingMenuTextBox" value={BuildingResidentUsername} id="BuildingResidentUsername" />
                <div className="SlidingMenuMoreDetail">نام کاربری مختص واحد ثبت شده و غیر قابل تغییر.</div>
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">رمز عبور</div>
                <input className="SlidingMenuTextBox" id="StaffPassword" type="password" value={BuildingResidentPasswrod ? BuildingResidentPasswrod : null} onChange={(e) => setBuildingResidentPassword(e.target.value)} />
                <div className="SlidingMenuMoreDetail">فقط در صورت تمایل در تغییر رمز غبور کاربر پر شود</div>
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">تکرار رمز عبور</div>
                <input className="SlidingMenuTextBox" id="StaffRepeatPassword" type="password" value={BuildingResidentRepeatPassword ? BuildingResidentRepeatPassword : null} onChange={(e) => setBuildingResidentRepeatPassword(e.target.value)} />
                <div className="SlidingMenuMoreDetail">تکرار رمز عبور جدید در صورت تمایل به تغییر رمز عبور</div>
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">نام </div>
                <input className="SlidingMenuTextBox" value={BuildingResidentFirstName} onChange={(e) => setBuildingResidentFirstName(e.target.value)} />
                <div className="SlidingMenuMoreDetail">نام کاربر اینجا نوشته شود</div>
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">نام خانوادگی </div>
                <input className="SlidingMenuTextBox" value={BuildingResidentLastName} onChange={(e) => setBuildingResidentLastName(e.target.value)} />
                <div className="SlidingMenuMoreDetail">نام خانوادگی کاربر اینجا نوشته شود</div>
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">موبایل</div>
                <input className="SlidingMenuTextBox" value={BuildingResidentMobile} onChange={(e) => setBuildingResidentMobile(e.target.value)} />
                <div className="SlidingMenuMoreDetail">شماره موبایل کاربر. توجه داشته باشید که با تغییر شماره همراه، نام کاربری نیز تغییر میکند.</div>
              </div>

              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">جنسیت</div>
                <div className="SlidingMenuTextBox DropDownContainer">
                  <select
                    value={BuildingResidentGender}
                    className="DropDownSelectNoFlag"
                    name="ResidentGender"
                    id="ResidentGender"
                    defaultValue="جنسیت"
                    onChange={(e) => {
                      setBuildingResidentGender(e.target.value);
                    }}>
                    <option value="-1" disabled>
                      لطفا انتخاب کنید
                    </option>
                    <option value="male">مرد</option>
                    <option value="female">زن</option>
                  </select>
                </div>
              </div>

              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">نوع کاربری</div>
                <div className="SlidingMenuTextBox DropDownContainer">
                  <select
                    value={userType}
                    className="DropDownSelectNoFlag"
                    name="UsertypeId"
                    id="UsertypeId"
                    defaultValue="Choose a UserType"
                    onChange={(e) => {
                      setUserType(e.target.value);
                    }}>
                    <option value="Choose a UserType" disabled>
                      لطفا انتخاب کنید
                    </option>

                    {currentUserTypes
                      ? currentUserTypes.map((item, index) => (
                          <option key={index} value={item.Type}>
                            {item.Text}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            {BuildingResidentPasswrod === BuildingResidentRepeatPassword && BuildingResidentFirstName && BuildingResidentLastName && BuildingResidentMobile ? (
              <div className="LabelAndTextBoxContainer">
                <button type="sumbit" onClick={(e) => updateBuildingResidentHandler(e)} class="SaveButton" id="SaveButton">
                  <span class="icon-SaveButton"></span>ذخیره
                </button>
              </div>
            ) : (
              <div className="LabelAndTextBoxContainer">
                <button type="sumbit" disabled class="SaveButton ButtonDisabled" id="SaveButton">
                  <span class="icon-SaveButton"></span>ذخیره
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EditBuildingResident);

export const PlateCharacters=[
    {
      "english": "Alef",
      "farsi": "الف",
      "value": 0
    },
    {
      "english": "B",
      "farsi": "ب",
      "value": 1
    },
    {
      "english": "J",
      "farsi": "ج",
      "value": 2
    },
    {
      "english": "L",
      "farsi": "ل",
      "value": 3
    },
    {
      "english": "M",
      "farsi": "م",
      "value": 4
    },
    {
      "english": "N",
      "farsi": "ن",
      "value": 5
    },
    {
      "english": "Q",
      "farsi": "ق",
      "value": 6
    },
    {
      "english": "V",
      "farsi": "و",
      "value": 7
    },
    {
      "english": "H",
      "farsi": "ه",
      "value": 8
    },
    {
      "english": "Y",
      "farsi": "ی",
      "value": 9
    },
    {
      "english": "D",
      "farsi": "د",
      "value": 10
    },
    {
      "english": "S",
      "farsi": "س",
      "value": 11
    },
    {
      "english": "Sad",
      "farsi": "ص",
      "value": 12
    },
    {
      "english": "Malol",
      "farsi": "معلول",
      "value": 13
    },
    {
      "english": "T",
      "farsi": "ت",
      "value": 14
    },
    {
      "english": "Ta",
      "farsi": "ط",
      "value": 15
    },
    {
      "english": "Ein",
      "farsi": "ع",
      "value": 16
    },
    {
      "english": "Englisdiplomat",
      "farsi": "D",
      "value": 17
    },
    {
      "english": "Englissiyasi",
      "farsi": "S",
      "value": 18
    },
    {
      "english": "P",
      "farsi": "پ",
      "value": 19
    },
    {
      "english": "Tashrifat",
      "farsi": "تشریفات",
      "value": 20
    },
    {
      "english": "The",
      "farsi": "ث",
      "value": 21
    },
    {
      "english": "Ze",
      "farsi": "ز",
      "value": 22
    },
    {
      "english": "She",
      "farsi": "ش",
      "value": 23
    },
    {
      "english": "Fe",
      "farsi": "ف",
      "value": 24
    },
    {
      "english": "Kaf",
      "farsi": "ک",
      "value": 25
    },
    {
      "english": "Gaf",
      "farsi": "گ",
      "value": 26
    }
  ]
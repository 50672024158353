export const resourcesReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETRESOURCES':
            return {...action.payload}
        default:
           return state;
    }
}

export const selectedResourceReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETSELECTEDRESOURCES':
            return {...action.payload}
        default:
           return state;
    }
}

import React, { useState, useEffect, useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./Layout.css";
import "./LayoutRtl.css";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import CheckLogin from "../../utils/checkLogin";
import { BrowserView, MobileView } from "react-device-detect";
import DarkModeContext from "../darkmode/DarkModeContext";

const Layout = (props) => {
  const token = JSON.parse(localStorage.getItem("residentialManagement"));

  const [residentialManagmentOpen, setResidentialManagmentOpen] = useState();
  const [managmentOpen, setmanagmentOpen] = useState();
  const [projectsManagementOpen, setprojectsManagementOpen] = useState();
  const [provisionOpen, setprovisionOpen] = useState();
  const [mirrorSectionOpen, setMirrorSectionOpen] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [amenitiesManagmentOpen, setAmenitiesManagmentOpen] = useState();

  if (!CheckLogin()) props.history.replace("/");

  const openResidentialManagment = () => {
    setResidentialManagmentOpen(!residentialManagmentOpen);
  };
  const openManagment = () => {
    setmanagmentOpen(!managmentOpen);
  };
  const openProjectManagment = () => {
    setprojectsManagementOpen(!projectsManagementOpen);
  };
  const openBuildingManagment = () => {
    setprovisionOpen(!provisionOpen);
  };
  const openMirrorSection = () => {
    setMirrorSectionOpen(!mirrorSectionOpen);
  };
  const openAmenitiesManagment = () => {
    setAmenitiesManagmentOpen(!amenitiesManagmentOpen);
  };

  const ShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);

  return (
    <div>
      <BrowserView>
        <div className="LayoutContainer BackgroundLightToWhiteGradient">
          <div className="HeaderandContentContainert RtlDirection">
            {/* <div className="LayoutHeader VerticallyCenteredObject">
              <div className="HeaderBarCss">
                <div className="DisplayFlex">
                  <Breadcrumbs title={props.location.state ? props.location.state.id.title : null} />
                </div>
                <div className="HeaderBarIconsContainer">
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                  <div className="HeaderBarIcons">
                    <NavLink exact to="/Logout">
                      <span className="icon-exit HeaderRibbonIcon"></span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="DarkModeContainer">
              <div className="DarkModeDiv" onClick={toggleDarkMode}>
                {darkMode ? (
                  <div>
                    <img className="DarkModeIcon" src="/images/night-mode.png" alt="" />
                  </div>
                ) : (
                  <div>
                    <img className="DarkModeIcon" src="/images/day-mode.png" alt="" />
                  </div>
                )}
              </div>
            </div>

            <div className="LayoutContent ">{props.children}</div>
          </div>
          <div className="SideBar">
            <div className="SideBarContianer">
              {token.userData.userType === "BuildingAdmin" ? (
                <>
                  <div className="HotelInformationContainer">
                    <img className="SidebarTitleImage" src="/images/ic_logo.png" alt="" />

                    <div className="SidebarTitle">مجتمع مسکونی پاردیک</div>
                    {/* <div className="SidebarTitle">{token.decodedToken ? token.decodedToken.unique_name : null}</div> */}
                  </div>
                  <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                    {residentialManagmentOpen ? (
                      <ListItemButton className="MuiListCssSelected" onClick={openResidentialManagment}>
                        <ListItemIcon>
                          <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت کاربران" />
                        <span className="icon-DropUpArrow SideBarIcons"></span>
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={openResidentialManagment}>
                        <ListItemIcon>
                          <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت کاربران" />
                        <span className="icon-DropDownArrow SideBarIcons"></span>
                      </ListItemButton>
                    )}
                    <Collapse in={residentialManagmentOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Unit" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="مدیریت واحدها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/BuildingResidents" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="مدیریت ساکنین" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </List>

                  <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                    {provisionOpen ? (
                      <ListItemButton className="MuiListCssSelected" onClick={openBuildingManagment}>
                        <ListItemIcon>
                          <span className="icon-provision SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت ساختمان" />
                        <span className="icon-DropUpArrow SideBarIcons"></span>
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={openBuildingManagment}>
                        <ListItemIcon>
                          <span className="icon-provision SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت ساختمان" />
                        <span className="icon-DropDownArrow SideBarIcons"></span>
                      </ListItemButton>
                    )}

                    <Collapse in={provisionOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/BuildingProject" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="مشخصات ساختمان" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/BillingManagement" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="مدیریت شارژ" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        {/* <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Expenses" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="هزینه ها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/BuildingExpenses" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="مدیریت شارژ" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton> */}
                      </List>
                    </Collapse>
                  </List>

                  <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                    {amenitiesManagmentOpen ? (
                      <ListItemButton className="MuiListCssSelected" onClick={openAmenitiesManagment}>
                        <ListItemIcon>
                          <span className="icon-provision SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت منابع" />
                        <span className="icon-DropUpArrow SideBarIcons"></span>
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={openAmenitiesManagment}>
                        <ListItemIcon>
                          <span className="icon-provision SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت منابع" />
                        <span className="icon-DropDownArrow SideBarIcons"></span>
                      </ListItemButton>
                    )}

                    <Collapse in={amenitiesManagmentOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Announcements" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="اطلاعیه ها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>

                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/MirrorCompliments" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="خوشامدگویی" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        {/* <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/ServiceTypes" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="سرویس ها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/ServiceRules" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="قوانین سرویس ها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/SpecialRules" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="قوانین روزهای خاص" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                     
                     
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/WeatherForecast" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="تنظیمات آب هوا" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton> */}
                      </List>
                    </Collapse>
                  </List>

                  <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                    {mirrorSectionOpen ? (
                      <ListItemButton className="MuiListCssSelected" onClick={openMirrorSection}>
                        <ListItemIcon>
                          <span className="icon-mirror SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت تردد" />
                        <span className="icon-DropUpArrow SideBarIcons"></span>
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={openMirrorSection}>
                        <ListItemIcon>
                          <span className="icon-mirror SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت تردد" />
                        <span className="icon-DropDownArrow SideBarIcons"></span>
                      </ListItemButton>
                    )}

                    <Collapse in={mirrorSectionOpen} timeout="auto" unmountOnExit>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/LicensePlate" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="پلاک خوان" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Gates" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="راهبند ها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/noneAssociatedLicensePlate" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="پلاک های خدماتی" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                    </Collapse>
                  </List>
                </>
              ) : (
                <>
                  <div className="HotelInformationContainer">
                    <img className="SidebarTitleImage" src="/images/ic_logo.png" alt="" />

                    <div className="SidebarTitle">پنل مدیریتی پاردیک</div>
                    {/* <div className="SidebarTitle">{token.decodedToken ? token.decodedToken.unique_name : null}</div> */}
                  </div>
                  <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                    {managmentOpen ? (
                      <ListItemButton className="MuiListCssSelected" onClick={openManagment}>
                        <ListItemIcon>
                          <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت داخلی" />
                        <span className="icon-DropUpArrow SideBarIcons"></span>
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={openManagment}>
                        <ListItemIcon>
                          <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت داخلی" />
                        <span className="icon-DropDownArrow SideBarIcons"></span>
                      </ListItemButton>
                    )}
                    <Collapse in={managmentOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Staffs" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="مدیریت کاربران" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Roles" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="نقش ها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Resources" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="منابع" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Devices" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="دستگاه ها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                      </List>
                    </Collapse>
                    {projectsManagementOpen ? (
                      <ListItemButton className="MuiListCssSelected" onClick={openProjectManagment}>
                        <ListItemIcon>
                          <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت مشتریان" />
                        <span className="icon-DropUpArrow SideBarIcons"></span>
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={openProjectManagment}>
                        <ListItemIcon>
                          <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                        </ListItemIcon>
                        <ListItemText primary="مدیریت مشتریان" />
                        <span className="icon-DropDownArrow SideBarIcons"></span>
                      </ListItemButton>
                    )}

                    <Collapse in={projectsManagementOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Projects" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="پروژه ها" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>

                        <ListItemButton className="MuiSubListCss">
                          <NavLink exact className="NavLinkWidth" to="/Residents" activeClassName="HeaderNavigation-Active">
                            <ListItemText className="MenuChildPadding" primary="ساکنین" sx={{ pl: 8 }} />
                          </NavLink>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </List>
                </>
              )}

              <div className="LayoutSideBarFooterContainer">
                <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                  <NavLink exact to="/Logout">
                    <ListItemButton className="MuiListCssSelected">
                      <ListItemIcon>
                        <span className="icon-exit SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>

                      <ListItemText primary="خروج" sx={{ pl: 1 }} />
                    </ListItemButton>
                  </NavLink>
                </List>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="DeviceLayoutContainer">
          <div className="DeviceHeaderandContentContainert RtlDirection">
            <div className="LayoutHeader VerticallyCenteredObject">
              <div className="DeviceHeaderBarCss">
                <div className="HeaderBarIconsContainer">
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                </div>
                <div>
                  <div className="MenuCloseButton" onClick={ShowMenu}>
                    <span className="icon-menu"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="LayoutContent BackgroundLightToWhiteGradient">{props.children}</div>
          </div>
          <div className={showMenu ? "SideBarOpen" : "SideBarClosed"}>
            <div className="HeaderPlacings">
              <div className="HotelInformationContainer">
                <img className="DeviceSidebarTitleImage" src="/images/ic_logo.png" alt="" />
                <div className="SidebarTitle">برج سپهر</div>
              </div>
              <div className="MenuCloseButton" onClick={ShowMenu}>
                <span className="icon-closeX ColorBlackColor"></span>
              </div>
            </div>
            {token.userData.userType === "BuildingAdmin" ? (
              <>
                <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                  {residentialManagmentOpen ? (
                    <ListItemButton className="MuiListCssSelected" onClick={openResidentialManagment}>
                      <ListItemIcon>
                        <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت کاربران" />
                      <span className="icon-DropUpArrow SideBarIcons"></span>
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={openResidentialManagment}>
                      <ListItemIcon>
                        <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت کاربران" />
                      <span className="icon-DropDownArrow ColorBlackColor"></span>
                    </ListItemButton>
                  )}
                  <Collapse in={residentialManagmentOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Unit" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="مدیریت واحدها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/BuildingResidents" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="مدیریت ساکنین" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/LicensePlate" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="پلاک خوان" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>

                <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                  {provisionOpen ? (
                    <ListItemButton className="MuiListCssSelected" onClick={openBuildingManagment}>
                      <ListItemIcon>
                        <span className="icon-provision SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت ساختمان" />
                      <span className="icon-DropUpArrow SideBarIcons"></span>
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={openBuildingManagment}>
                      <ListItemIcon>
                        <span className="icon-provision SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت ساختمان" />
                      <span className="icon-DropDownArrow ColorBlackColor"></span>
                    </ListItemButton>
                  )}

                  <Collapse in={provisionOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/BuildingProject" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="مشخصات ساختمان" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/BuildingProject" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="مدیریت شارژ" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/ServiceTypes" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="سرویس ها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/ServiceRules" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="قوانین سرویس ها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/SpecialRules" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="قوانین روزهای خاص" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>

                <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                  {mirrorSectionOpen ? (
                    <ListItemButton className="MuiListCssSelected" onClick={openMirrorSection}>
                      <ListItemIcon>
                        <span className="icon-mirror SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت آینه" />
                      <span className="icon-DropUpArrow SideBarIcons"></span>
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={openMirrorSection}>
                      <ListItemIcon>
                        <span className="icon-mirror SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت آینه" />
                      <span className="icon-DropDownArrow ColorBlackColor"></span>
                    </ListItemButton>
                  )}

                  <Collapse in={mirrorSectionOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Announcements" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="اطلاعیه ها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>

                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/MirrorCompliments" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="خوشامدگویی" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/WeatherForecast" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="تنظیمات آب هوا" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </>
            ) : (
              <>
                <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                  {managmentOpen ? (
                    <ListItemButton className="MuiListCssSelected" onClick={openManagment}>
                      <ListItemIcon>
                        <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت داخلی" />
                      <span className="icon-DropUpArrow SideBarIcons"></span>
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={openManagment}>
                      <ListItemIcon>
                        <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت داخلی" />
                      <span className="icon-DropDownArrow ColorBlackColor"></span>
                    </ListItemButton>
                  )}
                  <Collapse in={managmentOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Staffs" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="مدیریت کاربران" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Roles" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="نقش ها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Resources" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="منابع" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Devices" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="دستگاه ها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {projectsManagementOpen ? (
                    <ListItemButton className="MuiListCssSelected" onClick={openProjectManagment}>
                      <ListItemIcon>
                        <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت مشتریان" />
                      <span className="icon-DropUpArrow SideBarIcons"></span>
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={openProjectManagment}>
                      <ListItemIcon>
                        <span className="icon-Management SideBarIcons LayoutListIcon"></span>
                      </ListItemIcon>
                      <ListItemText primary="مدیریت مشتریان" />
                      <span className="icon-DropDownArrow ColorBlackColor"></span>
                    </ListItemButton>
                  )}

                  <Collapse in={projectsManagementOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Projects" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="پروژه ها" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>

                      <ListItemButton className="MuiSubListCss">
                        <NavLink exact className="NavLinkWidth" to="/Residents" activeClassName="HeaderNavigation-Active">
                          <ListItemText className="MenuChildPadding" primary="ساکنین" sx={{ pl: 8 }} />
                        </NavLink>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </>
            )}

            <div className="DeviceLayoutSideBarFooterContainer">
              <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                <NavLink exact to="/Logout">
                  <ListItemButton className="MuiListCssSelected">
                    <ListItemIcon>
                      <span className="icon-exit SideBarIcons LayoutListIcon"></span>
                    </ListItemIcon>

                    <ListItemText primary="خروج" sx={{ pl: 1 }} />
                  </ListItemButton>
                </NavLink>
              </List>
            </div>
          </div>
        </div>
      </MobileView>
    </div>
  );
};

export default withRouter(Layout);

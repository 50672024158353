import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { actionGetSelectedResources } from "../../../actions";
import "../../../components/datagridtable/DataGridTable.css";
import AddResourceItemSlidingMenu from "./AddResourceItemSlidingMenu";
import DeleteResourceModal from "./DeleteResourceModal";

import GrayedOut from "../../../components/ui/grayedout/GrayedOut";

function ResourceDetail({ history }) {
  const dispatch = useDispatch();
  const mySelectedResource = useSelector((state) => state.selectedResources);
  const selectedResource = history.location.state.ResourceId;

  useEffect(() => {
    dispatch(actionGetSelectedResources(selectedResource));
  }, []);

  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler() {
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }

  return (
    <>
      {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
      {slideMenuIsOpen && <AddResourceItemSlidingMenu ResourceId={mySelectedResource.id} onClickCloseHandler={slideMenuCloserHandler} />}
      {deleteModalOpan && <DeleteResourceModal CurrentResource={mySelectedResource} onClickCloseHandler={slideMenuCloserHandler} />}

      <div className="ContentBody ">
        <div className="BodyPadding">
          {mySelectedResource.resourceItems ? (
            <>
              <div className="GridTopTitleAndDescription">
                <div className="GridTopTitle"> {mySelectedResource.title}</div>
                <div className="GridTopDescription"> {mySelectedResource.description}</div>
                <div className="CrudContainer">
                  <div className="ButtonsContainer">
                    <div onClick={slideMenuOpenerHandler} className="BackgorundPrimaryColor button-left">
                      <span className="icon-add"></span>
                      اضافه
                    </div>
                    <div onClick={deleteModalOpenerHandler} className="BackgorundSecondaryColor button-left">
                      <span className="icon-trash"></span>
                      حذف
                    </div>
                  </div>
                </div>
              </div>
              <div className="GridViewAndPaginationContainer">
                <div className="TableGridViewContainer">
                  <ol className="collection collection-container">
                    <li className="item item-container">
                      <div className="attribute Firstchild">ردیف</div>
                      <div className="attribute">نام منبع</div>
                      <div className="attribute">کلید</div>
                    </li>

                    {mySelectedResource.resourceItems.map((item, index) => (
                      <li key={index + 1} className="item item-container">
                        <div className="attribute Firstchild" data-name="#">
                          {index + 1}
                        </div>
                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="Resource Name">
                            {item.resourceName}
                          </div>
                        </div>
                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="Resource Name">
                            {item.resourceKey}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default withRouter(ResourceDetail);

import React from "react";

export default function CardViewServices() {
  return (
    <div className="CardViewContainerServices">
      <div className="CardIconContainer">
        <span className="icon-point-of-service CardIconBackground"></span>
      </div>
      <div className="CardViewElements ">
        <div className="CardViewTitleInfo">تعداد سرویس های فعال امروز: 15</div>
        <div className="CardViewSupplementaryInfo">تعداد رزرو شده ها: 5</div>
        <div className="CardViewSupplementaryInfo">تعداد رزرو های استفاده شده: 3</div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ServiceTypesGridTable from "./ServiceTypesGridTable";
import AddServiceTypesSlidingMenu from "./AddServiceTypesSlidingMenu";
import DeleteServiceTypesModal from "./DeleteServiceTypesModal";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { actionGetAllServiceTypes } from "../../../actions";
import { useDispatch } from "react-redux";

export default function ServiceTypes() {
  useEffect(() => {
    getServiceTypes();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentServiceTypes, setCurrentServiceTypes] = useState();

  const getServiceTypes = async (myPageIndex) => {
    dispatch(actionGetAllServiceTypes(1, pageSize));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentServiceTypes(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddServiceTypesSlidingMenu onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getServiceTypes} />}
        {deleteModalOpan && <DeleteServiceTypesModal CurrentServiceTypes={currentServiceTypes} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getServiceTypes} />}
        <ServiceTypesGridTable onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}

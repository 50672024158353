import React, { useState } from "react";
import { toast } from "react-toastify";
import { PostANoneAssociatedLicensePlate } from "../../../Services/managementServices";
import { PlateCharacters } from "../licenseplate/PlatesCharacters";

export default function AddNoneAssociatedLicensePlate(props) {
  const [NoneAssociatedLicensePlateFirstNumber, setNoneAssociatedLicensePlateFirstNumber] = useState("");
  const [NoneAssociatedLicensePlateSecondNumber, setNoneAssociatedLicensePlateSecondNumber] = useState("");
  const [NoneAssociatedLicensePlateCharacter, setNoneAssociatedLicensePlateCharacter] = useState("");
  const [NoneAssociatedLicensePlateCityCode, setNoneAssociatedLicensePlateCityCode] = useState("");
  const [NoneAssociatedLicensePlateunitId, NoneAssociatedLicensePlateUnitId] = useState(props.UnitId);
  const [NoneAssociatedLicensePlateNote, setNoneAssociatedLicensePlateNote] = useState("");
  const [NoneAssociatedLicensePlateTitle, setNoneAssociatedLicensePlateTitle] = useState("");

  const reset = () => {
    setNoneAssociatedLicensePlateFirstNumber("");
    setNoneAssociatedLicensePlateSecondNumber("");
    setNoneAssociatedLicensePlateCharacter("");
    setNoneAssociatedLicensePlateCityCode("");
    NoneAssociatedLicensePlateUnitId("");
  };

  const addNoneAssociatedLicensePlateHandler = async (e) => {
    e.preventDefault();
    const { status } = await PostANoneAssociatedLicensePlate(
      NoneAssociatedLicensePlateFirstNumber,
      NoneAssociatedLicensePlateSecondNumber,
      NoneAssociatedLicensePlateCharacter,
      NoneAssociatedLicensePlateCityCode,
      props.UnitId,
      NoneAssociatedLicensePlateNote,
      NoneAssociatedLicensePlateTitle
    );
    if (status === 200) {
      reset();
      toast.success("پلاک باموفقیت ثبت شد");

      props.onClickCloseHandler();
      props.onClickRefresh();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن پلاک جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">عنوان پلاک</div>
        <input className="SlidingMenuTextBox" id="NoneAssociatedLicensePlateTitle" value={NoneAssociatedLicensePlateTitle} onChange={(e) => setNoneAssociatedLicensePlateTitle(e.target.value)} />
      </div>
      <div className="FullWidthContainer plateAddHeight">
        <div className="PlateMainContainer">
          <span className="PlateCharactertemplate" />
          <input className="PlateCharacterHolder" maxLength="2" value={NoneAssociatedLicensePlateFirstNumber} onChange={(e) => setNoneAssociatedLicensePlateFirstNumber(e.target.value)} type="tel" placeholder="⚊ ⚊" id="carPlateSection1" />
          <select
            value={NoneAssociatedLicensePlateCharacter}
            className="PlateCharacterHolder"
            name="UsertypeId"
            id="UsertypeId"
            onChange={(e) => {
              setNoneAssociatedLicensePlateCharacter(e.target.value);
            }}>
            <option value="ChooseaDeviceType">حرف</option>

            {PlateCharacters
              ? PlateCharacters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.farsi}
                  </option>
                ))
              : null}
          </select>
          <input
            className="PlateCharacterHolder PlateDivider"
            maxLength="3"
            value={NoneAssociatedLicensePlateSecondNumber}
            onChange={(e) => setNoneAssociatedLicensePlateSecondNumber(e.target.value)}
            type="tel"
            placeholder="⚊ ⚊ ⚊"
            id="carPlateSection1"
          />
          <input className="PlateCharacterHolder" maxLength="2" value={NoneAssociatedLicensePlateCityCode} onChange={(e) => setNoneAssociatedLicensePlateCityCode(e.target.value)} type="tel" placeholder="⚊ ⚊" id="carPlateSection1" />
        </div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">پیام ورود پلاک</div>
        <textarea className="SlidingMenuTextArea" id="ProjectDescription" value={NoneAssociatedLicensePlateNote} onChange={(e) => setNoneAssociatedLicensePlateNote(e.target.value)} />
        <div className="SlidingMenuMoreDetail">این پیام هنگام ورود پلاک به پارکینگ به مدیران واحد نمایش داده می شود.</div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>

        <div className="ButtonsContainer">
          {NoneAssociatedLicensePlateFirstNumber && NoneAssociatedLicensePlateSecondNumber && NoneAssociatedLicensePlateCityCode && NoneAssociatedLicensePlateCharacter && NoneAssociatedLicensePlateCharacter !== "ChooseaDeviceType" ? (
            <button onClick={(e) => addNoneAssociatedLicensePlateHandler(e)} type="sumbit" className="SaveButton">
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <div type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </div>
          )}

          <div onClick={props.onClickCloseHandler} className="CancelButton ">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

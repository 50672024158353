import React, { useState } from "react";
import { toast } from "react-toastify";
import { PostAResidentInProject } from "../../../Services/managementServices";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllProjects } from "../../../actions";

export default function ProjectAddResidentsSlidingMenu(props) {
  const [residentUsername, setResidentUsername] = useState("");
  const [residentPasswrod, setResidentPasswrod] = useState("");
  const [residentFirstName, setresidentFirstName] = useState("");
  const [residentLastName, setresidentLastName] = useState("");
  const [residentMobile, setResidentMobile] = useState("");
  const [BuildingResidentGender, setBuildingResidentGender] = useState("");

  const reset = () => {
    setResidentUsername("");
    setResidentPasswrod("");
    setresidentFirstName("");
    setresidentLastName("");
    setResidentMobile("");
    setBuildingResidentGender("");
  };

  const addResidentHandler = async (e) => {
    e.preventDefault();
    const { status } = await PostAResidentInProject(residentUsername, residentPasswrod, residentFirstName, residentLastName, residentMobile, BuildingResidentGender, props.ProjectId);
    if (status === 200) {
      reset();
      toast.success("سکنه باموفقیت ثبت شد");
      props.onClickCloseHandler();
      props.onClickRefresh();
      props.ResetPage();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن سکنه جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نام کاربری (شماره موبایل)</div>
          <input
            className="SlidingMenuTextBox"
            id="residentUsername"
            value={residentUsername}
            onChange={(e) => {
              setResidentUsername(e.target.value);
              setResidentMobile(e.target.value);
            }}
          />
          <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">رمز عبور</div>
          <input type="password" className="SlidingMenuTextBox" id="residentPasswrod" value={residentPasswrod} onChange={(e) => setResidentPasswrod(e.target.value)} />
          <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
        </div>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نام</div>
          <input className="SlidingMenuTextBox" id="residentFirstName" value={residentFirstName} onChange={(e) => setresidentFirstName(e.target.value)} />
          <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
        </div>
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نام خانوادگی</div>
          <input className="SlidingMenuTextBox" id="residentLastName" value={residentLastName} onChange={(e) => setresidentLastName(e.target.value)} />
          <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
        </div>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">جنسیت</div>
          <select
            className="PlateCharacterHolder"
            name="UsertypeId"
            id="UsertypeId"
            defaultValue="-1"
            onChange={(e) => {
              setBuildingResidentGender(e.target.value);
            }}>
            <option value="-1" disabled>
              لطفا انتخاب کنید
            </option>
            <option value="male">مرد</option>
            <option value="female">زن</option>
          </select>
          <div className="SlidingMenuMoreDetail">جنسیت ساکن در حال تعریف</div>
        </div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>

        <div className="ButtonsContainer">
          {residentUsername && residentPasswrod && residentFirstName && residentMobile ? (
            <button onClick={(e) => addResidentHandler(e)} type="sumbit" className="SaveButton">
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <div type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </div>
          )}

          <div onClick={props.onClickCloseHandler} className="CancelButton ">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

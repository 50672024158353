import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAProject } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddProjectSlidingMenu(props) {
  const [ProjectTitle, setProjectTitle] = useState("");
  const [ProjectDescription, setProjectDescription] = useState("");

  const reset = () => {
    setProjectTitle("");
    setProjectDescription("");
  };

  const addNewProjectHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await PostAProject(ProjectTitle, ProjectDescription);
    if (status === 200) {
      reset();
      toast.success("Project added Successfully");
      props.onClickCloseHandler();
      props.onClickRefresh();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">پروژه اضافه کنید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">نام پروژه جدید</div>
        <input className="SlidingMenuTextBox" id="resourceName" value={ProjectTitle} onChange={(e) => setProjectTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">در این قسمت نام پروژه باید پر شود.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">توضیحات پروژه</div>
        <textarea className="SlidingMenuTextArea" id="ProjectDescription" value={ProjectDescription} onChange={(e) => setProjectDescription(e.target.value)} />
        <div className="SlidingMenuMoreDetail">توضیحی جامع راجب خود پروژه، این قسمت بعدا توسط مدیر ساختمان نیز قابل ویرایش و تکمیل کردن است.</div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="ButtonsContainer">
          <button type="sumbit" className="SaveButton" onClick={(e) => addNewProjectHandler(e)}>
            <span className="icon-SaveButton"></span>
            ذخیره
          </button>
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddProjectSlidingMenu);

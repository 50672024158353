import React from "react";
import ReactDOM from "react-dom";
import "./assets/fonts/icomoon/style.css";
import "./assets/css/basic.css";
import "./assets/css/fonts.css";
// import "./assets/css/colorresource.css";
import "./assets/css/style.css";
import "./assets/css/deviceview.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/index";
import { DarkModeProvider } from "./components/darkmode/DarkModeContext";

ReactDOM.render(
  <Provider store={store}>
    <DarkModeProvider>
      <App />
    </DarkModeProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();

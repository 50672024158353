import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { actionGetSelectedProject } from "../../../actions";
import "../../../components/slidingmenu/slidingmenu.css";
import Loading from "../../../components/ui/loading/Loading";
import ProjectDescription from "./ProjectDescription";
import { getProjectById } from "../../../Services/managementServices";
import ProjectAmenities from "./ProjectAmenities";
import ProjectPictures from "./ProjectPictures";
import ProjectResidents from "./ProjectResidents";

function EditProject({ history }) {
  const mySelectedProject = useSelector((state) => state.selectedProject);
  const selectedCheckBox = history.location.state.id;

  const [loadingHandler, setLoadingHandler] = useState(false);

  const [projectId, setProjectId] = useState("");
  const [projectInformation, setProjectInformation] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectLogoFileKey, setProjectLogoFileKey] = useState("");
  const [projectAmenities, setProjectAmenities] = useState([]);
  const [projectPictures, setProjectPictures] = useState([]);
  const [projectNumberOfUnits, setProjectNumberOfUnits] = useState("");
  const [numberOfPlates, setNumberOfPlates] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    fillStates();
    dispatch(actionGetSelectedProject(selectedCheckBox));
  }, []);

  const [isProjectDescriptionTrue, setIsProjectDescriptionTrue] = useState(true);
  const [isProjectAmenitiesTrue, setIsProjectAmenitiesTrue] = useState(false);
  const [isProjectImagesTrue, setIsProjectImagesTrue] = useState(false);
  const [isProjectResidents, setIsProjectResidents] = useState(false);

  function ShowProjectDescription() {
    setIsProjectDescriptionTrue(true);
    setIsProjectImagesTrue(false);
    setIsProjectAmenitiesTrue(false);
    setIsProjectResidents(false);
  }
  function ShowProjectImages() {
    setIsProjectDescriptionTrue(false);
    setIsProjectImagesTrue(true);
    setIsProjectAmenitiesTrue(false);
    setIsProjectResidents(false);
  }
  function ShowProjectAmenities() {
    setIsProjectDescriptionTrue(false);
    setIsProjectImagesTrue(false);
    setIsProjectAmenitiesTrue(true);
    setIsProjectResidents(false);
  }
  function ShowProjectResidents() {
    setIsProjectDescriptionTrue(false);
    setIsProjectImagesTrue(false);
    setIsProjectAmenitiesTrue(false);
    setIsProjectResidents(true);
  }
  const fillStates = async () => {
    const { data, status } = await getProjectById(selectedCheckBox);

    if (status === 200) {
      setProjectId(data.id);
      setProjectInformation(data.information);
      setProjectName(data.name);
      setProjectNumberOfUnits(data.numberOfUnits);
      setNumberOfPlates(data.numberOfPlates);
      setProjectLogoFileKey(data.logoFileKey);
      setProjectPictures(data.imageKeys);
      setProjectAmenities(data.amenities);
    }
  };

  return (
    <>
      <div className="ContentBody">
        <div className="BodyPadding">
          <div className="ContentBody">
            <div className="ServiceSettingtypes">
              {isProjectDescriptionTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  مشخصات پروژه
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowProjectDescription()}>
                  مشخصات پروژه
                </div>
              )}
              {isProjectImagesTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  آیکون امکانات
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowProjectImages()}>
                  آیکون امکانات
                </div>
              )}
              {isProjectAmenitiesTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  عکس های پروژه
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowProjectAmenities()}>
                  عکس های پروژه
                </div>
              )}
              {isProjectResidents ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  ساکنین پروژه
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowProjectResidents()}>
                  ساکنین پروژه
                </div>
              )}
            </div>
            <div className="BodyPadding">
              <div className="EditPageTwoColumnNoBG">
                {isProjectDescriptionTrue ? (
                  <div className="EditPageTwoColumnItemWithBG">
                    <ProjectDescription
                      onClickRefresh={fillStates}
                      ProjectId={projectId}
                      ProjectName={projectName}
                      NumberOfUnits={projectNumberOfUnits}
                      NumberOfPlates={numberOfPlates}
                      ProjectInformation={projectInformation}
                      ProjectLogo={projectLogoFileKey}
                      ProjectAmenities={projectAmenities}
                      ProjectPictures={projectPictures}
                    />
                  </div>
                ) : null}
                {isProjectImagesTrue ? (
                  <div className="EditPageTwoColumnItemWithBG">
                    <ProjectAmenities
                      onClickRefresh={fillStates}
                      ProjectId={projectId}
                      ProjectName={projectName}
                      ProjectInformation={projectInformation}
                      ProjectAmenities={projectAmenities}
                      ProjectPictures={projectPictures}
                      ProjectLogo={projectLogoFileKey}
                    />
                  </div>
                ) : null}
                {isProjectAmenitiesTrue ? (
                  <div className="EditPageTwoColumnItemWithBG">
                    <ProjectPictures
                      onClickRefresh={fillStates}
                      ProjectId={projectId}
                      ProjectName={projectName}
                      ProjectInformation={projectInformation}
                      ProjectPictures={projectPictures}
                      ProjectAmenities={projectAmenities}
                      ProjectLogo={projectLogoFileKey}
                    />
                  </div>
                ) : null}
                {isProjectResidents ? (
                  <div className="GridViewAfterSelectedTab">
                    <ProjectResidents ProjectId={projectId} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(EditProject);

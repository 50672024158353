import React from "react";
import "./LoadingUpload.css";

export default function LoadingUpload() {
  return (
      <div className="ImageLoadingMiddlePage">
        <div className="Imagelds-dual-ring"></div>
      </div>
  );
}

export const UserTypes = [
  {
    english: "Admin",
    farsi: "مدیر",
  },
  {
    english: "BuildingAdmin",
    farsi: "مدیر ساختمان",
  },
  {
    english: "UnitAdmin",
    farsi: "مدیر واحد",
  },
  {
    english: "Resident",
    farsi: "ساکن",
  },
];

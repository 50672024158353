import React, { useState, useEffect } from "react";
import config from "../../../Services/config.json";
import { PutAProject } from "../../../Services/managementServices";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";

export default function ProjectDescription(props) {
  const [projectName, setProjectName] = useState(""); // Initialize with prop
  const [projectNumberOfUnits, setProjectNumberOfUnits] = useState(""); // Initialize with prop
  const [projectNumberOfPlates, setProjectNumberOfPlates] = useState(""); // Initialize with prop
  const [projectInformation, setProjectInformation] = useState(""); // Initialize with prop
  const [projectLogoFileKey, setProjectLogoFileKey] = useState(null); // Initialize with prop
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  useEffect(() => {
    setProjectName(props.ProjectName || "");
    setProjectInformation(props.ProjectInformation || "");
    setProjectNumberOfUnits(props.NumberOfUnits || "");
    setProjectNumberOfPlates(props.NumberOfPlates || "");
    setProjectLogoFileKey(props.ProjectLogo || "");
    setUploadedImageUrl(config.ImageUrl + config.BucketName + "/" + (props.ProjectLogo || ""));
  }, [props.ProjectName, props.ProjectInformation, props.NumberOfUnits, props.NumberOfPlates, props.ProjectLogo]);

  const [loadingHandler, setLoadingHandler] = useState(false);
  const [progressData, setProgressData] = useState(0);
  const fileValidTypes = ["image/png", "image/jpeg", "image/jpg"];

  const fileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("Format not supported");
      } else {
        setProgressData(0);
        setLoadingHandler(true);
        const response = await axios.post(`${config.PardikURL}/Images`, imageData, {
          headers: {
            accept: "text/plain",
            bucketName: config.BucketName,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            let progressValue = Math.round((100 * data.loaded) / data.total);
            setProgressData(progressValue);
          },
        });
        if (response.status === 200) {
          setUploadedImageUrl(config.ImageUrl + config.BucketName + "/" + response.data.fileKey);
          setProjectLogoFileKey(response.data.fileKey);

          toast.success("Image uploaded Successfully");
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoadingHandler(false);
    }
  };
  const updateProjectHandler = async (e) => {
    try {
      const { status } = await PutAProject(props.ProjectId, projectName, projectInformation, projectNumberOfUnits, projectNumberOfPlates, null, null, projectLogoFileKey);
      toast.success("تغییرات با موفقیت انجام شد");
      props.onClickRefresh();
    } catch (err) {
      toast.error(err.response.data.ErrorDescription);
    }
  };

  return (
    <>
      <div className="ContentBody">
        <form
          className="formGrid"
          onSubmit={(e) => {
            e.preventDefault();
            updateProjectHandler(e);
          }}>
          <div className="WidthFull">
            <div className="FullWidthGrid4Columns">
              <div className="FullWidthContainer">
                <div className="EditMenuUploadImageContainer">
                  {uploadedImageUrl ? <img className="UploadImage" src={uploadedImageUrl} alt=""></img> : <div className="UploadNoImageText"> {loadingHandler ? <LoadingUpload /> : "محل بارگذاری عکس"}</div>}
                </div>
                {loadingHandler ? <input type="file" id="file" className="uploadButton ButtonDisabled" disabled /> : <input type="file" id="file" className="uploadButton" onChange={fileSelectedHandler} />}
                {loadingHandler ? (
                  <label htmlFor="file" className="ButtonDisabled" disabled>
                    بارگذاری عکس
                  </label>
                ) : (
                  <label htmlFor="file">بارگذاری عکس</label>
                )}
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">نام پروژه جدید</div>
                <input className="SlidingMenuTextBox" id="projectName" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                <div className="SlidingMenuMoreDetail">در این قسمت نام پروژه باید پر شود.</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تعداد واحد های پروژه</div>
                <input className="SlidingMenuTextBox" id="projectNumberOfUnits" value={projectNumberOfUnits} onChange={(e) => setProjectNumberOfUnits(e.target.value)} />
                <div className="SlidingMenuMoreDetail">در این قسمت نام پروژه باید پر شود.</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تعداد پارکینگ های پروژه</div>
                <input className="SlidingMenuTextBox" id="projectNumberOfPlates" value={projectNumberOfPlates} onChange={(e) => setProjectNumberOfPlates(e.target.value)} />
                <div className="SlidingMenuMoreDetail">در این قسمت نام پروژه باید پر شود.</div>
              </div>

              <div className="FullWidthContainer TextAreaInGrid">
                <div className="SlidingMenuItemTitle">توضیحات پروژه</div>
                <textarea className="SlidingMenuTextArea" id="ProjectDescription" value={projectInformation} onChange={(e) => setProjectInformation(e.target.value)} />
                <div className="SlidingMenuMoreDetail">توضیحی جامع راجب خود پروژه، این قسمت بعدا توسط مدیر ساختمان نیز قابل ویرایش و تکمیل کردن است.</div>
              </div>
            </div>
          </div>

          <div className="FullWidthContainer">
            <div className="ButtonsContainer">
              {projectInformation && projectName ? (
                <button type="sumbit" className="SaveButton" id="SaveButton" onClick={(e) => updateProjectHandler(e)}>
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              ) : (
                <button type="sumbit" className="SaveButton ButtonDisabled" id="SaveButton">
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { UnitResidency } from "./UnitResidency";
import config from "../../../Services/config.json";
import { PutAUnitById } from "../../../Services/managementServices";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";
import { UnitTypes } from "./UnitTypes";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import moment from "jalali-moment";

export default function UnitInfo(props) {
  const [unitNumber, setUnitNumber] = useState("");
  const [unitResidency, setUnitResidency] = useState("");
  const [unitSquare, setUnitSquare] = useState("");
  const [unitFloor, setUnitFloor] = useState("");
  const [unitIsEmpty, setUnitIsEmpty] = useState(false);
  const [unitType, setUnitType] = useState("-1");
  const [unitBlock, setUnitBlock] = useState("");
  const [unitDateOfResidence, setUnitDateOfResidence] = useState("");
  const [unitDateOfResidenceGregorian, setUnitDateOfResidenceGregorian] = useState("");
  const [unitNumberOfMembers, setUnitNumberOfMembers] = useState("");
  const [unitNumberOfPlates, setUnitNumberOfPlates] = useState("");
  const [numberOfActivePlates, setNumberOfActivePlates] = useState("");

  const handleSelectedDay = async (selectedDayItem) => {
    const combinedMyDay = selectedDayItem.year + "-" + selectedDayItem.month + "-" + selectedDayItem.day;
    const paramDate = await moment.from(combinedMyDay, "fa", "YYYY-MM-DD").format("YYYY-MM-DD");
    const m = moment(paramDate, "YYYY-MM-DD");
    setUnitDateOfResidence(selectedDayItem);
    setUnitDateOfResidenceGregorian(m._i);
  };

  useEffect(() => {
    setUnitNumber(props.UnitData.number || "");
    setUnitResidency(props.UnitData.residency || "");
    setUnitSquare(props.UnitData.square || "");
    setUnitFloor(props.UnitData.floor || "");
    setUnitIsEmpty(props.UnitData.isEmpty || false);
    setUnitType(props.UnitData.type || "-1");
    setUnitBlock(props.UnitData.block || "");
    setUnitDateOfResidence(props.UnitData.dateOfResidence ? convertPropDate(props.UnitData.dateOfResidence) : null);
    setUnitNumberOfMembers(props.UnitData.numberOfMembers || "");
    setUnitNumberOfPlates(props.UnitData.numberOfPlates || "");
    setNumberOfActivePlates(props.UnitData.numberOfActivePlates || "");
  }, [
    props.UnitData.number,
    props.UnitData.residency,
    props.UnitData.square,
    props.UnitData.floor,
    props.UnitData.isEmpty,
    props.UnitData.type,
    props.UnitData.dateOfResidence,
    props.UnitData.numberOfMembers,
    props.UnitData.block,
    props.UnitData.numberOfPlates,
    props.UnitData.numberOfActivePlates,
  ]);

  const convertPropDate = (myDate) => {
    let convertedDate = moment(myDate.split("T")[0], "YYYY/MM/DD").locale("fa").format("YYYY-MM-DD");
    const fetchedYear = convertedDate.substring(0, convertedDate.indexOf("-"));
    const fetchedMonth = convertedDate.substring(5, convertedDate.lastIndexOf("-"));
    const fetchedDay = convertedDate.substring(8, convertedDate.length);

    let combinedDate = { day: parseInt(fetchedDay), month: parseInt(fetchedMonth), year: parseInt(fetchedYear) };
    //setUnitDateOfResidence(combinedDate)
    return combinedDate;
  };

  const updateProjectHandler = async (e) => {
    e.preventDefault();

    try {
      const { status } = await PutAUnitById(
        props.UnitId,
        props.UnitData.title,
        props.UnitData.field,
        unitFloor,
        unitNumber,
        unitNumberOfPlates,
        numberOfActivePlates,
        props.UnitData.information,
        props.UnitData.phone,
        unitType,
        unitResidency,
        unitDateOfResidenceGregorian ? unitDateOfResidenceGregorian : props.UnitData.dateOfResidence.split("T")[0],
        unitNumberOfMembers,
        unitIsEmpty,
        unitSquare,
        props.UnitData.logoFileKey,
        unitBlock
      );
      toast.success("تغییرات با موفقیت انجام شد");
      props.onClickRefresh();
    } catch (err) {
      toast.error(err.response.data.ErrorDescription);
    }
  };
  console.log(unitIsEmpty);

  return (
    <>
      <div className="ContentBody">
        <form
          className="formGrid"
          onSubmit={(e) => {
            e.preventDefault();
            updateProjectHandler(e);
          }}>
          <div className="WidthFull">
            <div className="FullWidthGrid4Columns">
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">شماره واحد</div>
                <input className="SlidingMenuTextBox" value={unitNumber} onChange={(e) => setUnitNumber(e.target.value)} id="unitNumber" />
                <div className="SlidingMenuMoreDetail">شماره واحد/پلاک مشخص شده برای واحد</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">نوع ساکن</div>
                <select
                  value={unitResidency}
                  className="SlidingMenuTextBox"
                  name="unitResidency"
                  id="unitResidency"
                  onChange={(e) => {
                    setUnitResidency(e.target.value);
                  }}>
                  <option value="-1" disabled>
                    لطفا انتخاب کنید
                  </option>
                  {UnitResidency
                    ? UnitResidency.map((item, index) => (
                        <option key={index} value={item.english}>
                          {item.farsi}
                        </option>
                      ))
                    : null}
                </select>
                <div className="SlidingMenuMoreDetail">نوع ساکن مستقر در این واحد</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">متراژ (متر مربع)</div>
                <input className="SlidingMenuTextBox" value={unitSquare} onChange={(e) => setUnitSquare(e.target.value)} id="unitSquare" />
                <div className="SlidingMenuMoreDetail">متراژ واحد تعریف شده در متر مربع</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">طبقه</div>
                <input className="SlidingMenuTextBox" value={unitFloor} onChange={(e) => setUnitFloor(e.target.value)} id="unitFloor" />
                <div className="SlidingMenuMoreDetail">طبقه واحد تعریف شده. در صورت ویلایی بودن واحد این مقدار 1 فرض می شود.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">بلوک ساختمان (در صورت موجود بودن)</div>
                <input className="SlidingMenuTextBox" value={unitBlock} onChange={(e) => setUnitBlock(e.target.value)} id="unitBlock" />
                <div className="SlidingMenuMoreDetail">در صورت نداشتن بلوک میتوان این فیلد را خالی گذاشت</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">وضعیت پر بودن واحد</div>
                <label className="switch">
                  <input type="checkbox" onChange={(e) => setUnitIsEmpty(!unitIsEmpty)} checked={unitIsEmpty === true ? true : false} />
                  <span className="switchslider round"></span>
                </label>
                <div className="SlidingMenuMoreDetail">در صورت غیرفعال کردن این فرد، دسترسی او به پنل به صورت کامل قطع می شود.</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">نوع واحد</div>
                <select
                  value={unitType}
                  className="SlidingMenuTextBox"
                  name="UnitTypes"
                  id="UnitTypes"
                  onChange={(e) => {
                    setUnitType(e.target.value);
                  }}>
                  <option value="-1" disabled>
                    لطفا انتخاب کنید
                  </option>
                  {UnitTypes
                    ? UnitTypes.map((item, index) => (
                        <option key={index} value={item.english}>
                          {item.farsi}
                        </option>
                      ))
                    : null}
                </select>
                <div className="SlidingMenuMoreDetail">نوع واحد از نظر مسکونی یا اداری بودن</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تاریخ شروع سکونت</div>
                <DatePicker value={unitDateOfResidence} onChange={(val) => handleSelectedDay(val)} inputPlaceholder="" shouldHighlightWeekends locale="fa" />

                <div className="SlidingMenuMoreDetail">تاریخ شروع سکونت ساکنین در این واحد.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تعداد ساکنین</div>
                <input className="SlidingMenuTextBox" value={unitNumberOfMembers} onChange={(e) => setUnitNumberOfMembers(e.target.value)} id="unitNumberOfMembers" />
                <div className="SlidingMenuMoreDetail">توجه داشته باشید که عدد موجود در این قسمت در محاسبه شارژ میتواند تاثیر بگذارد.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تعداد مجاز ثبت پلاک</div>
                <input className="SlidingMenuTextBox" value={unitNumberOfPlates} onChange={(e) => setUnitNumberOfPlates(e.target.value)} id="unitNumberOfPlates" />
                <div className="SlidingMenuMoreDetail">بیشترین تعداد پلاکی که این واحد میتواند تعریف کند.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تعداد مجاز ثبت پلاک</div>
                <input className="SlidingMenuTextBox" value={numberOfActivePlates} onChange={(e) => setNumberOfActivePlates(e.target.value)} id="unitNumberOfPlates" />
                <div className="SlidingMenuMoreDetail">بیشترین تعداد ماشین(پلاک) هایی که همزمان از واحد میتوانند وارد پارکینگ شوند.</div>
              </div>
            </div>
          </div>
          <div className="FullWidthContainer">
            <div className="ButtonsContainer">
              {unitNumber && unitNumberOfPlates >= numberOfActivePlates ? (
                <button type="button" className="SaveButton" id="SaveButton" onClick={(e) => updateProjectHandler(e)}>
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              ) : (
                <button type="button" className="SaveButton ButtonDisabled" id="SaveButton">
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

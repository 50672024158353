export const foodsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETFOODS":
      return { ...action.payload };
    default:
      return state;
  }
};

export const selectedFoodReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDFOOD":
      return { ...action.payload };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import "../../slidingmenu/slidingmenu.css";

export default function IconPicker(props) {
  const Icons = [
    "icon-BathingMaterials",
    "icon-CleaningServices",
    "icon-TechnicalSupport",
    "icon-RoomSnack",
    "icon-FreshTowels",
    "icon-BeddingSheets",
    "icon-MedicalServices",
    "icon-LuggageServices",
    "icon-staffservices",
    "icon-hotelbell",
    "icon-pamphlet",
    "icon-car",
    "icon-wheelchair",
    "icon-RoomSpaIcon",
    "icon-briefcase",
    "icon-laundry",
    "icon-Balcony",
    "icon-Bathtub",
    "icon-Beach",
    "icon-Casino",
    "icon-CityView",
    "icon-ClothesRack",
    "icon-DryingRack",
    "icon-Garden",
    "icon-Lake",
    "icon-MountainView",
    "icon-Patio",
    "icon-PetFriendly",
    "icon-Restaurant",
    "icon-Shower",
    "icon-Wifi",
    "icon-parking",
    "icon-EntertainmentFacilitiy",
    "icon-Gym",
    "icon-SwimmingPool",
  ];

  const [Icon, setIcon] = useState([]);

  useEffect(() => {
    setIcon(props.CurrentIcon);
  }, []);

  if (props.multiSelect === 1) {
    return (
      <div className="IconSelectorContainer">
        {Icons.map((item, index) => (
          <button
            key={index}
            type="button"
            className={props.CurrentIcon.includes(item) ? item + " IconSelectorIcon IconSelectorIconSelected" : item + " IconSelectorIcon"}
            value={item}
            onClick={(e) => {
              setIcon(e.target.value);
              props.SelectedIcon(e.target.value);
            }}
          />
        ))}
      </div>
    );
  } else {
    return (
      <div className="IconSelectorContainer">
        {Icons.map((item, index) => (
          <button
            key={index}
            type="button"
            className={Icon === item ? item + " IconSelectorIcon IconSelectorIconSelected" : item + " IconSelectorIcon"}
            value={item}
            onClick={(e) => {
              setIcon(e.target.value);
              props.SelectedIcon(e.target.value);
            }}
          />
        ))}
      </div>
    );
  }
}

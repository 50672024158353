import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { actionGetSelectedProject } from "../../../actions";
import "../../../components/slidingmenu/slidingmenu.css";
import Loading from "../../../components/ui/loading/Loading";
import BuildingProjectDescription from "./BuildingProjectDescription";
import BuildingProjectPictures from "./BuildingProjectPictures";
import BuildingProjectAmenities from "./BuildingProjectAmenities";
import { getProjectById } from "../../../Services/managementServices";

function BuildingProject() {
  const token = JSON.parse(localStorage.getItem("residentialManagement"));

  const selectedCheckBox = token.userData.projectId;

  const [loadingHandler, setLoadingHandler] = useState(false);

  const [projectId, setProjectId] = useState("");
  const [projectInformation, setProjectInformation] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectLogoFileKey, setProjectLogoFileKey] = useState("");
  const [projectAmenities, setProjectAmenities] = useState([]);
  const [projectPictures, setProjectPictures] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    fillStates();
    dispatch(actionGetSelectedProject(selectedCheckBox));
  }, []);

  const [isProjectDescriptionTrue, setIsProjectDescriptionTrue] = useState(true);
  const [isProjectAmenitiesTrue, setIsProjectAmenitiesTrue] = useState(false);
  const [isProjectImagesTrue, setIsProjectImagesTrue] = useState(false);

  function ShowProjectDescription() {
    setIsProjectDescriptionTrue(true);
    setIsProjectImagesTrue(false);
    setIsProjectAmenitiesTrue(false);
  }
  function ShowProjectImages() {
    setIsProjectDescriptionTrue(false);
    setIsProjectImagesTrue(true);
    setIsProjectAmenitiesTrue(false);
  }
  function ShowProjectAmenities() {
    setIsProjectDescriptionTrue(false);
    setIsProjectImagesTrue(false);
    setIsProjectAmenitiesTrue(true);
  }

  const fillStates = async () => {
    const { data, status } = await getProjectById(selectedCheckBox);

    if (status === 200) {
      setProjectId(data.id);
      setProjectInformation(data.information);
      setProjectName(data.name);
      setProjectLogoFileKey(data.logoFileKey);
      setProjectPictures(data.imageKeys);
      setProjectAmenities(data.amenities);
    }
  };

  return (
    <>
      <div className="ContentBody">
        <div className="BodyPadding">
          <div className="ContentBody">
            <div className="ServiceSettingtypes">
              {isProjectDescriptionTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  مشخصات پروژه
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowProjectDescription()}>
                  مشخصات پروژه
                </div>
              )}
              {isProjectImagesTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  آیکون امکانات
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowProjectImages()}>
                  آیکون امکانات
                </div>
              )}
              {isProjectAmenitiesTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  عکس های پروژه
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowProjectAmenities()}>
                  عکس های پروژه
                </div>
              )}
            </div>
            <div className="BodyPadding">
              <div className="EditPageTwoColumnNoBG">
                <div className="EditPageTwoColumnItemWithBG">
                  {isProjectDescriptionTrue ? (
                    <BuildingProjectDescription
                      onClickRefresh={fillStates}
                      ProjectId={projectId}
                      ProjectName={projectName}
                      ProjectInformation={projectInformation}
                      ProjectLogo={projectLogoFileKey}
                      ProjectAmenities={projectAmenities}
                      ProjectPictures={projectPictures}
                    />
                  ) : null}
                  {isProjectImagesTrue ? (
                    <BuildingProjectAmenities
                      onClickRefresh={fillStates}
                      ProjectId={projectId}
                      ProjectName={projectName}
                      ProjectInformation={projectInformation}
                      ProjectAmenities={projectAmenities}
                      ProjectPictures={projectPictures}
                      ProjectLogo={projectLogoFileKey}
                    />
                  ) : null}
                  {isProjectAmenitiesTrue ? (
                    <BuildingProjectPictures
                      onClickRefresh={fillStates}
                      ProjectId={projectId}
                      ProjectName={projectName}
                      ProjectInformation={projectInformation}
                      ProjectPictures={projectPictures}
                      ProjectAmenities={projectAmenities}
                      ProjectLogo={projectLogoFileKey}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(BuildingProject);

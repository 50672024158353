import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAServiceRule, PostAServiceRules } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddServiceRulesSlidingMenu(props) {
  const [ServiceRulesTitle, setServiceRulesTitle] = useState("");
  const [ServiceRulesPrice, setServiceRulesPrice] = useState("");

  const [serviceRulesisPrivate, setServiceRulesisPrivate] = useState(null);

  const [startTimeRestriction, setStartTimeRestriction] = useState("");
  const [endTimeRestriction, setEndTimeRestriction] = useState("");
  const [isFree, setIsFree] = useState("");
  const [isTimeAttached, setIsTimeAttached] = useState(false);

  const reset = () => {
    setServiceRulesTitle("");
    setServiceRulesPrice("");
    setServiceRulesisPrivate(false);
    setStartTimeRestriction("");
    setEndTimeRestriction("");
  };

  const addNewServiceRuleHandler = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await PostAServiceRule(ServiceRulesTitle, ServiceRulesPrice, startTimeRestriction, endTimeRestriction, serviceRulesisPrivate, isTimeAttached);
      if (status === 200) {
        reset();
        toast.success("Rule added Successfully");
        props.onClickCloseHandler();
        props.onClickRefresh();
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.errors.Description[0] : "Something went wrong!");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن سرویس جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">نام سرویس</div>
        <input className="SlidingMenuTextBox" id="ServiceRulesTitle" value={ServiceRulesTitle} onChange={(e) => setServiceRulesTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">نامگذاری سرویس برای ارائه به مخاطب و مدیریت آن سرویس از طریق پنل مدیریت.</div>
      </div>
      <div className="FullWidthFlex">
        {isFree ? (
          <div className="HalfWidthContainer">
            <div className="SlidingMenuItemTitle">هزینه سرویس (به ریال)</div>
            <input disabled className="SlidingMenuTextBox ButtonDisabled" id="ServiceRulesPrice" value={0} />
            <div className="SlidingMenuMoreDetail">هزینه سرویس ارائه شده. در صورت رایگان بودن گزینه مقابل را انتخاب نمایید.</div>
          </div>
        ) : (
          <div className="HalfWidthContainer">
            <div className="SlidingMenuItemTitle">هزینه سرویس (به ریال)</div>
            <input className="SlidingMenuTextBox" id="ServiceRulesPrice" value={ServiceRulesPrice} onChange={(e) => setServiceRulesPrice(e.target.value)} />
            <div className="SlidingMenuMoreDetail">هزینه سرویس ارائه شده. در صورت رایگان بودن گزینه مقابل را انتخاب نمایید.</div>
          </div>
        )}

        <div className="HalfWidthContainer">
          <div className="TitleAndSliderCheck">
            <div className="SlidingMenuItemTitle">رایگان</div>
            <label className="switch">
              <input type="checkbox" onChange={(e) => (isFree ? (setIsFree(false), setServiceRulesPrice("")) : (setServiceRulesPrice("0"), setIsFree(true)))} />
              <span className="switchslider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="HalfWidthContainer">
        <div className="TitleAndSliderCheck">
          <div className="SlidingMenuItemTitle">متصل</div>
          <label className="switch">
            <input type="checkbox" checked={isTimeAttached ? true : false} onChange={() => setIsTimeAttached(!isTimeAttached)} />
            <span className="switchslider round"></span>
          </label>
        </div>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">زمان شروع</div>
          <div className="SlidingMenuTextBox DropDownContainer">
            <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId" onChange={(e) => setStartTimeRestriction(e.target.value)}>
              <option value="Choose a Device Type" disabled selected>
                لطفا زمان شروع مورد نظر را انتخاب نمایید.
              </option>
              <option value="01:00">01:00</option>
              <option value="02:00">02:00</option>
              <option value="03:00">03:00</option>
              <option value="04:00">04:00</option>
              <option value="05:00">05:00</option>
              <option value="06:00">06:00</option>
              <option value="07:00">07:00</option>
              <option value="08:00">08:00</option>
              <option value="09:00">09:00</option>
              <option value="10:00">10:00</option>
              <option value="11:00">11:00</option>
              <option value="12:00">12:00</option>
              <option value="13:00">13:00</option>
              <option value="14:00">14:00</option>
              <option value="15:00">15:00</option>
              <option value="16:00">16:00</option>
              <option value="17:00">17:00</option>
              <option value="18:00">18:00</option>
              <option value="19:00">19:00</option>
              <option value="20:00">20:00</option>
              <option value="21:00">21:00</option>
              <option value="22:00">22:00</option>
              <option value="23:00">23:00</option>
              <option value="23:59">24:00</option>
            </select>
          </div>
          <div className="SlidingMenuMoreDetail">زمان شروع این قانون. توجه داشته باشید این ساعت باید کوچکتر از ساعت پایان باشد.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">زمان پایان</div>
          <div className="SlidingMenuTextBox DropDownContainer">
            <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId" onChange={(e) => setEndTimeRestriction(e.target.value)}>
              <option value="Choose a Device Type" disabled selected>
                لطفا زمان شروع مورد نظر را انتخاب نمایید.
              </option>
              <option value="01:00">01:00</option>
              <option value="02:00">02:00</option>
              <option value="03:00">03:00</option>
              <option value="04:00">04:00</option>
              <option value="05:00">05:00</option>
              <option value="06:00">06:00</option>
              <option value="07:00">07:00</option>
              <option value="08:00">08:00</option>
              <option value="09:00">09:00</option>
              <option value="10:00">10:00</option>
              <option value="11:00">11:00</option>
              <option value="12:00">12:00</option>
              <option value="13:00">13:00</option>
              <option value="14:00">14:00</option>
              <option value="15:00">15:00</option>
              <option value="16:00">16:00</option>
              <option value="17:00">17:00</option>
              <option value="18:00">18:00</option>
              <option value="19:00">19:00</option>
              <option value="20:00">20:00</option>
              <option value="21:00">21:00</option>
              <option value="22:00">22:00</option>
              <option value="23:00">23:00</option>
              <option value="23:59">24:00</option>
            </select>
          </div>
          <div className="SlidingMenuMoreDetail">زمان پایان این قانون. توجه داشته باشید این ساعت باید بزرگتر از ساعت شروع باشد.</div>
        </div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">خصوصی / عمومی</div>
        <div className="FullWidthFlex">
          <div className="HalfWidthContainer">
            <div className="PrivecyTypeContainer">
              <input type="radio" onClick={() => setServiceRulesisPrivate(false)} id="Public" name="PrivacyType" value="عمومی"></input>
              <label>عمومی</label>
            </div>
          </div>
          <div className="HalfWidthContainer">
            <div className="PrivecyTypeContainer">
              <input type="radio" onClick={() => setServiceRulesisPrivate(true)} id="Private" name="PrivacyType" value="خصوصی"></input>
              <label>خصوصی</label>
            </div>
          </div>
        </div>

        <div className="SlidingMenuMoreDetail">در صورت خصوصی بودن قانون، انتخاب سرویس بعد از رزرو توسط اولین فرد برای دیگران در ساعت انتخاب شده مقدور نخواهد بود.</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>

        <div className="ButtonsContainer">
          {endTimeRestriction > startTimeRestriction && ServiceRulesTitle && ServiceRulesPrice && endTimeRestriction && startTimeRestriction && serviceRulesisPrivate !== null ? (
            <button type="sumbit" className="SaveButton" onClick={(e) => addNewServiceRuleHandler(e)}>
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <button disabled type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </button>
          )}

          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddServiceRulesSlidingMenu);

import React, { useState } from "react";
import { toast } from "react-toastify";
import "../../../components/ui/modal/modal.css";
import { deleteServiceTypeById } from "./../../../Services/managementServices";

export default function DeleteModal(props) {
  const [deleteTextBox, setDeleteTextBox] = useState("");

  const deleteHandler = async (e, id) => {
    e.preventDefault();

    try {
      const { status } = await deleteServiceTypeById(id);
      if (status === 200) {
        toast.success("Deleted Successfully");
        props.onClickCloseHandler();
        props.onClickRefresh();
      }
    } catch (err) {
      console.log(err.response.data.ErrorDescription);
      toast.error(err.response ? err.response.data.ErrorDescription : "Something went wrong!");
    }
  };

  return (
    <form className="DeleteModalContainer">
      <div className="DeleteModalInsideContainer">
        <div className="DeleteIconInsideModal">
          <span className="icon-closeX DeleteIconBorder"></span>
        </div>
        <div className=" HorizontallyCenteredText">
          <div className="ModalDetail">
            شما در حال حذف کردن کامل سرویس <span className="DeleteText">{props.CurrentServiceTypes.title}</span> هستید. برای تایید لطفا کلمه <span className="DeleteText">Delete</span> را با حفظ کوچک یا بزرگ بودن حروف در کادر زیر بنویسید.
          </div>
          <input className="FullWidthContainer ModalTextBox HorizontallyCenteredText" onChange={(e) => setDeleteTextBox(e.target.value)} id="ServiceTypesTitle" />
        </div>
        <div className="DeleteModalButtons">
          {deleteTextBox === "Delete" ? (
            <button onClick={(e) => deleteHandler(e, props.CurrentServiceTypes.id)} type="sumbit" className="SaveButton">
              حذف
            </button>
          ) : (
            <div className="SaveButton ButtonDisabled">حذف</div>
          )}
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            بستن
          </div>
        </div>
      </div>
    </form>
  );
}

import React from "react";
import { NavLink } from "react-router-dom";
import SmallCardView from "../../components/ui/smallcardview/SmallCardView";

export default function Settings() {
  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        <div className="ResponsiveGridViewer">
          <NavLink exact to="/LanguageSettings">
            <SmallCardView Title={"Languages"} Description={"Determine supported languages"} Icon={"icon-language"} />
          </NavLink>
          <NavLink exact to="/">
            <SmallCardView Title={"Currency"} Description={"Set the general currency"} Icon={"icon-money-currency"} />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

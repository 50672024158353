export const serviceRulesReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSERVICERULES":
      return { ...action.payload };
    default:
      return state;
  }
};

export const serviceTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSERVICETYPES":
      return { ...action.payload };
    default:
      return state;
  }
};

export const selectedServiceRulesReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDSERVICERULES":
      return { ...action.payload };
    default:
      return state;
  }
};

export const selectedServiceTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDSERVICETYPES":
      return { ...action.payload };
    default:
      return state;
  }
};

export const serviceTypeDevicesByIdReducer = (state = [], action) => {
  switch (action.type) {
    case "GETSERVICETYPEDEVICESBYID":
      return [...action.payload];
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import Loading from "../../../components/ui/loading/Loading";
import { withRouter } from "react-router-dom";
import { getPlateById, getResidentById, PutALicensePlate, PutAResident } from "../../../Services/managementServices";
import { toast } from "react-toastify";
import { PlateCharacters } from "./PlatesCharacters";

function EditLicensePlate({ history }) {
  const selectedCheckBox = history.location.state.id;

  const [licensePlateFirstNumber, setLicensePlateFirstNumber] = useState("");
  const [licensePlateSecondNumber, setlicensePlateSecondNumber] = useState("");
  const [licensePlateCharacter, setlicensePlateCharacter] = useState("");
  const [licensePlateCityCode, setlicensePlateCityCode] = useState("");
  const [licensePlateNote, setLicensePlateNote] = useState("");
  const [character, setCharacter] = useState("");

  useEffect(() => {
    fillStates();
  }, []);

  const fillStates = async () => {
    const { data, status } = await getPlateById(selectedCheckBox);
    if (status === 200) {
      setLicensePlateFirstNumber(data.firstNumber);
      setlicensePlateSecondNumber(data.secondNumber);
      setlicensePlateCityCode(data.cityCode);
      setlicensePlateCharacter(PlateCharacters.find((x) => x.english === data.character).value);
    }
  };

  const updateLicensePlateHandler = async (e) => {
    e.preventDefault();

    try {
      const { status } = await PutALicensePlate(selectedCheckBox, licensePlateFirstNumber, licensePlateSecondNumber, PlateCharacters.find((x) => x.farsi === licensePlateCharacter).value, licensePlateCityCode, licensePlateNote);

      if (status === 200) {
        toast.success("تغییرات با موفقیت اعمال شد");
        fillStates();
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.ErrorDescription : "Something went wrong!");
    }
  };

  return (
    <div className="ContentBody">
      <div className="BodyPadding">
        <div className="EditPageThreeColumnNoBG">
          <div className="TabInfoContainer">
            <div class="PostHeaderTitle">اطلاعات پلاک</div>
            <div className="TabInfoGridNum">
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">شماره اول </div>
                <input className="SlidingMenuTextBox" value={licensePlateFirstNumber} onChange={(e) => setLicensePlateFirstNumber(e.target.value)} />
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">شماره دوم </div>
                <input className="SlidingMenuTextBox" value={licensePlateSecondNumber} onChange={(e) => setlicensePlateSecondNumber(e.target.value)} />
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">نوع کاربری</div>
                <div className="SlidingMenuTextBox DropDownContainer">
                  <select
                    value={licensePlateCharacter}
                    className="DropDownSelectNoFlag"
                    name="UsertypeId"
                    id="UsertypeId"
                    defaultValue="Choose a Device Type"
                    onChange={(e) => {
                      setlicensePlateCharacter(e.target.value);
                    }}>
                    <option value="Choose a Device Type" disabled>
                      لطفا انتخاب کنید
                    </option>

                    {PlateCharacters
                      ? PlateCharacters.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.farsi}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">کد شهر </div>
                <input className="SlidingMenuTextBox" value={licensePlateCityCode} onChange={(e) => setlicensePlateCityCode(e.target.value)} />
              </div>

              <div className="LabelAndTextBoxContainer">
                <div className="SlidingMenuItemTitle">پیام ورود پلاک</div>
                <textarea className="SlidingMenuTextArea" id="ProjectDescription" value={licensePlateNote} onChange={(e) => setLicensePlateNote(e.target.value)} />
                <div className="SlidingMenuMoreDetail">این پیام هنگام ورود پلاک به پارکینگ به مدیران واحد نمایش داده می شود.</div>
              </div>
            </div>
            {licensePlateFirstNumber && licensePlateSecondNumber && licensePlateCharacter && licensePlateCityCode ? (
              <div className="LabelAndTextBoxContainer">
                <button type="sumbit" onClick={(e) => updateLicensePlateHandler(e)} class="SaveButton" id="SaveButton">
                  <span class="icon-SaveButton"></span>ذخیره
                </button>
              </div>
            ) : (
              <div className="LabelAndTextBoxContainer">
                <button type="sumbit" disabled class="SaveButton ButtonDisabled" id="SaveButton">
                  <span class="icon-SaveButton"></span>ذخیره
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EditLicensePlate);


import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

const token = JSON.parse(localStorage.getItem("residentialManagement"));
if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token.userData.token}`;



const methods = {
  get: axios.get, //restfull api
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
export default methods;

import React from "react";
import Pagination from "../../../components/pagination/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { actionGetAllUnits } from "../../../actions";
import { UnitResidency } from "./UnitResidency";
import { UnitTypes } from "./UnitTypes";
import moment from "jalali-moment";

export default function UnitGridTable(props) {
  const myUnits = useSelector((state) => state.allUnits);
  const dispatch = useDispatch();

  const [filterUnitnumber, setFilterUnitnumber] = useState("");
  const [filterUnitResidency, setFilterUnitResidency] = useState("");
  const [filterUnitType, setFilterUnitType] = useState("");
  const [filterUnitDateOfResidence, setFilterUnitDateOfResidence] = useState("");
  const [filterUnitSquare, setFilterUnitSquare] = useState("");

  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [pageSize, setPageSize] = useState(8);

  const filterData = (searchVal, field) => {
    let filterData = [];
    let filternumber = field === "number" ? searchVal : filterUnitnumber;
    let filterResidency = field === "residency" ? searchVal : filterUnitResidency;
    let filterType = field === "type" ? searchVal : filterUnitType;
    let filterDateOfResidence = field === "dateOfResidence" ? searchVal : filterUnitDateOfResidence;
    let filterSquare = field === "square" ? searchVal : filterUnitSquare;

    if (field === "number") {
      setFilterUnitnumber(searchVal);
    } else if (field === "residency") {
      setFilterUnitResidency(searchVal);
    } else if (field === "type") {
      setFilterUnitType(searchVal);
    } else if (field === "dateOfResidence") {
      setFilterUnitDateOfResidence(searchVal);
    } else if (field === "square") {
      setFilterUnitSquare(searchVal);
    }

    if (filternumber) filterData.push({ searchField: "number", searchValue: filternumber, operatorType: "Equals" });
    if (filterResidency) filterData.push({ searchField: "residency", searchValue: filterResidency, operatorType: "Equals" });
    if (filterType) filterData.push({ searchField: "type", searchValue: filterType, operatorType: "Equals" });
    if (filterDateOfResidence) filterData.push({ searchField: "dateOfResidence", searchValue: filterDateOfResidence, operatorType: "Equals" });
    if (filterSquare) filterData.push({ searchField: "square", searchValue: filterSquare, operatorType: "Equals" });

    dispatch(actionGetAllUnits(1, pageSize, filterData));
  };

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox.id} IsSelected={isSelected} NavlinkAdress={"/EditUnit"} />
      {myUnits.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container DataGridHeader">
                <div className="attribute Firstchild">شماره ردیف</div>

                <div className="attribute">شماره واحد</div>
                <div className="attribute">وضعیت پر/خالی</div>
                <div className="attribute">نوع ساکن</div>
                <div className="attribute">نوع واحد</div>
                <div className="attribute">وضعیت مالی</div>
                <div className="attribute">تاریخ شروع سکونت</div>
                <div className="attribute">متراژ (متر مربع)</div>
              </li>
              <li className="item item-container SearchRow">
                <div className="attribute Firstchild">
                  <span className="icon-Search"></span>
                  <div>جستجو</div>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="جستجو شماره واحد" name="number" value={filterUnitnumber} onChange={(e) => filterData(e.target.value, "number")}></input>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="جستجو وضعیت پر/خالی"></input>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="جستجو نوع ساکن" name="residency" value={filterUnitResidency} onChange={(e) => filterData(e.target.value, "residency")}></input>
                </div>
                <div className="GridItemSearchContainer">
                  <select className="GridItemSearch" name="unitType" id="unitType" defaultValue="-1" onChange={(e) => filterData(e.target.value, "type")}>
                    <option value="">جستجو نوع واحد</option>
                    {UnitTypes
                      ? UnitTypes.map((item, index) => (
                          <option key={index} value={item.english}>
                            {item.farsi}
                          </option>
                        ))
                      : null}
                  </select>
                </div>

                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="جستجو وضعیت مالی"></input>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="تعیین بازه تاریخ" name="dateOfResidence" value={filterUnitDateOfResidence} onChange={(e) => filterData(e.target.value, "dateOfResidence")}></input>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="تعیین بازه متراژ" name="square" value={filterUnitSquare} onChange={(e) => filterData(e.target.value, "square")}></input>
                </div>
              </li>
              <form>
                {myUnits.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="BuildingResidentsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="number">
                        {item.number}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="isEmpty Name">
                        {item.isEmpty ? <div>پر</div> : <div>خالی</div>}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="residency">
                        {UnitResidency.find((x) => x.english === item.residency).farsi}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="type">
                        {UnitTypes.find((x) => x.english === item.type).farsi}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="BuildingResidents Name">
                        {""}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="dateOfResidence">
                        {moment(item.dateOfResidence.split("T")[0]).locale("fa").format("YYYY-DD-MM")}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="squareName">
                        {item.square}
                      </div>
                    </div>

                    {/* <div className="attribute-container part-id">
                      <div className="attribute" data-name="Role Name">
                        {item.isActive ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>}
                      </div>
                    </div> */}
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myUnits.pageIndex}
            //SearchField1={residentSearchUsername}
            PageSize={props.PassPageSize}
            TotalCount={myUnits.totalCount}
            TotalPages={myUnits.totalPages}
            HasPreviousPage={myUnits.hasPreviousPage}
            HasNextPage={myUnits.hasNextPage}
            actionType="Units"
          />
        </div>
      ) : null}
    </>
  );
}

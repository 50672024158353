import React, { useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { actionGetAllNoneAssociatedLicensePlates } from "../../../actions";
import { PlateCharacters } from "../licenseplate/PlatesCharacters";
import { SyncNoneAssociatedplate } from "../../../Services/managementServices";
import { toast } from "react-toastify";

export default function NoneAssociatedLicensePlateGridTable(props) {
  const dispatch = useDispatch();

  const [NoneAssociatedLicensePlateFirstNumber, setNoneAssociatedLicensePlateFirstNumber] = useState("");
  const [NoneAssociatedLicensePlateSecondNumber, setNoneAssociatedLicensePlateSecondNumber] = useState("");
  const [NoneAssociatedLicensePlateCharacter, setNoneAssociatedLicensePlateCharacter] = useState("");
  const [NoneAssociatedLicensePlateCityCode, setNoneAssociatedLicensePlateCityCode] = useState("");

  const myNoneAssociatedLicensePlates = useSelector((state) => state.allNoneAssociatedLicensePlates);
  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [filterFullPlate, setFilterFullPlate] = useState("");
  const [pageSize, setPageSize] = useState(8);

  const syncronization = async () => {
    const { status } = await SyncNoneAssociatedplate();
    if (status === 200) {
      toast.success("همگام سازی با موفقیت انجام شد");
    }
  };

  const ResetSearch = () => {
    setNoneAssociatedLicensePlateFirstNumber("");
    setNoneAssociatedLicensePlateSecondNumber("");
    setNoneAssociatedLicensePlateCharacter("");
    setNoneAssociatedLicensePlateCityCode("");
    dispatch(actionGetAllNoneAssociatedLicensePlates(1, pageSize, "", ""));
  };

  const filterData = (firstNumber, plateCharacter, secondNumber, cityCode) => {
    if (firstNumber !== null) setNoneAssociatedLicensePlateFirstNumber(firstNumber);
    if (plateCharacter !== null) setNoneAssociatedLicensePlateCharacter(plateCharacter);
    if (secondNumber !== null) setNoneAssociatedLicensePlateSecondNumber(secondNumber);
    if (cityCode !== null) setNoneAssociatedLicensePlateCityCode(cityCode);

    var searchFirstNumberVal = firstNumber !== null ? firstNumber : NoneAssociatedLicensePlateFirstNumber;
    var searchCharacterVal = plateCharacter !== null ? plateCharacter : NoneAssociatedLicensePlateCharacter;
    var searchSecondNumberVal = secondNumber !== null ? secondNumber : NoneAssociatedLicensePlateSecondNumber;
    var searchCityCodeVal = cityCode !== null ? cityCode : NoneAssociatedLicensePlateCityCode;

    // Combine the values to create the full filter string
    var searchFullText = searchFirstNumberVal + ":" + searchCharacterVal + ":" + searchSecondNumberVal + ":" + searchCityCodeVal;

    dispatch(actionGetAllNoneAssociatedLicensePlates(1, pageSize, searchFullText, ""));
  };

  return (
    <>
      <CrudOperators
        onClickOpenHandler={props.onClickOpenHandler}
        onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)}
        SelectedId={selectedCheckBox.id}
        IsSelected={isSelected}
        NavlinkAdress={"/EditNoneAssociatedLicensePlate"}
      />
      {/* <div className="CrudContainer">
        <div className="ButtonsContainer">
          <div onClick={props.onClickOpenHandler} className="BackgorundPrimaryColor button-left">
            <span className="icon-add"></span>
            اضافه با اکسل
          </div>
        </div>
      </div> */}
      {myNoneAssociatedLicensePlates.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container DataGridHeader">
                <div className="attribute Firstchild">شماره ردیف</div>

                <div className="attribute">شماره اول</div>
                <div className="attribute">شماره دوم</div>
                <div className="attribute">حرف</div>
                <div className="attribute">کد شهر</div>
                <div className="attribute">پلاک کامل</div>
                <div className="attribute">شماره واحد</div>
              </li>
              <li className="item item-container">
                <div className="attribute Firstchild">جستجو</div>
                <div className="Platesearchcontainer">
                  <div className="GridItemSearchContainer">
                    <div className="GridLicensePlate">
                      <div className="PlateMainContainerForGrid">
                        <span className="PlateCharactertemplate" />
                        <input
                          className="PlateCharacterHolder"
                          maxLength="2"
                          onChange={(e) => {
                            filterData(null, null, null, e.target.value);
                          }}
                          type="tel"
                          placeholder="⚊ ⚊"
                          id="carPlateSection1"
                          value={NoneAssociatedLicensePlateCityCode}
                        />

                        <select
                          className="PlateCharacterHolder"
                          name="UsertypeId"
                          id="UsertypeId"
                          defaultValue="Choose a Device Type"
                          value={NoneAssociatedLicensePlateCharacter}
                          onChange={(e) => {
                            filterData(null, e.target.value, null, null);
                          }}>
                          <option value="Choose a Device Type" disabled>
                            حرف
                          </option>

                          {PlateCharacters
                            ? PlateCharacters.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.farsi}
                                </option>
                              ))
                            : null}
                        </select>
                        <input
                          className="PlateCharacterHolder PlateDivider"
                          maxLength="3"
                          onChange={(e) => {
                            filterData(null, null, e.target.value, null);
                          }}
                          type="tel"
                          placeholder="⚊ ⚊ ⚊"
                          id="carPlateSection1"
                          value={NoneAssociatedLicensePlateSecondNumber}
                        />
                        <input
                          className="PlateCharacterHolder"
                          maxLength="2"
                          onChange={(e) => {
                            filterData(e.target.value, null, null, null);
                          }}
                          type="tel"
                          placeholder="⚊ ⚊"
                          id="carPlateSection1"
                          value={NoneAssociatedLicensePlateFirstNumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div onClick={(e) => ResetSearch()} className="ClearButton">
                    <span className="icon-trash"></span>
                    پاک کردن
                  </div>
                </div>

                <div className="GridItemSearchContainer">
                  <div onClick={(e) => syncronization()} className="ClearButton">
                    <span className="icon-checked"></span>
                    هنگام سازی
                  </div>
                </div>
              </li>
              <form>
                {myNoneAssociatedLicensePlates.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="ResidentsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate firstNumber">
                        {item.firstNumber}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate secondNumber">
                        {item.secondNumber}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate character">
                        {PlateCharacters.find((x) => x.english === item.character).farsi}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate cityCode">
                        {item.cityCode}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate fullText">
                        <div className="GridLicensePlate">
                          <div className="PlateMainContainerForGrid">
                            <span className="PlateCharactertemplate" />
                            <div className="PlateCharacterHolder">{item.firstNumber}</div>
                            <div className="PlateCharacterHolder">{PlateCharacters.find((x) => x.english === item.character).farsi}</div>
                            <div className="PlateCharacterHolder PlateDivider"> {item.secondNumber}</div>
                            <div className="PlateCharacterHolder">{item.cityCode}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate cityCode">
                        {item.unitNumber}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myNoneAssociatedLicensePlates.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myNoneAssociatedLicensePlates.totalCount}
            TotalPages={myNoneAssociatedLicensePlates.totalPages}
            HasPreviousPage={myNoneAssociatedLicensePlates.hasPreviousPage}
            HasNextPage={myNoneAssociatedLicensePlates.hasNextPage}
            actionType="NoneAssociatedLicensePlates"
          />
        </div>
      ) : null}
    </>
  );
}

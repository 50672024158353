import React, { useEffect, useState } from "react";
import { PlateCharacters } from "../../../BuildingManagement/licenseplate/PlatesCharacters";
import { getAllGatesStats } from "../../../../Services/managementServices";
import LoadingUpload from "../../../../components/ui/loadingupload/LoadingUpload";

export default function PlateView() {
  const [fetchedGatesPlates, setFetchedGatesPlates] = useState([]);
  const [theLasthreePlates, setTheLasthreePlates] = useState([]);
  const [activeTitle, setActiveTitle] = useState(null);
  const [activeTitleCount, setActiveTitleCount] = useState(null);
  const [loadingHandler, setLoadingHandler] = useState(false);

  useEffect(() => {
    getGatesStats();
  }, []);

  useEffect(() => {
    // Set the default active title to the title at index 0
    if (fetchedGatesPlates.length > 0) {
      setActiveTitle(fetchedGatesPlates[0].gateTitle);
      handleTitleClick(fetchedGatesPlates[0].gateTitle); // Fetch data for default active title
    }
  }, [fetchedGatesPlates]);

  const getGatesStats = async () => {
    setLoadingHandler(true);
    const { status, data } = await getAllGatesStats();

    if (status === 200 && data && Array.isArray(data)) {
      const fetchedData = [];
      data.forEach((gateData) => {
        const reversedEntries = gateData.plateInformation.reverse();
        const selectedData = [];
        const seenPlateFullTexts = new Set();

        for (const entry of reversedEntries) {
          const { plateFullText, date, unitNumber } = entry;

          if (!seenPlateFullTexts.has(plateFullText)) {
            selectedData.push({ plateFullText, date, unitNumber });
            seenPlateFullTexts.add(plateFullText);

            if (selectedData.length === 3) {
              break;
            }
          }
        }

        fetchedData.push({
          gateTitle: gateData.gate.title,
          plateData: selectedData,
          count: gateData.count,
        });
      });

      setFetchedGatesPlates(fetchedData);
      setLoadingHandler(false);
    }
  };

  const handleTitleClick = (clickedTitle) => {
    setActiveTitle(clickedTitle);
    const clickedGateData = fetchedGatesPlates.find((plate) => plate.gateTitle === clickedTitle);
    if (clickedGateData) {
      setActiveTitleCount(clickedGateData.count);

      const reversedEntries = clickedGateData.plateData.reverse();
      const selectedData = [];
      const seenPlateFullTexts = new Set();

      for (const entry of reversedEntries) {
        const { plateFullText, date, unitNumber } = entry;

        if (!seenPlateFullTexts.has(plateFullText)) {
          selectedData.push({ plateFullText, date, unitNumber });
          seenPlateFullTexts.add(plateFullText);

          if (selectedData.length === 3) {
            break;
          }
        }
      }

      setTheLasthreePlates(selectedData);
    }
  };

  return (
    <div className="PlateInfoContainer">
      <div className="PlateInfoCard">
        {loadingHandler ? (
          <LoadingUpload />
        ) : (
          <>
            <div className="GateTitlesScroll">
              {fetchedGatesPlates.map((fetchedGatesPlate, index) => (
                <div key={index} className={`GateTitles ${fetchedGatesPlate.gateTitle === activeTitle ? "GateTitles GateTitlesActive" : "GateTitles"}`} onClick={() => handleTitleClick(fetchedGatesPlate.gateTitle)}>
                  {fetchedGatesPlate.gateTitle}
                </div>
              ))}
            </div>
            <div>تعداد تقریبی پلاک های تردد کرده امروز: {activeTitleCount}</div>
            <div>تعداد پلاک مهمان ثبت شده فعال: 8</div>
          </>
        )}
      </div>

      <div className="GridViewAndPaginationContainer">
        <div className="TableGridViewContainer">
          <ol className="collection collection-container">
            <li className="item item-container DataGridHeader">
              <div className="attribute Firstchild">سه تردد آخر</div>
              <div className="attribute">پلاک</div>
              <div className="attribute">ساعت</div>
              <div className="attribute">شماره واحد</div>
            </li>

            {theLasthreePlates ? (
              <form>
                {theLasthreePlates.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      {index + 1}
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate">
                        <div className="GridLicensePlate">
                          <div className="PlateMainContainerForGrid">
                            <span className="PlateCharactertemplate" />
                            <div className="PlateCharacterHolder">{item.plateFullText.split(":")[0]}</div>
                            <div className="PlateCharacterHolder">{PlateCharacters.find((x) => x.value === parseInt(item.plateFullText.split(":")[1])).farsi}</div>
                            <div className="PlateCharacterHolder PlateDivider"> {item.plateFullText.split(":")[2]}</div>
                            <div className="PlateCharacterHolder">{item.plateFullText.split(":")[3]}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Time">
                        {item.date ? new Date(item.date).toLocaleTimeString("fa-IR") : null}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Unit Number">
                        {item.unitNumber ? item.unitNumber : "مهمان"}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            ) : null}
          </ol>
        </div>
      </div>
    </div>
  );
}

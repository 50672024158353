import React, { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./assets/css/colorresource.css";
import "./assets/css/darkcolorresource.css"; // Add this line
import DarkModeContext from "./components/darkmode/DarkModeContext"; // Update import

import LoginPage from "./pages/loginpage/LoginPage";
import LandingPage from "./pages/landingpage/LandingPage";

import ServiceTypes from "./pages/BuildingManagement/servicetypes/ServiceTypes";
import LicensePlate from "./pages/BuildingManagement/licenseplate/LicensePlate";
import NoneAssociatedLicensePlate from "./pages/BuildingManagement/noneassociatedlicenseplate/NoneAssociatedLicensePlate";

import Staffs from "./pages/Management/staffs/Staffs";
import Roles from "./pages/Management/roles/Roles";
import EditRole from "./pages/Management/roles/EditRole";
import EditStaff from "./pages/Management/staffs/EditStaff";
import Resources from "./pages/Management/resources/Resources";
import ResourceDetail from "./pages/Management/resources/ResourceDetail";

import Announcements from "./pages/mirrorsection/announcements/Announcements";
import EditAnnouncement from "./pages/mirrorsection/announcements/EditAnnouncement";
import MirrorCompliments from "./pages/mirrorsection/mirrorcompliment/MirrorCompliments";
import EditMirrorCompliment from "./pages/mirrorsection/mirrorcompliment/EditMirrorCompliment";

import Settings from "./pages/settings/Settings";
import Layout from "./components/layout/Layout";
import { useDispatch } from "react-redux";
import { loginAction, isLoggedIn } from "./actions/index";
import Logout from "./components/ui/logout/Logout";
import EditServiceTypes from "./pages/BuildingManagement/servicetypes/EditServiceTypes";
import Residents from "./pages/Management/Residents/Residents";
import EditResident from "./pages/Management/Residents/EditResident";
import ServiceRules from "./pages/BuildingManagement/servicerules/ServiceRules";
import EditServiceRules from "./pages/BuildingManagement/servicerules/EditServiceRules";
import Devices from "./pages/Management/Devices/Devices";
import WeatherForecast from "./pages/mirrorsection/weatherforecast/WeatherForecast";
import Projects from "./pages/Management/Projects/Projects";
import EditProject from "./pages/Management/Projects/EditProject";
import AdminLoginPage from "./pages/loginpage/AdminLoginPage";
import EditLicensePlate from "./pages/BuildingManagement/licenseplate/EditLicensePlate";
import EditNoneAssociatedLicensePlate from "./pages/BuildingManagement/noneassociatedlicenseplate/EditNoneAssociatedLicensePlate";
import BuildingResidents from "./pages/BuildingManagement/buildingresidents/BuildingResidents";
import EditBuildingResident from "./pages/BuildingManagement/buildingresidents/EditBuildingResident";
import BuildingProject from "./pages/BuildingManagement/buildingproject/BuildingProject";
import Unit from "./pages/BuildingManagement/unit/Unit";
import EditUnit from "./pages/BuildingManagement/unit/EditUnit";
import Expenses from "./pages/BuildingManagement/expenses/Expenses";
import BuildingExpenses from "./pages/BuildingManagement/buildingexpenses/BuildingExpenses";
import Gates from "./pages/BuildingManagement/gates/Gates";
import BillingManagement from "./pages/BuildingManagement/BillingManagement/BillingManagement";
import Transactions from "./pages/BuildingManagement/BillingManagement/Transactions";

const App = () => {
  const { darkMode } = useContext(DarkModeContext); // Use useContext to access context value

  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("residentialManagement"));
    setUserType(JSON.parse(localStorage.getItem("residentialManagement")));
    if (token) {
      const dateNow = Date.now() / 1000;
      if (token.decodedToken.exp < dateNow) {
        localStorage.removeItem("residentialManagement");
        dispatch(loginAction(null));
        dispatch(isLoggedIn(false));
      } else {
        dispatch(loginAction(token));
        dispatch(isLoggedIn(true));
      }
    } else {
      //window.location.reload()
    }
  }, []);

  return (
    <div className={darkMode ? "dark-mode App" : "light-mode App"}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <LoginPage />
          </Route>
          <Route path="/AdminLogin" exact>
            <AdminLoginPage />
          </Route>
          <Route path="/Logout" exact>
            <Logout />
          </Route>

          {userType && userType.userData.userType === "BuildingAdmin" ? (
            <Layout>
              <Route path="/LandingPage" exact>
                <LandingPage />
              </Route>

              <Route path="/ServiceTypes">
                <ServiceTypes />
              </Route>
              <Route path="/EditServiceTypes">
                <EditServiceTypes />
              </Route>
              <Route path="/LicensePlate">
                <LicensePlate />
              </Route>
              <Route path="/NoneAssociatedLicensePlate">
                <NoneAssociatedLicensePlate />
              </Route>
              <Route path="/Unit">
                <Unit />
              </Route>
              <Route path="/EditUnit">
                <EditUnit />
              </Route>
              <Route path="/EditLicensePlate">
                <EditLicensePlate />
              </Route>
              <Route path="/EditNoneAssociatedLicensePlate">
                <EditNoneAssociatedLicensePlate />
              </Route>
              <Route path="/ServiceRules">
                <ServiceRules />
              </Route>
              <Route path="/EditServiceRules">
                <EditServiceRules />
              </Route>
              <Route path="/BuildingResidents">
                <BuildingResidents />
              </Route>
              <Route path="/EditBuildingResident">
                <EditBuildingResident />
              </Route>
              <Route path="/BuildingProject">
                <BuildingProject />
              </Route>

              <Route path="/Announcements">
                <Announcements />
              </Route>
              <Route path="/EditAnnouncement">
                <EditAnnouncement />
              </Route>
              <Route path="/Devices">
                <Devices />
              </Route>
              <Route path="/MirrorCompliments">
                <MirrorCompliments />
              </Route>
              <Route path="/EditMirrorCompliment">
                <EditMirrorCompliment />
              </Route>

              <Route path="/Expenses">
                <Expenses />
              </Route>
              <Route path="/BuildingExpenses">
                <BuildingExpenses />
              </Route>
              <Route path="/Gates">
                <Gates />
              </Route>

              <Route path="/BillingManagement">
                <BillingManagement />
              </Route>
              <Route path="/Transactions">
                <Transactions />
              </Route>

              <Route path="/WeatherForecast">
                <WeatherForecast />
              </Route>
              <Route path="/Settings">
                <Settings />
              </Route>
            </Layout>
          ) : (
            <Layout>
              <Route path="/Residents">
                <Residents />
              </Route>
              <Route path="/EditResident">
                <EditResident />
              </Route>
              <Route path="/Staffs">
                <Staffs />
              </Route>
              <Route path="/Roles">
                <Roles />
              </Route>
              <Route path="/EditRole">
                <EditRole />
              </Route>
              <Route path="/EditStaff">
                <EditStaff />
              </Route>
              <Route path="/Resources">
                <Resources />
              </Route>
              <Route path="/ResourceDetail">
                <ResourceDetail />
              </Route>
              <Route path="/Projects">
                <Projects />
              </Route>
              <Route path="/EditProject">
                <EditProject />
              </Route>
            </Layout>
          )}
        </Switch>

        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </BrowserRouter>
    </div>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import config from "../../../Services/config.json";
import { toast } from "react-toastify";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";
import { PutAProject } from "../../../Services/managementServices";

export default function ProjectPictures(props) {
  const [projectName, setProjectName] = useState(props.ProjectName || ""); // Initialize with prop
  const [projectInformation, setProjectInformation] = useState(props.ProjectInformation || ""); // Initialize with prop
  const [projectLogoFileKey, setProjectLogoFileKey] = useState(props.ProjectLogo || null); // Initialize with prop
  const [projectServiceIcon, setProjectServiceIcon] = useState([]);

  const [ProjectImageKey, setProjectImageKey] = useState("");
  const [uploadedHotelImage, setUploadedHotelImage] = useState([]);
  const [progressData, setProgressData] = useState(0);
  const [lastUploadedHotelImage, setLastUploadedHotelImage] = useState([]);
  const [loadingHandler, setLoadingHandler] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const [newItems, setNewItems] = useState([]);

  const fileValidTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];

  useEffect(() => {
    setProjectName(props.ProjectName || "");
    setProjectInformation(props.ProjectInformation || "");
    setProjectLogoFileKey(props.ProjectLogo || "");
    DatafillerAmenities(props.ProjectAmenities);
    Datafiller(props.ProjectPictures || "");
  }, [props.ProjectName, props.ProjectInformation, props.ProjectLogo, props.ProjectPictures, props.ProjectAmenities]);

  const Datafiller = (images) => {
    setUploadedHotelImage(images);
    setLastUploadedHotelImage(images);
  };

  const DatafillerAmenities = (AmenityIcons) => {
    let tempArray = [];

    if (AmenityIcons.length > 0) {
      AmenityIcons.forEach((element) => {
        tempArray.push(element);
      });
      setProjectServiceIcon(tempArray);
    }
  };

  const hotelFileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("فرمت عکس نا مشخص است");
      } else {
        setProgressData(0);
        setLoadingHandler(true);
        const response = await axios.post(`${config.PardikURL}/Images`, imageData, {
          headers: {
            accept: "text/plain",
            bucketName: config.BucketName,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            let progressValue = Math.round((100 * data.loaded) / data.total);
            setProgressData(progressValue);
          },
        });
        if (response.status === 200) {
          let tempArray = newItems;
          tempArray.push(response.data.fileKey);
          setNewItems(tempArray);

          setProjectImageKey(response.data.fileKey);

          updateProjectHandler();

          toast.success("بارگذاری با موفقیت انجام شد");
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("بارگذاری با مشکل مواجه شد!");
      setLoadingHandler(false);
    }
  };

  const imageStatusHandler = (item, status) => {
    if (status === 0) {
      let tempNewItems = newItems.filter((x) => x !== item);
      setNewItems(tempNewItems);
    } else {
      let tempUploadedItems = uploadedHotelImage.filter((x) => x !== item);
      setUploadedHotelImage(tempUploadedItems);
    }
  };

  const updateProjectHandler = async () => {
    let imageKeys = [];
    imageKeys = uploadedHotelImage.concat(newItems);

     const { status } = await PutAProject(props.ProjectId, projectName, projectInformation,null,null,null, imageKeys,null);


    if (status === 200) {
      toast.success("به روز رسانی عکس ها با موفقیت انجام شد");
      setNewItems("");
      props.onClickRefresh();
    } else {
      toast.warning("مشکلی در به روز رسانی پیش آمد. لطفا اینترنت خود را چک کرده و دوباره تلاش کنید.");
    }
  };

  return (
    <>
      <div className="ContentBody">
        <form
          className="formGridScrollable "
          onSubmit={(e) => {
            e.preventDefault();
            updateProjectHandler(e);
          }}>
          <div className="WidthFull GalleryScrollable" id="style-3">
            <div className="GalleryAbsolutScroll">
              <div className="FullWidthGrid4Columns">
                <div className="FullWidthContainer">
                  {loadingHandler ? <input type="file" id="hotelFile" className="uploadButton ButtonDisabled" disabled /> : <input type="file" id="hotelFile" className="uploadButton" onChange={hotelFileSelectedHandler} />}
                  {loadingHandler ? (
                    <div className="ImageGalleryContainer ">
                      <label className="className" htmlFor="hotelFile" disabled>
                        <div className="GalleryImage">بارگذاری عکس</div>
                      </label>
                    </div>
                  ) : (
                    <div className="ImageGalleryContainer ">
                      <label className="UploadImageCursor" htmlFor="hotelFile">
                        <div className="GalleryImage"> بارگذاری عکس</div>
                      </label>
                    </div>
                  )}
                </div>

                {uploadedHotelImage.length > 0
                  ? uploadedHotelImage.map((item, index) => (
                      <div key={index} className="FullWidthContainer">
                        <div className="ImageGalleryContainer">
                          <img className="GalleryImage" src={`https://hestia-api.pardikiot.com/api/images/getfile/${config.BucketName}/${item}`} alt="" />
                          <div>
                            <div onClick={() => imageStatusHandler(item, 1)} className="GalleryRemoveImage">
                              <span className="icon-closeX"></span>
                              حذف
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
                {/* {newItems.length > 0
                  ? newItems.map((item, index) => (
                      <div key={index} className="FullWidthContainer">
                        <div className="ImageGalleryContainer">
                          <div className="ToSaveNotif">برای اضافه شدن این عکس دکمه ذخیره را فشار دهید.</div>
                          <img className="GalleryImage" src={`https://hestia-api.pardikiot.com/api/images/getfile/${config.BucketName}/${item}`} alt="" />
                          <div>
                            <div onClick={() => imageStatusHandler(item, 1)} className="GalleryRemoveImage">
                              <span className="icon-closeX"></span>
                              حذف
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null} */}

                {loadingHandler ? (
                  <div className="FullWidthContainer">
                    <div className="ImageGalleryContainer">
                      <div className="GalleryImage">
                        <LoadingUpload />
                      </div>
                      <div>
                        <div className="GalleryRemoveImage">...در حال بارگذاری</div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="FullWidthContainer">
            <div className="ButtonsContainer">
              <button type="button" className="SaveButton" id="SaveButton" onClick={(e) => updateProjectHandler(e)}>
                <span className="icon-SaveButton"></span>
                ذخیره
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

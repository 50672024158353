import React, { useEffect, useState } from "react";
import LicensePlatesGridTable from "./LicensePlateGridTable";
import AddBatchLicensePlates from "./AddBatchLicensePlates";
import DeleteLicensePlateModal from "./DeleteLicensePlate";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { useDispatch } from "react-redux";
import { actionGetAllLicensePlates } from "../../../actions";

export default function LicensePlate() {
  useEffect(() => {
    getLicensePlates();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentLicensePlates, setCurrentLicensePlates] = useState();

  const getLicensePlates = async (myPageIndex) => {
    dispatch(actionGetAllLicensePlates(1, pageSize, "", ""));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentLicensePlates(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddBatchLicensePlates onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getLicensePlates} />}
        {deleteModalOpan && <DeleteLicensePlateModal CurrentLicensePlates={currentLicensePlates} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getLicensePlates} />}
        <LicensePlatesGridTable onClickRefresh={getLicensePlates} PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PutAResourceItem } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddResourceItemSlidingMenu(props) {
  const [resourceName, setResourceName] = useState("");
  const [resourceKey, setResourceKey] = useState("");

  const reset = () => {
    setResourceName("");
    setResourceKey("");
  };

  const addNewResourceHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await PutAResourceItem(props.ResourceId, resourceName, resourceKey);
    if (status === 200) {
      reset();
      toast.success("Resource added Successfully");
      props.onClickCloseHandler();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن زیر منبع جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">عنوان زیر منبع</div>
        <input className="SlidingMenuTextBox" id="resourceName" value={resourceName} onChange={(e) => setResourceName(e.target.value)} />
        <div className="SlidingMenuMoreDetail">عنوان زیر منبع یا سطح دسترسی یک منبع</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">کلید دسترسی</div>
        <input className="SlidingMenuTextBox" id="resourceKey" value={resourceKey} onChange={(e) => setResourceKey(e.target.value)} />
        <div className="SlidingMenuMoreDetail">کلید مشخص شده در بکند برای این سطح دسترسی</div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            Cancel
          </div>
          <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addNewResourceHandler(e)}>
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddResourceItemSlidingMenu);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { actionGetSelectedUser } from "../../../actions";
import "../../../components/slidingmenu/slidingmenu.css";
import { PutAssignRoleToStaff, getStaffById } from "../../../Services/managementServices";
import { PutAStaff } from "../../../Services/managementServices";
// import Loading from "../../../components/ui/loading/Loading";

function EditStaff({ history }) {
  const mySelectedStaff = useSelector((state) => state.selectedUser);
  const selectedCheckBox = history.location.state.id;
  // const [currentRoles, setCurrenctRoles] = useState([]);

  const [staffFirstName, setStaffFirstName] = useState("");
  const [staffLastName, setStaffLastName] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [staffPassword, setStaffPassword] = useState("");
  const [staffRepeatPassword, setRepeatPassword] = useState("");

  // const [loadingHandler, setLoadingHandler] = useState(false);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   setStaffFirstName(selectedCheckBox.firstname);
  //   setStaffLastName(selectedCheckBox.lastname);
  //   setStaffEmail(selectedCheckBox.email);
  //   dispatch(actionGetSelectedUser(selectedCheckBox));
  // }, []);

  useEffect(() => {
    fillStates();
  }, []);

  const fillStates = async () => {
    const { data, status } = await getStaffById(selectedCheckBox);
    if (status === 200) {
      console.log(data);
      setStaffFirstName(data.firstname);
      setStaffLastName(data.lastname);
      setStaffEmail(data.email);
    }
  };

  // const handleCheckSelectedRoles = (e, selectedRoles) => {
  //   if (selectedRoles) {
  //     //------------------
  //     const copyUserCurrentRoles = [...mySelectedStaff.roles];
  //     const newRole = copyUserCurrentRoles.find((x) => x.roleId === selectedRoles.roleId);
  //     newRole.isSelected = e;

  //     setCurrenctRoles(copyUserCurrentRoles);
  //     //-----------------
  //   }
  // };

  // const updateStaffHandler = async (e) => {
  //   setLoadingHandler(true);
  //   updateStaffRolesHandler(e);
  //   // updateStaffInfoHandler(e);
  //   setTimeout(() => {
  //     setLoadingHandler(false);
  //   }, 1500);
  // };

  // const updateStaffRolesHandler = async (e) => {
  //   e.preventDefault();
  //   const selectedRolesId = [];

  //   currentRoles.length > 0
  //     ? currentRoles.map((item) => {
  //         if (item.isSelected) {
  //           selectedRolesId.push(item.roleId);
  //         }
  //       })
  //     : mySelectedStaff.roles.map((item) => {
  //         if (item.isSelected) {
  //           selectedRolesId.push(item.roleId);
  //         }
  //       });

  //   const { status } = await PutAssignRoleToStaff(selectedCheckBox, selectedRolesId);
  //   if (status === 200) {
  //     toast.success("Roles Assigned Successfully");
  //   }
  // };

  const updateStaffInfoHandler = async (e) => {
    const password = staffPassword ? staffPassword : mySelectedStaff.password;

    if (staffRepeatPassword === staffPassword) {
      const { status } = await PutAStaff(selectedCheckBox, staffFirstName, staffLastName, staffEmail, staffPassword);

      if (status === 200) {
        toast.success("Updated Successfully");
      }
    } else {
      toast.warning("Passwords does not match!");
    }
  };

  return (
    <div className="ContentBody">
      <div className="BodyPadding">
        <div className="ContentBody">
          <div className="BodyPadding">
            <div className="EditPageTwoColumnNoBG">
              <div className="EditPageTwoColumnItemWithBG">
                <div className="ContentBody">
                  <form
                    className="formGrid"
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateStaffInfoHandler(e);
                    }}>
                    <div className="WidthFull">
                      <div className="FullWidthGrid4Columns">
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">رمز عبور</div>
                          <input className="SlidingMenuTextBox" id="StaffPassword" type="password" value={staffPassword ? staffPassword : null} onChange={(e) => setStaffPassword(e.target.value)} />

                          <div className="SlidingMenuMoreDetail">فقط در صورت تمایل در تغییر رمز غبور کاربر پر شود</div>
                        </div>

                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">تکرار رمز عبور</div>
                          <input className="SlidingMenuTextBox" id="StaffRepeatPassword" type="password" value={staffRepeatPassword ? staffRepeatPassword : null} onChange={(e) => setRepeatPassword(e.target.value)} />

                          <div className="SlidingMenuMoreDetail">تکرار رمز عبور جدید در صورت تمایل به تغییر رمز عبور</div>
                        </div>
                        <div className="FullWidthContainer ">
                          <div className="SlidingMenuItemTitle">نام </div>
                          <input className="SlidingMenuTextBox" value={staffFirstName} onChange={(e) => setStaffFirstName(e.target.value)} id="staffFirstName" />
                          <div className="SlidingMenuMoreDetail">نام کاربر اینجا نوشته شود</div>
                        </div>
                        <div className="FullWidthContainer ">
                          <div className="SlidingMenuItemTitle">نام خانوادگی </div>
                          <input className="SlidingMenuTextBox" value={staffLastName} onChange={(e) => setStaffLastName(e.target.value)} id="staffLastName" />
                          <div className="SlidingMenuMoreDetail">نام خانوادگی کاربر اینجا نوشته شود</div>
                        </div>
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">نام کاربری</div>

                          <input disabled className="SlidingMenuTextBox" value={"09198195314"} id="residentUsername" />
                          <div className="SlidingMenuMoreDetail">نام کاربری مختص واحد ثبت شده و غیر قابل تغییر.</div>
                        </div>
                        <div className="FullWidthContainer">
                          <div className="SlidingMenuItemTitle">ایمیل</div>
                          <input className="SlidingMenuTextBox" value={staffEmail} onChange={(e) => setStaffEmail(e.target.value)} id="staffEmail" />
                          <div className="SlidingMenuMoreDetail">ایمیل کاربر. پر کردن این فیلد الزامی نیست.</div>
                        </div>
                      </div>
                      {/* <div className="EditPageTwoColumnItemWithBG">
                        <div className="RoleAssignmenttablecontainer">
                          <div className="EditPageItemBorderMediumDescription">
                            <div className="EditTitle">انتصاب نقش ها</div>
                            <div className="EditDescriptions">در این قسمت نقش های مورد نظرتان را میتوانید به این کاربر تخصیص دهید.</div>
                          </div>
                          <div className="EditPageItemBorderMedium">
                            <div className="EditStaffPageItemHeader">
                              <div className="SlidingMenuTitle">نقش ها</div>
                            </div>
                            {mySelectedStaff.roles
                              ? mySelectedStaff.roles.map((item, index) => (
                                  <div key={index} className="EditStaffPageColomnSelectableItem">
                                    <input className="Checkbox" type="checkbox" name="" id={index} onChange={(e) => handleCheckSelectedRoles(e.target.checked, item)} checked={item.isSelected} />
                                    <div className="FontSizeL">
                                      {item.title} - {item.description}
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div> */}
                    </div>

                    {staffFirstName && staffLastName && staffPassword === staffRepeatPassword ? (
                      <button type="button" onClick={(e) => updateStaffInfoHandler(e)} class="SaveButton" id="SaveButton">
                        <span class="icon-SaveButton"></span>ذخیره
                      </button>
                    ) : (
                      <button type="button" className="SaveButton ButtonDisabled" id="SaveButton">
                        <span className="icon-SaveButton"></span>
                        ذخیره
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EditStaff);

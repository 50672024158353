import React from "react";

export default function CardViewUnits() {
  return (
    <div className="CardViewContainerUnits">
      <div className="CardIconContainer">
        <span className="icon-house CardIconBackground"></span>
      </div>
      <div className="CardViewElements ">
        <div className="CardViewTitleInfo">تعداد واحد های مجموعه: 232</div>
        <div className="CardViewSupplementaryInfo">تعداد واحد های پر: 67</div>
        <div className="CardViewSupplementaryInfo">تعداد واحد های خالی: 165</div>
      </div>
    </div>
  );
}

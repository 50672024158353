import React, { useState } from "react";
import { toast } from "react-toastify";
import { UnitTypes } from "./UnitTypes";
import { UnitResidency } from "./UnitResidency";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import moment from "jalali-moment";
import { useEffect } from "react";
import { PostAUnit, getAllResidentsUnitAdmin } from "../../../Services/managementServices";

export default function AddUnitSlidingMenu(props) {
  useEffect(() => {
    getUnitAdmins();
  }, []);

  const getUnitAdmins = async () => {
    const { status, data } = await getAllResidentsUnitAdmin();
    if (status === 200) {
      setFetchedUnitAdmins(data);
    }
  };

  const [fetchedUnitAdmins, setFetchedUnitAdmins] = useState();

  const [unitNumber, setUnitNumber] = useState("");
  const [unitField, setUnitField] = useState("");
  const [unitfloor, setUnitfloor] = useState("");
  const [unitTitle, setUnitTitle] = useState("");
  const [unitInformation, setUnitInformation] = useState("");
  const [unitPhone, setUnitPhone] = useState("");
  const [unitType, setUnitType] = useState("");
  const [unitResidency, setUnitResidency] = useState("");
  const [unitDateOfResidence, setUnitDateOfResidence] = useState("");
  const [unitDateOfResidenceGregorian, setUnitDateOfResidenceGregorian] = useState("");
  const [unitNumberOfMembers, setUnitNumberOfMembers] = useState("");
  const [unitIsEmpty, setUnitIsEmpty] = useState(false);
  const [unitSquare, setUnitSquare] = useState("");
  const [unitDescription, setUnitDescription] = useState("");
  const [unitLogoFileKey, setUnitLogoFileKey] = useState("");
  const [unitAdmin, setUnitAdmin] = useState("");
  const [unitBlock, setUnitBlock] = useState("");
  const [unitNumberOfPlates, setUnitNumberOfPlates] = useState("");
  const [numberOfActivePlates, setNumberOfActivePlates] = useState("");

  const reset = () => {
    setUnitNumber("");
    setUnitField("");
    setUnitfloor("");
    setUnitTitle("");
    setUnitInformation("");
    setUnitPhone("");
    setUnitType("");
    setUnitResidency("");
    setUnitDateOfResidence("");
    setUnitNumberOfMembers("");
    setUnitIsEmpty(false);
    setUnitSquare("");
    setUnitDescription("");
    setUnitLogoFileKey("");
    setUnitAdmin("");
    setUnitBlock("");
    setUnitNumberOfPlates("");
    setNumberOfActivePlates("");
  };

  const handleSelectedDay = async (selectedDayItem) => {
    const combinedMyDay = selectedDayItem.year + "-" + selectedDayItem.month + "-" + selectedDayItem.day;
    const paramDate = await moment.from(combinedMyDay, "fa", "YYYY-MM-DD").format("YYYY-MM-DD");
    const m = moment(paramDate, "YYYY-MM-DD");
    setUnitDateOfResidence(selectedDayItem);
    setUnitDateOfResidenceGregorian(m._i);
  };

  const addUnitHandler = async (e) => {
    e.preventDefault();

    const { status } = await PostAUnit(unitNumber, unitNumberOfPlates, numberOfActivePlates, unitfloor, unitType, unitResidency, unitDateOfResidenceGregorian, unitNumberOfMembers, unitIsEmpty, unitSquare, unitAdmin, unitBlock);
    if (status === 200) {
      reset();
      toast.success("واحد باموفقیت ثبت شد");
      props.onClickCloseHandler();
      props.onClickRefresh();
      props.ResetPage();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن واحد جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">شماره واحد</div>
          <input
            className="SlidingMenuTextBox"
            id="UnitTitle"
            value={unitNumber}
            onChange={(e) => {
              setUnitNumber(e.target.value);
            }}
          />
        </div>
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">متراژ</div>
          <input className="SlidingMenuTextBox" id="unitSquare" value={unitSquare} onChange={(e) => setUnitSquare(e.target.value)} />
        </div>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">طبقه</div>
          <input className="SlidingMenuTextBox" id="unitfloor" value={unitfloor} onChange={(e) => setUnitfloor(e.target.value)} />
        </div>
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">بلوک</div>
          <input className="SlidingMenuTextBox" id="unitBlock" value={unitBlock} onChange={(e) => setUnitBlock(e.target.value)} />
        </div>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نوع واحد</div>
          <select
            className="PlateCharacterHolder"
            name="unitType"
            id="unitType"
            defaultValue="-1"
            onChange={(e) => {
              setUnitType(e.target.value);
            }}>
            <option value="-1" disabled>
              لطفا انتخاب کنید
            </option>
            {UnitTypes
              ? UnitTypes.map((item, index) => (
                  <option key={index} value={item.english}>
                    {item.farsi}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نوع ساکن</div>
          <select
            className="PlateCharacterHolder"
            name="unitResidency"
            id="unitResidency"
            defaultValue="-1"
            onChange={(e) => {
              setUnitResidency(e.target.value);
            }}>
            <option value="-1" disabled>
              لطفا انتخاب کنید
            </option>
            {UnitResidency
              ? UnitResidency.map((item, index) => (
                  <option key={index} value={item.english}>
                    {item.farsi}
                  </option>
                ))
              : null}
          </select>
        </div>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">تاریخ شروع سکونت</div>

          <DatePicker value={unitDateOfResidence} onChange={(val) => handleSelectedDay(val)} inputPlaceholder="" shouldHighlightWeekends locale="fa" />
        </div>
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">تعداد ساکنین (مینیمم 1)</div>
          <input className="SlidingMenuTextBox" id="unitNumberOfMembers" value={unitNumberOfMembers} onChange={(e) => setUnitNumberOfMembers(e.target.value)} />
        </div>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">تعداد پلاک مجاز برای ثبت</div>
          <input className="SlidingMenuTextBox" id="unitNumberOfPlates" value={unitNumberOfPlates} onChange={(e) => setUnitNumberOfPlates(e.target.value)} />
          <div className="SlidingMenuMoreDetail">بیشترین تعداد پلاکی که این واحد میتواند تعریف کند.</div>
        </div>
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">محدودیت ورود</div>
          <input className="SlidingMenuTextBox" id="numberOfActivePlates" value={numberOfActivePlates} onChange={(e) => setNumberOfActivePlates(e.target.value)} />
          <div className="SlidingMenuMoreDetail">بیشترین تعداد ماشین(پلاک) هایی که همزمان از واحد میتوانند وارد پارکینگ شوند.</div>
        </div>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">مدیر واحد</div>
          <select
            className="PlateCharacterHolder"
            name="unitAdmin"
            id="unitAdmin"
            defaultValue="-1"
            onChange={(e) => {
              setUnitAdmin(e.target.value);
            }}>
            <option value="-1" disabled>
              لطفا انتخاب کنید
            </option>
            {fetchedUnitAdmins
              ? fetchedUnitAdmins.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.displayName}
                  </option>
                ))
              : null}
          </select>
        </div>
        {/* <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">اضافه کردن مدیر واحد</div>
          <div className="SlidingMenuMoreDetail">برای اضافه کردن سریع مدیر واحد روی آیکون + کلیک کنید.</div>
        </div> */}
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>
        <div className="ButtonsContainer">
          {unitNumber && unitfloor && unitType && unitResidency && unitDateOfResidence && unitNumberOfMembers && unitSquare && unitAdmin ? (
            <button onClick={(e) => addUnitHandler(e)} type="sumbit" className="SaveButton">
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <div type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </div>
          )}
          <div onClick={props.onClickCloseHandler} className="CancelButton ">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import IconPicker from "../../../components/ui/iconpicker/IconPicker";
import { toast } from "react-toastify";

import { PutAProject } from "../../../Services/managementServices";

function ProjectAmenities(props) {
  const [projectName, setProjectName] = useState(props.ProjectName || ""); // Initialize with prop
  const [projectInformation, setProjectInformation] = useState(props.ProjectInformation || ""); // Initialize with prop
  const [projectLogoFileKey, setProjectLogoFileKey] = useState(props.ProjectLogo || null); // Initialize with prop

  useEffect(() => {
    setProjectName(props.ProjectName || "");
    setProjectInformation(props.ProjectInformation || "");
    setProjectLogoFileKey(props.ProjectLogo || "");
    Datafiller(props.ProjectAmenities);
  }, [props.ProjectName, props.ProjectInformation, props.ProjectLogo, props.ProjectAmenities]);

  const [projectServiceIcon, setProjectServiceIcon] = useState([]);

  const Datafiller = (AmenityIcons) => {
    let tempArray = [];

    if (AmenityIcons.length > 0) {
      AmenityIcons.forEach((element) => {
        tempArray.push(element);
      });
      setProjectServiceIcon(tempArray);
    }
  };

  const updateProjectAmenitiesHandler = async () => {
    let iconsTempArray = [];
    if (projectServiceIcon.length > 0) {
      projectServiceIcon.forEach((element) => {
        iconsTempArray.push(element);
      });
    } else {
      iconsTempArray = [];
    }

    const { status } = await PutAProject(props.ProjectId, projectName, projectInformation,null,null, iconsTempArray, null, projectLogoFileKey);

    if (status === 200) {
      toast.success("Updated Successfully");
      props.onClickRefresh();
    } else {
      toast.warning("Somthing has occured!");
    }
  };
  const addIconToState = (item) => {
    let tempProjectIcons = projectServiceIcon;
    let flag = 0;
    tempProjectIcons.forEach((icon) => {
      if (icon === item) {
        tempProjectIcons = tempProjectIcons.filter((icon) => icon !== item);
        return (flag = 1);
      }
    });

    if (flag === 0) {
      tempProjectIcons.push(item);
    }

    setProjectServiceIcon(tempProjectIcons);
  };

  return (
    <>
      <div className="formGrid">
        <div className="WidthHalf">
          <div className="EditMenuFullWidthContainer">
            <div className="SlidingMenuItemTitle">آیکون امکانات مجموعه</div>

            <IconPicker SelectedIcon={(e) => addIconToState(e)} CurrentIcon={projectServiceIcon} multiSelect={1} />
            <div className="SlidingMenuMoreDetail">این آیکون ها صرفا جهت زیبایی و نشان دادن امکانات مجموعه در غالب گرافیکی میباشد.</div>
          </div>
        </div>

        <div className="FullWidthContainer">
          <div className="ButtonsContainer">
            <button type="sumbit" className="SaveButton" id="SaveButton" onClick={(e) => updateProjectAmenitiesHandler(e)}>
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(ProjectAmenities);

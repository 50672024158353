import React, { useState, useEffect } from "react";
import { UnitResidency } from "./UnitResidency";
import config from "../../../Services/config.json";
import { PutAUnitById } from "../../../Services/managementServices";
import { toast } from "react-toastify";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";
import axios from "axios";

export default function UnitBusinessInfo(props) {
  const [unitTitle, setUnitTitle] = useState("");
  const [unitField, setUnitField] = useState("");
  const [unitTelephone, setUnitTelephone] = useState("");
  const [unitInfo, setUnitInfo] = useState("");
  const [unitLogoFileKey, setUnitLogoFileKey] = useState(props.UnitData.logoFileKey || "");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  useEffect(() => {
    console.log(props.UnitData);
    setUnitTitle(props.UnitData.title || "");
    setUnitField(props.UnitData.field || "");
    setUnitTelephone(props.UnitData.phone || "");
    setUnitInfo(props.UnitData.information || "");
    setUnitLogoFileKey(props.UnitData.logoFileKey || "");

    setUploadedImageUrl(config.ImageUrl + config.BucketName + "/" + (props.UnitData.logoFileKey || ""));
  }, [props.UnitData.title, props.UnitData.field, props.UnitData.phone, props.UnitData.information, props.UnitData.logoFileKey]);

  const updateProjectBussinessInfoHandler = async (e) => {
    e.preventDefault();

    try {
      const { status } = await PutAUnitById(
        props.UnitId,
        unitTitle,
        unitField,
        props.UnitData.floor,
        props.UnitData.number,
        props.UnitData.numberOfPlates,
        props.UnitData.numberOfActivePlates,
        unitInfo,
        unitTelephone,
        props.UnitData.type,
        props.UnitData.residency,
        props.UnitData.dateOfResidence,
        props.UnitData.numberOfMembers,
        props.UnitData.isEmpty,
        props.UnitData.square,
        unitLogoFileKey,
        props.UnitData.block
      );
      toast.success("تغییرات با موفقیت انجام شد");
      props.onClickRefresh();
    } catch (err) {
      toast.error(err.response.data.ErrorDescription);
    }
  };

  const [loadingHandler, setLoadingHandler] = useState(false);
  const [progressData, setProgressData] = useState(0);
  const fileValidTypes = ["image/png", "image/jpeg", "image/jpg"];

  const fileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("Format not supported");
      } else {
        setProgressData(0);
        setLoadingHandler(true);
        const response = await axios.post(`${config.PardikURL}/Images`, imageData, {
          headers: {
            accept: "text/plain",
            bucketName: config.BucketName,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            let progressValue = Math.round((100 * data.loaded) / data.total);
            setProgressData(progressValue);
          },
        });
        if (response.status === 200) {
          setUploadedImageUrl(response.data.fileUrl);
          setUnitLogoFileKey(response.data.fileKey);
          toast.success("عکس با موفقیت آپلود شد");
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoadingHandler(false);
    }
  };

  return (
    <>
      <div className="ContentBody">
        <form
          className="formGrid"
          onSubmit={(e) => {
            e.preventDefault();
            updateProjectBussinessInfoHandler(e);
          }}>
          <div className="WidthFull">
            <div className="FullWidthGrid4Columns">
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">نام واحد</div>
                <input className="SlidingMenuTextBox" value={unitTitle} onChange={(e) => setUnitTitle(e.target.value)} id="unitTitle" />
                <div className="SlidingMenuMoreDetail">شماره واحد/پلاک مشخص شده برای واحد</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">حوزه فعالیت</div>
                <input className="SlidingMenuTextBox" value={unitField} onChange={(e) => setUnitField(e.target.value)} id="unitField" />
                <div className="SlidingMenuMoreDetail">متراژ واحد تعریف شده در متر مربع</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تلفن ثابت واحد</div>
                <input className="SlidingMenuTextBox" value={unitTelephone} onChange={(e) => setUnitTelephone(e.target.value)} id="unitTelephone" />
                <div className="SlidingMenuMoreDetail">توجه داشته باشید که عدد موجود در این قسمت در محاسبه شارژ میتواند تاثیر بگذارد.</div>
              </div>
              <div className="FullWidthContainer TextAreaInGrid">
                <div className="SlidingMenuItemTitle">توضیحات</div>
                <textarea className="SlidingMenuTextBox TextAreaInGridLocked" value={unitInfo} onChange={(e) => setUnitInfo(e.target.value)} id="unitInfo" />
                <div className="SlidingMenuMoreDetail">توجه داشته باشید که عدد موجود در این قسمت در محاسبه شارژ میتواند تاثیر بگذارد.</div>
              </div>

              <div className="FullWidthContainer ">
                <div className="EditMenuUploadImageContainer">
                  {unitLogoFileKey ? <img className="UploadImage" src={uploadedImageUrl} alt=""></img> : <div className="UploadNoImageText"> {loadingHandler ? <LoadingUpload /> : "محل بارگذاری عکس"}</div>}
                </div>
                {loadingHandler ? <input type="file" id="file" className="uploadButton ButtonDisabled" disabled /> : <input type="file" id="file" className="uploadButton" onChange={fileSelectedHandler} />}
                {loadingHandler ? (
                  <label htmlFor="file" className="ButtonDisabled" disabled>
                    بارگذاری عکس
                  </label>
                ) : (
                  <label htmlFor="file">بارگذاری عکس</label>
                )}
              </div>
            </div>
          </div>
          <div className="FullWidthContainer">
            <div className="ButtonsContainer">
              {unitTitle ? (
                <button type="button" className="SaveButton" id="SaveButton" onClick={(e) => updateProjectBussinessInfoHandler(e)}>
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              ) : (
                <button type="button" className="SaveButton ButtonDisabled" id="SaveButton">
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import ProjectAddResidentsSlidingMenu from "./ProjectAddResidentsSlidingMenu";
import DeleteResidentModal from "../Residents/DeleteResidentModal";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { useDispatch } from "react-redux";
import { actionGetAllResidents } from "../../../actions";
import ProjectResidentsGridTable from "./ProjectResidentsGridTable";

export default function ProjectResidents(props) {
  useEffect(() => {
    getResidents();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentResidents, setCurrentResidents] = useState();

  const getResidents = async (myPageIndex) => {
    dispatch(actionGetAllResidents("", "", props.ProjectId, "", "", 1, pageSize));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentResidents(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <>
      {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
      {slideMenuIsOpen && <ProjectAddResidentsSlidingMenu ProjectId={props.ProjectId} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getResidents} />}
      {deleteModalOpan && <DeleteResidentModal CurrentResidents={currentResidents} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getResidents} />}
      <ProjectResidentsGridTable ProjectId={props.ProjectId} onClickRefresh={getResidents} PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
    </>
  );
}

import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";

export default function DevicesGridTable(props) {
  const myDevices = useSelector((state) => state.allDevices);
  console.log(myDevices);
  const [selectedCheckBox, setSelectedCheckBox] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox} IsSelected={isSelected} NavlinkAdress={"/EditDevice"} />
      {myDevices ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">مدل دستگاه</div>
                <div className="attribute">نوع دستگاه</div>
              </li>
              <form>
                {myDevices.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    {/* ----------------------Row Number---------------------- */}
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="deviceItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    {/* ----------------------Serial Number---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="productTypeId">
                        {item.deviceModel}
                      </div>
                    </div>
                    {/* ----------------------Is Active---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="isActive">
                        {item.deviceType ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myDevices.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myDevices.totalCount}
            TotalPages={myDevices.totalPages}
            HasPreviousPage={myDevices.hasPreviousPage}
            HasNextPage={myDevices.hasNextPage}
            actionType="Devices"
          />
        </div>
      ) : (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">Row</div>
                <div className="attribute">Serial Number</div>
                <div className="attribute">Active</div>
                <div className="attribute">Is Used</div>
                <div className="attribute">Product Type</div>
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAStaff } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddStaffSlidingMenu(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatStaffPassword, setRepeatStaffPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [userName, setUserName] = useState("");

  const reset = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setRepeatStaffPassword("");
    setMobile("");
    setUserName("");
  };

  const addNewStaffHandler = async (e) => {
    e.preventDefault();
    if (repeatStaffPassword === password) {
      try {
        const { data, status } = await PostAStaff(email, mobile, userName, password, firstName, lastName);
        if (status === 200) {
          reset();
          toast.success("کاربر با موفقیت ثبت شد");
          props.onClickCloseHandler();
          props.onClickRefresh();
          props.ResetPage();
        }
      } catch (ex) {
        toast.error(ex.response.errorDescription);
      }
    } else {
      toast.error("Passwords Does not match");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن کاربر جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نام کاربر جدید</div>
          <input className="SlidingMenuTextBox" id="StaffName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <div className="SlidingMenuMoreDetail">در این قسمت فقط نام کاربر نوشته شود.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نام خانوادگی کاربر جدید</div>
          <input className="SlidingMenuTextBox" id="StaffLastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <div className="SlidingMenuMoreDetail">در این قسمت فقط نام خانوادگی کاربر نوشته شود.</div>
        </div>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">نام کاربری/ شماره تلفن همراه</div>
          <input
            className="SlidingMenuTextBox"
            id="StaffUserName"
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value);
              setUserName(e.target.value);
            }}
          />
          <div className="SlidingMenuMoreDetail">در این قسمت نام کاربری که همان شماره همراه کاربر می باشد پر شود.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">ایمیل</div>
          <input className="SlidingMenuTextBox" id="StaffEmail" value={email} onChange={(e) => setEmail(e.target.value)} />
          <div className="SlidingMenuMoreDetail">در صورت تمایل و دارا بودن ایمیل کاربر در این قسمت پر شود.</div>
        </div>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">رمز عبور</div>
          <input className="SlidingMenuTextBox" type="password" id="StaffPassword" value={password} onChange={(e) => setPassword(e.target.value)} />
          <div className="SlidingMenuMoreDetail">در اینجا رمز ابتدایی ورود برای کاربر تعریف می شود.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">تکرار رمز عبور</div>
          <input className="SlidingMenuTextBox" type="password" id="StaffRepeatPassword" value={repeatStaffPassword} onChange={(e) => setRepeatStaffPassword(e.target.value)} />
          <div className="SlidingMenuMoreDetail">برای اطمینان حاصل کردن از رمز وارد شده دوباره تکرار کنید.</div>
        </div>
      </div>

      <div className="FullWidthContainer">
        <div className="SlidingMenuMoreDetail">پس از اضافه کردن کاربر جدید به صفحه ویرایش کاربر برای تخصیص دادن نقش منقل خواهید شد.</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="ButtonsContainer">
          <button type="sumbit" className="SaveButton" onClick={(e) => addNewStaffHandler(e)}>
            <span className="icon-SaveButton"></span>
            ذخیره
          </button>
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddStaffSlidingMenu);

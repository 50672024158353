export const UnitTypes=[
    {
      "english":"Residential",
      "farsi":"مسکونی"
    },
    {
      "english":"Official",
      "farsi":"اداری"
    }
   
  ]
export const billingManagementsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETBILLINGMANAGEMENT":
      return { ...action.payload };
    default:
      return state;
  }
};

export const selectedBillingManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDBILLINGMANAGEMENT":
      return { ...action.payload };
    default:
      return state;
  }
};
export const selectedTransactionByChargeId = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDTRANSACTIONBYCHARGEID":
      return { ...action.payload };
    default:
      return state;
  }
};
export const selectedTransactionByUnitId = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDUNITTRANSACTIONBYCHARGEID":
      return { ...action.payload };
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { PostAResident, getAllResidents } from "../../../Services/managementServices";

export default function AddBuildingResidentSlidingMenu(props) {
  useEffect(() => {
    getUnitAdmins();
  }, []);

  const getUnitAdmins = async () => {
    const { status, data } = await getAllResidents("", "", "", "", "", 1, 1000);
    if (status === 200) {
      setFetchedUnitAdmins(data.entities);
    }
  };
  const currentUserTypes = [
    { Type: "Resident", Text: "ساکن" },
    { Type: "UnitAdmin", Text: "مدیر واحد" },
  ];

  const [fetchedUnitAdmins, setFetchedUnitAdmins] = useState();

  const token = JSON.parse(localStorage.getItem("residentialManagement"));

  const [BuildingResidentUsername, setBuildingResidentUsername] = useState("");
  const [BuildingResidentPasswrod, setBuildingResidentPasswrod] = useState("");
  const [BuildingResidentFirstName, setBuildingResidentFirstName] = useState("");
  const [BuildingResidentLastName, setBuildingResidentLastName] = useState("");
  const [BuildingResidentMobile, setBuildingResidentMobile] = useState("");
  const [BuildingResidentGender, setBuildingResidentGender] = useState("");
  const [BuildingResidentUnitId, setBuildingResidentUnitId] = useState(props.UnitId === undefined ? null : [props.UnitId]);
  const [unitAdmin, setUnitAdmin] = useState("");
  const [userType, setUserType] = useState("");

  const [addNewUserTab, setAddNewUserTab] = useState(true);
  const [addExistingUserTab, setAddExistingUserTab] = useState(false);

  function ShowAddNew() {
    setAddNewUserTab(true);
    setAddExistingUserTab(false);
  }
  function ShowAddExisting() {
    setAddNewUserTab(false);
    setAddExistingUserTab(true);
  }

  const reset = () => {
    setBuildingResidentUsername("");
    setBuildingResidentPasswrod("");
    setBuildingResidentFirstName("");
    setBuildingResidentLastName("");
    setBuildingResidentMobile("");
    setBuildingResidentUnitId("");
    setUserType("");
    setUnitAdmin("");
  };
  console.log(props.UnitId);

  const addBuildingResidentHandler = async (e) => {
    e.preventDefault();
    const { status } = await PostAResident(BuildingResidentUsername, BuildingResidentPasswrod, BuildingResidentFirstName, BuildingResidentLastName, BuildingResidentMobile, BuildingResidentUnitId, BuildingResidentGender, null, userType);
    if (status === 200) {
      reset();
      toast.success("ساکن باموفقیت ثبت شد");
      props.onClickCloseHandler();
      props.onClickRefresh();
      props.ResetPage();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن ساکن جدید به واحد</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="SlidingMenuTabContainer">
        {addNewUserTab ? (
          <div className="SelectedtabInSlidingMenu">ساکن جدید</div>
        ) : (
          <div className="UnSelectedtabInSlidingMenu" onClick={() => ShowAddNew()}>
            ساکن جدید
          </div>
        )}
        {/* {addExistingUserTab ? (
          <div className="SelectedtabInSlidingMenu">ساکن موجود</div>
        ) : (
          <div className="UnSelectedtabInSlidingMenu" onClick={() => ShowAddExisting()}>
            ساکن موجود
          </div>
        )} */}
      </div>
      {addNewUserTab ? (
        <>
          <div className="FullWidthFlex">
            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">نام کاربری (شماره موبایل)</div>
              <input
                className="SlidingMenuTextBox"
                id="BuildingResidentUsername"
                value={BuildingResidentUsername}
                onChange={(e) => {
                  setBuildingResidentUsername(e.target.value);
                  setBuildingResidentMobile(e.target.value);
                }}
              />
              <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
            </div>

            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">رمز عبور</div>
              <input type="password" className="SlidingMenuTextBox" id="BuildingResidentPasswrod" value={BuildingResidentPasswrod} onChange={(e) => setBuildingResidentPasswrod(e.target.value)} />
              <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
            </div>
          </div>

          <div className="FullWidthFlex">
            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">نام</div>
              <input className="SlidingMenuTextBox" id="BuildingResidentFirstName" value={BuildingResidentFirstName} onChange={(e) => setBuildingResidentFirstName(e.target.value)} />
              <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
            </div>
            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">نام خانوادگی</div>
              <input className="SlidingMenuTextBox" id="BuildingResidentLastName" value={BuildingResidentLastName} onChange={(e) => setBuildingResidentLastName(e.target.value)} />
              <div className="SlidingMenuMoreDetail">نامگذاری سکنه برای ارائه به مخاطب و مدیریت آن سکنه از طریق پنل مدیریت.</div>
            </div>
          </div>
          <div className="FullWidthFlex">
            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">جنسیت</div>
              <select
                className="PlateCharacterHolder"
                name="Gender"
                id="Gender"
                defaultValue="-1"
                onChange={(e) => {
                  setBuildingResidentGender(e.target.value);
                }}>
                <option value="-1" disabled>
                  لطفا انتخاب کنید
                </option>
                <option value="male">مرد</option>
                <option value="female">زن</option>
              </select>
              <div className="SlidingMenuMoreDetail">جنسیت ساکن در حال تعریف</div>
            </div>

            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">سطح دسترسی</div>
              <select
                className="PlateCharacterHolder"
                name="UsertypeId"
                id="UsertypeId"
                defaultValue="-2"
                onChange={(e) => {
                  setUserType(e.target.value);
                }}>
                <option value="-2" disabled>
                  لطفا انتخاب کنید
                </option>

                {currentUserTypes
                  ? currentUserTypes.map((item, index) => (
                      <option key={index} value={item.Type}>
                        {item.Text}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
        </>
      ) : null}

      {addExistingUserTab ? (
        <>
          <div className="FullWidthFlex">
            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">مدیر واحد</div>
              <select
                className="PlateCharacterHolder"
                name="unitAdmin"
                id="unitAdmin"
                defaultValue="-1"
                onChange={(e) => {
                  setUnitAdmin(e.target.value);
                }}>
                <option value="-1" disabled>
                  لطفا انتخاب کنید
                </option>
                {fetchedUnitAdmins
                  ? fetchedUnitAdmins.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.displayName}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            {/* <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">اضافه کردن مدیر واحد</div>
          <div className="SlidingMenuMoreDetail">برای اضافه کردن سریع مدیر واحد روی آیکون + کلیک کنید.</div>
        </div> */}
          </div>
        </>
      ) : null}

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>
        {addNewUserTab ? (
          <div className="ButtonsContainer">
            {BuildingResidentUsername && BuildingResidentPasswrod && BuildingResidentFirstName && BuildingResidentMobile && BuildingResidentGender ? (
              <button onClick={(e) => addBuildingResidentHandler(e)} type="sumbit" className="SaveButton">
                <span className="icon-SaveButton"></span>
                ذخیره
              </button>
            ) : (
              <div type="sumbit" className="SaveButton ButtonDisabled">
                <span className="icon-SaveButton "></span>
                ذخیره
              </div>
            )}

            <div onClick={props.onClickCloseHandler} className="CancelButton ">
              <span className="icon-closeX"></span>
              لغو
            </div>
          </div>
        ) : null}
        {addExistingUserTab ? (
          <div className="ButtonsContainer">
            {unitAdmin ? (
              <button onClick={(e) => addBuildingResidentHandler(e)} type="sumbit" className="SaveButton">
                <span className="icon-SaveButton"></span>
                ذخیره
              </button>
            ) : (
              <div type="sumbit" className="SaveButton ButtonDisabled">
                <span className="icon-SaveButton "></span>
                ذخیره
              </div>
            )}

            <div onClick={props.onClickCloseHandler} className="CancelButton ">
              <span className="icon-closeX"></span>
              لغو
            </div>
          </div>
        ) : null}
      </div>
    </form>
  );
}

export const announcementsReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETANNOUNCEMENTS':
            return {...action.payload}
        default:
           return state;
    }
}
export const selectedAnnouncementReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETSELECTEDANNOUNCEMENTS':
            return {...action.payload}
        default:
           return state;
    }
}
export const deleteAnnouncementReducer=(state={},action)=>{
    switch (action.type) {
        case 'DELETESELECTEDANNOUNCEMENT':
            return {...action.payload}
        default:
           return state;
    }
}
export const deleteAnnouncementItemReducer=(state={},action)=>{
    switch (action.type) {
        case 'DELETESELECTEDANNOUNCEMENTITEM':
            return {...action.payload}
        default:
           return state;
    }
}

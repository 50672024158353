import React, { useState, useEffect } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector, useDispatch } from "react-redux";
import { actionGetAllServiceTypes } from "../../../actions";

export default function ServiceTypesGridTable(props) {
  const dispatch = useDispatch();

  const myServiceTypes = useSelector((state) => state.allServiceTypes);

  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [pageSize, setPageSize] = useState(8);

  const [filterRuleName, setFilterRuleName] = useState("");

  const filterData = (searchVal, field) => {
    let filterData = [];
    let filterName = field === "title" ? searchVal : filterRuleName;

    if (field === "title") {
      setFilterRuleName(searchVal);
    }

    if (filterName) filterData.push({ searchField: "title", searchValue: filterName, operatorType: "Contains" });

    dispatch(actionGetAllServiceTypes(1, pageSize, filterData));
  };

  return (
    <>
      <CrudOperators
        onClickOpenHandler={props.onClickOpenHandler}
        onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)}
        SelectedId={selectedCheckBox.id}
        selectedData={selectedCheckBox}
        IsSelected={isSelected}
        NavlinkAdress={"/EditServiceTypes"}
      />
      {myServiceTypes.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام سرویس</div>
                <div className="attribute">وضعیت</div>
              </li>
              <li className="item item-container">
                <div className="attribute Firstchild">جستجو</div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" name="ruleName" value={filterRuleName} onChange={(e) => filterData(e.target.value, "title")}></input>
                </div>
                <div className="GridItemSearchContainer"></div>
              </li>
              <form>
                {myServiceTypes.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="ServiceTypesItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="ServiceTypes Name">
                        {item.title}
                      </div>
                    </div>
                    <div className="attribute-container vendor-information">
                      <div className="attribute" data-name="Description">
                        {/* {item.isActive ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>} */}
                        <span className="icon-checked"></span>
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myServiceTypes.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myServiceTypes.totalCount}
            TotalPages={myServiceTypes.totalPages}
            HasPreviousPage={myServiceTypes.hasPreviousPage}
            HasNextPage={myServiceTypes.hasNextPage}
            actionType="ServiceTypes"
          />
        </div>
      ) : (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام سرویس</div>
                <div className="attribute">وضعیت</div>
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}

import http from "./httpServices";
import config from "./config.json";

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Announcements     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const loginUser = (username, password) => {
  return http.post(`${config.PardikURL}/Authentication/authenticateresident`, { username, password });
};
export const loginAdmin = (username, password) => {
  return http.post(`${config.PardikURL}/Authentication`, { username, password });
};

import React, { useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { actionGetAllLicensePlates } from "../../../actions";
import { PlateCharacters } from "../licenseplate/PlatesCharacters";
import { Syncplate } from "../../../Services/managementServices";
import { toast } from "react-toastify";

export default function UnitLicensePlatesGridTable(props) {
  const dispatch = useDispatch();

  const [licensePlateFirstNumber, setLicensePlateFirstNumber] = useState("");
  const [licensePlateSecondNumber, setlicensePlateSecondNumber] = useState("");
  const [licensePlateCharacter, setlicensePlateCharacter] = useState("");
  const [licensePlateCityCode, setlicensePlateCityCode] = useState("");

  const myLicensePlates = useSelector((state) => state.allLicensePlates);
  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [filterFullPlate, setFilterFullPlate] = useState("");
  const [pageSize, setPageSize] = useState(8);

  const syncronization = async () => {
    const { status } = await Syncplate();
    if (status === 200) {
      toast.success("همگام سازی با موفقیت انجام شد");
    }
  };

  const ResetSearch = () => {
    setLicensePlateFirstNumber("");
    setlicensePlateSecondNumber("");
    setlicensePlateCharacter("");
    setlicensePlateCityCode("");
    dispatch(actionGetAllLicensePlates(1, pageSize, "", props.UnitId));
  };

  const filterData = (firstNumber, plateCharacter, secondNumber, cityCode) => {
    if (firstNumber !== null) setLicensePlateFirstNumber(firstNumber);
    if (plateCharacter !== null) setlicensePlateCharacter(plateCharacter);
    if (secondNumber !== null) setlicensePlateSecondNumber(secondNumber);
    if (cityCode !== null) setlicensePlateCityCode(cityCode);

    var searchFirstNumberVal = firstNumber !== null ? firstNumber : licensePlateFirstNumber;
    var searchCharacterVal = plateCharacter !== null ? plateCharacter : licensePlateCharacter;
    var searchSecondNumberVal = secondNumber !== null ? secondNumber : licensePlateSecondNumber;
    var searchCityCodeVal = cityCode !== null ? cityCode : licensePlateCityCode;

    var searchFullText = searchFirstNumberVal + ":" + searchCharacterVal + ":" + searchSecondNumberVal + ":" + searchCityCodeVal;
    dispatch(actionGetAllLicensePlates(1, pageSize, searchFullText));
  };

  return (
    <>
      <div className="CrudContainer">
        <div className="ButtonsContainer">
          <div onClick={props.onClickOpenHandler} className="BackgorundPrimaryColor button-left">
            <span className="icon-add"></span>
            اضافه
          </div>
          {/* {props.IsSelected === true ? (
          <>
            <Link
              to={{
                pathname: props.NavlinkAdress,
                state: { id: props.SelectedId, selectedData: props.selectedData ? props.selectedData : null },
              }}
              className="BackgorundPrimaryColor button-left">
              <span className="icon-pencil"></span>
              ویرایش
            </Link>
            <div onClick={props.onClickDeleteOpenHandler} className="BackgorundSecondaryColor button-left">
              <span className="icon-trash"></span>
              حذف
            </div>
          </>
        ) : (
          <>
            <div className="BackgorundPrimaryColor button-left ButtonDisabled">
              <span className="icon-pencil"></span>ویرایش
            </div>
            <div className="BackgorundSecondaryColor button-left ButtonDisabled">
              <span className="icon-trash"></span>حذف
            </div>
          </>
        )} */}
        </div>
      </div>

      {myLicensePlates.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container DataGridHeader">
                <div className="attribute Firstchild">شماره ردیف</div>

                <div className="attribute">شماره اول</div>
                <div className="attribute">شماره دوم</div>
                <div className="attribute">حرف</div>
                <div className="attribute">کد شهر</div>
                <div className="attribute">پلاک کامل</div>
                <div className="attribute">پیام ورود</div>
              </li>
              <li className="item item-container">
                <div className="attribute Firstchild">جستجو</div>
                <div className="Platesearchcontainer">
                  <div className="GridItemSearchContainer">
                    <div className="GridLicensePlate">
                      <div className="PlateMainContainerForGrid">
                        <span className="PlateCharactertemplate" />
                        <input
                          className="PlateCharacterHolder"
                          maxLength="2"
                          onChange={(e) => {
                            filterData(null, null, null, e.target.value);
                          }}
                          type="tel"
                          placeholder="⚊ ⚊"
                          id="carPlateSection1"
                          value={licensePlateCityCode}
                        />

                        <select
                          className="PlateCharacterHolder"
                          name="UsertypeId"
                          id="UsertypeId"
                          defaultValue="Choose a Device Type"
                          value={licensePlateCharacter}
                          onChange={(e) => {
                            filterData(null, e.target.value, null, null);
                          }}>
                          <option value="Choose a Device Type" disabled>
                            حرف
                          </option>

                          {PlateCharacters
                            ? PlateCharacters.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.farsi}
                                </option>
                              ))
                            : null}
                        </select>
                        <input
                          className="PlateCharacterHolder PlateDivider"
                          maxLength="3"
                          onChange={(e) => {
                            filterData(null, null, e.target.value, null);
                          }}
                          type="tel"
                          placeholder="⚊ ⚊ ⚊"
                          id="carPlateSection1"
                          value={licensePlateSecondNumber}
                        />
                        <input
                          className="PlateCharacterHolder"
                          maxLength="2"
                          onChange={(e) => {
                            filterData(e.target.value, null, null, null);
                          }}
                          type="tel"
                          placeholder="⚊ ⚊"
                          id="carPlateSection1"
                          value={licensePlateFirstNumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div onClick={(e) => ResetSearch()} className="ClearButton">
                    <span className="icon-trash"></span>
                    پاک کردن
                  </div>
                </div>

                <div className="GridItemSearchContainer">
                  <div onClick={(e) => syncronization()} className="ClearButton">
                    <span className="icon-checked"></span>
                    هنگام سازی
                  </div>
                </div>
              </li>
              <form>
                {myLicensePlates.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="ResidentsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate firstNumber">
                        {item.firstNumber}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate secondNumber">
                        {item.secondNumber}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate character">
                        {PlateCharacters.find((x) => x.english === item.character).farsi}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate cityCode">
                        {item.cityCode}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate fullText">
                        <div className="GridLicensePlate">
                          <div className="PlateMainContainerForGrid">
                            <span className="PlateCharactertemplate" />
                            <div className="PlateCharacterHolder">{item.firstNumber}</div>
                            <div className="PlateCharacterHolder">{PlateCharacters.find((x) => x.english === item.character).farsi}</div>
                            <div className="PlateCharacterHolder PlateDivider"> {item.secondNumber}</div>
                            <div className="PlateCharacterHolder">{item.cityCode}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate cityCode">
                        {item.plateNote}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myLicensePlates.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myLicensePlates.totalCount}
            TotalPages={myLicensePlates.totalPages}
            HasPreviousPage={myLicensePlates.hasPreviousPage}
            HasNextPage={myLicensePlates.hasNextPage}
            actionType="LicensePlates"
          />
        </div>
      ) : null}
    </>
  );
}

import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PutAMirrorComplimentItem } from "../../../Services/mirrorServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function EditMirrorComplimentsItemSlidingMenu(props) {
  const [MirrorComplimentTitle, setMirrorComplimentTitle] = useState(props.SelectedItem.text);

  const reset = () => {
    setMirrorComplimentTitle("");
  };

  const addMirrorComplimentItemHandler = async (e, Id, ItemId, title) => {
    e.preventDefault();
    const { status } = await PutAMirrorComplimentItem(Id, ItemId, title);
    if (status === 200) {
      reset();
      toast.success("Compliment Edited succesfully");
      props.onClickCloseHandler();
      props.ResetPage();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">Edit {props.SelectedItem.text}</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="TitleAndSliderCheck">
        <div className="HalfWidthInsideFlex">
          <div className="FullWidthContainer">
            <div className="SlidingMenuItemTitle">Language</div>
            <div className="SlidingMenuTextBox DropDownContainer">
              <img className="DropDownMenuImage" src={"/images/countries/" + props.SelectedItem.languageCode + ".png"} alt="" />

              <select disabled className="DropDownSelect" name="LangId" id="LangId" defaultValue="SelectLanguage">
                <option value="SelectLanguage">{props.SelectedItem.languageTitle}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">Announcement Title Indicator</div>
        <input className="SlidingMenuTextBox" id="Compliment Title" value={MirrorComplimentTitle} onChange={(e) => setMirrorComplimentTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">This title would be used as both the indicator of this announcement in the announcements menu and the title of this announcement with the selected language</div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            Cancel
          </div>

          {MirrorComplimentTitle ? (
            <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addMirrorComplimentItemHandler(e, props.MirrorComplimenttId, props.SelectedItem.languageId, MirrorComplimentTitle)}>
              Save
            </button>
          ) : (
            <button disabled className="SlidingMenuSavebutton ButtonDisabled">
              Save
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default withRouter(EditMirrorComplimentsItemSlidingMenu);

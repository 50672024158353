export const foodCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETFOODCATEGORIES":
      return {...action.payload};
    default:
      return state;
  }
};

export const selectedFoodCategoryReducer=(state={},action)=>{
  switch (action.type) {
      case 'GETSELECTEDFOODCATEGORIES':
          return {...action.payload}
      default:
         return state;
  }
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { actionGetSelectedUnit } from "../../../actions";
import "../../../components/slidingmenu/slidingmenu.css";
import Loading from "../../../components/ui/loading/Loading";

import { getUnitById } from "../../../Services/managementServices";
import UnitInfo from "./UnitInfo";
import UnitTransactions from "./UnitTransactions";
import UnitBusinessInfo from "./UnitBusinessInfo";
import UnitLicensePlate from "./UnitLicensePlate";
import UnitResidents from "./residents/UnitResidents";

function EditUnit({ history }) {
  const mySelectedUnit = useSelector((state) => state.selectedUnit);
  const selectedCheckBox = history.location.state.id;

  const [unitId, setUnitId] = useState("");
  const [unitData, setUnitData] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    fillStates();
    dispatch(actionGetSelectedUnit(selectedCheckBox));
  }, []);

  const [isUnitInfoTrue, setIsUnitInfoTrue] = useState(true);
  const [isUnitTransactionsTrue, setIsUnitTransactionsTrue] = useState(false);
  const [isUnitOfficeTrue, setIsUnitOfficeTrue] = useState(false);
  const [isUnitPlatesTrue, setIsUnitPlatesTrue] = useState(false);
  const [isUnitResidentsTrue, setIsUnitResidentsTrue] = useState(false);

  function ShowUnitInfo() {
    setIsUnitInfoTrue(true);
    setIsUnitOfficeTrue(false);
    setIsUnitTransactionsTrue(false);
    setIsUnitPlatesTrue(false);
    setIsUnitResidentsTrue(false);
  }
  function ShowUnitImages() {
    setIsUnitInfoTrue(false);
    setIsUnitOfficeTrue(true);
    setIsUnitTransactionsTrue(false);
    setIsUnitPlatesTrue(false);
    setIsUnitResidentsTrue(false);
  }
  function ShowUnitTransactions() {
    setIsUnitInfoTrue(false);
    setIsUnitOfficeTrue(false);
    setIsUnitTransactionsTrue(true);
    setIsUnitPlatesTrue(false);
    setIsUnitResidentsTrue(false);
  }
  function ShowUnitPlates() {
    setIsUnitInfoTrue(false);
    setIsUnitOfficeTrue(false);
    setIsUnitTransactionsTrue(false);
    setIsUnitPlatesTrue(true);
    setIsUnitResidentsTrue(false);
  }
  function ShowUnitResidents() {
    setIsUnitInfoTrue(false);
    setIsUnitOfficeTrue(false);
    setIsUnitTransactionsTrue(false);
    setIsUnitPlatesTrue(false);
    setIsUnitResidentsTrue(true);
  }

  const fillStates = async () => {
    const { data, status } = await getUnitById(selectedCheckBox);

    if (status === 200) {
      setUnitId(data.id);
      setUnitData(data);
    }
  };

  return (
    <>
      <div className="ContentBody">
        <div className="BodyPadding">
          <div className="ContentBody">
            <div className="ServiceSettingtypes">
              {isUnitInfoTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  اطلاعات واحد
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowUnitInfo()}>
                  اطلاعات واحد
                </div>
              )}

              {isUnitOfficeTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  تراکنش ها
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowUnitImages()}>
                  تراکنش ها
                </div>
              )}

              {unitData.type === "Official" ? (
                isUnitTransactionsTrue ? (
                  <div type="sumbit" className="ServiceSettingSelected">
                    اطلاعات اداری
                  </div>
                ) : (
                  <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowUnitTransactions()}>
                    اطلاعات اداری
                  </div>
                )
              ) : null}
              {isUnitPlatesTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  پلاک های واحد
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowUnitPlates()}>
                  پلاک های واحد
                </div>
              )}
              {isUnitResidentsTrue ? (
                <div type="sumbit" className="ServiceSettingSelected">
                  ساکنین واحد
                </div>
              ) : (
                <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowUnitResidents()}>
                  ساکنین واحد
                </div>
              )}
            </div>
            <div className="BodyPadding">
              <div className="EditPageTwoColumnNoBG">
                {isUnitInfoTrue ? (
                  <div className="EditPageTwoColumnItemWithBG">
                    <UnitInfo UnitData={unitData} UnitId={unitId} onClickRefresh={fillStates} />
                  </div>
                ) : null}
                {isUnitOfficeTrue ? (
                  <div className="GridViewAfterSelectedTab">
                    <UnitTransactions UnitId={unitId} onClickRefresh={fillStates} />
                  </div>
                ) : null}
                {isUnitTransactionsTrue ? (
                  <div className="EditPageTwoColumnItemWithBG">
                    <UnitBusinessInfo UnitData={unitData} UnitId={unitId} onClickRefresh={fillStates} />
                  </div>
                ) : null}
                {isUnitPlatesTrue ? (
                  <div className="GridViewAfterSelectedTab">
                    <UnitLicensePlate UnitId={unitId} onClickRefresh={fillStates} />
                  </div>
                ) : null}
                {isUnitResidentsTrue ? (
                  <div className="GridViewAfterSelectedTab">
                    <UnitResidents UnitId={unitId} onClickRefresh={fillStates} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(EditUnit);

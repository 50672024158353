import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function BillingManagementsGridTable(props) {
  const myBillingManagements = useSelector((state) => state.allBillingManagements);
  const [selectedCheckBox, setSelectedCheckBox] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      {/* <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox} IsSelected={isSelected} NavlinkAdress={"/EditBillingManagement"} /> */}
      <div className="CrudContainer">
        <div className="ButtonsContainer">
          <div onClick={props.onClickOpenHandler} className="BackgorundPrimaryColor button-left">
            <span className="icon-add"></span>
            اضافه
          </div>

          {isSelected === true ? (
            <>
              <Link
                to={{
                  pathname: "Transactions",
                  state: { ChargeId: selectedCheckBox.id },
                }}
                className="BackgorundPrimaryColor button-left">
                <span className="icon-pencil"></span>
                جزییات
              </Link>
              {/* <div onClick={props.onClickDeleteOpenHandler} className="BackgorundSecondaryColor button-left">
            <span className="icon-trash"></span>
            حذف
          </div> */}
            </>
          ) : (
            <>
              <div className="BackgorundPrimaryColor button-left ButtonDisabled">
                <span className="icon-pencil"></span>جزییات
              </div>
              {/* <div className="BackgorundSecondaryColor button-left ButtonDisabled">
            <span className="icon-trash"></span>حذف
          </div> */}
            </>
          )}
        </div>
      </div>
      {myBillingManagements.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">ردیف</div>
                <div className="attribute">عنوان شارژ</div>
                <div className="attribute">مبلغ</div>
              </li>
              <form>
                {myBillingManagements.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    {/* ----------------------Row Number---------------------- */}
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="AnnouncementsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    {/* ----------------------Title---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Title">
                        {item.title}
                      </div>
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Title">
                        {item.totalTransactionCosts}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myBillingManagements.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myBillingManagements.totalCount}
            TotalPages={myBillingManagements.totalPages}
            HasPreviousPage={myBillingManagements.hasPreviousPage}
            HasNextPage={myBillingManagements.hasNextPage}
            actionType="BillingManagements"
          />
        </div>
      ) : (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">خوشامدگویی</div>
                <div className="attribute">وضعیت</div>
                <div className="attribute">تاریخ انقضا</div>
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actionGetSelectedServiceTypes, actionGetAllServiceRules, actionGetServicetypeDevicesById } from "../../../actions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { getServiceMapDetails, getServiceRuleById, getServiceTypeById, PutAServiceTariff, PutAServiceType, PutServiceTypeMapInformation } from "./../../../Services/managementServices";
import config from "../../../Services/config.json";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import DeleteScheduleModal from "./DeleteSchedule";
import DeleteServiceTypeDevice from "./DeleteServiceTypeDevice";
import AddServiceTypeDevice from "./AddServiceTypeDevice";
import AddServiceRulesSlidingMenu from "../servicerules/AddServiceRulesSlidingMenu";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import EditServiceTypeDevice from "./EditServiceTypeDevice";
import ReservationIconPicker from "../../../components/ui/iconpicker/ReservationIconPicker";

function EditServiceTypes({ history }) {
  const dispatch = useDispatch();

  const myServiceRules = useSelector((state) => state.allServiceRules);
  const ServiceTypeDevices = useSelector((state) => state.allServicetypeDevicesById);

  const selectedCheckBox = history.location.state.id;

  useEffect(() => {
    fillStates();
    fetchAllServiceRyles();
    getMapDetails();
  }, []);

  const fetchAllServiceRyles = () => {
    dispatch(actionGetAllServiceRules(1, 1000));
    dispatch(actionGetServicetypeDevicesById(selectedCheckBox));
  };

  const [ServiceTypesTitle, setServiceTypesTitle] = useState("");
  const [ServiceTypesDescription, setServiceTypesDescription] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [fetchedServiceTypes, setFetchedServiceTypes] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isMapIncluded, setIsMapIncluded] = useState(false);

  const [isSchedulingTrue, setIsSchedulingTrue] = useState(true);
  const [isSelectableTrue, setIsSelectableTrue] = useState(false);
  const [isDeviceSettingTrue, setIsDeviceSettingTrue] = useState(false);

  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [selectedRuleId, setSelectedRuleId] = useState("");
  const [currentServiceTypesTarriffs, setCurrentServiceTypesTarriffs] = useState(null);
  const [currentRule, setCurrentRule] = useState("");
  const [colorizeValues, setColorizeValues] = useState(null);

  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [uploadedImagefileKey, setUploadedImagefileKey] = useState("");

  const [loadingHandler, setLoadingHandler] = useState(false);
  const [progressData, setProgressData] = useState(0);

  const fileValidTypes = ["image/png", "image/jpeg", "image/jpg"];

  const [selectedDeviceCheckBox, setSelectedDeviceCheckBox] = useState({});
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);

  const [devicesSlideMenuIsOpen, setDevicesSlideMenuIsOpen] = useState(false);
  const [devicesEditSlideMenuIsOpen, setDevicesEditSlideMenuIsOpen] = useState(false);
  const [devicesDeleteSlideMenuIsOpen, setDevicesDeleteSlideMenuIsOpen] = useState(false);
  const [devicesDeleteModalOpan, setDeviceDeleteModalOpan] = useState(false);

  const [mapDetails, setMapDetails] = useState();
  const [mapUploadedImageUrl, setMapUploadedImageUrl] = useState("");
  const [mapUploadedImagefileKey, setMapUploadedImagefileKey] = useState("");
  const [fetchedmapDetailsToString, setFetchedmapDetailsToString] = useState("");
  const [serviceIcon, setServiceIcon] = useState("");

  function slideMenuAddDeviceOpenerHandler() {
    setDevicesSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuEditDeviceOpenerHandler() {
    setDevicesEditSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuDeleteDeviceOpenerHandler() {
    setDevicesDeleteSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }

  function slideMenuDeviceCloserHandler() {
    setDevicesSlideMenuIsOpen(false);
    setDeviceDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalDeviceOpenerHandler(item) {
    setDeviceDeleteModalOpan(true);
    setGrayoutHandler(true);
  }

  const fillStates = async () => {
    const { data, status } = await getServiceTypeById(selectedCheckBox);

    if (status === 200) {
      setFetchedServiceTypes(data);
      setIsActive(data.isActive);
      setIsMapIncluded(data.isMapIncluded);
      setServiceTypesTitle(data.title);
      setServiceTypesDescription(data.description);
      setUploadedImageUrl(data.imageUrl);
      setUploadedImagefileKey(data.fileKey);
      if (selectedDay) {
        let newServiceTypesTariff = data.schedules.filter((x) => x.day.toLowerCase() === selectedDay);
        setCurrentServiceTypesTarriffs(newServiceTypesTariff.length > 0 ? newServiceTypesTariff[0].rules : null);
        coloringTable(newServiceTypesTariff[0]);
      }
    }
  };

  const getMapDetails = async () => {
    const { data, status } = await getServiceMapDetails(selectedCheckBox);
    let mapString = "";
    if (status === 200) {
      setMapDetails(data);
      mapString = data.mapInformation.join(",");
      setFetchedmapDetailsToString(mapString);
      setMapUploadedImagefileKey(data.imageFileKey);
      setMapUploadedImageUrl(data.imageUrl);
      setServiceIcon(data.icon);
    }
  };

  const fileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("Format not supported");
      } else {
        //setUploadedHotelImage("");
        setProgressData(0);
        setLoadingHandler(true);
        const response = await axios.post(`${config.PardikURL}/Images`, imageData, {
          headers: {
            accept: "text/plain",
            bucketName: "Buildings",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            let progressValue = Math.round((100 * data.loaded) / data.total);
            setProgressData(progressValue);
          },
        });
        if (response.status === 200) {
          setUploadedImageUrl(response.data.fileUrl);
          setUploadedImagefileKey(response.data.fileKey);

          toast.success("Image uploaded Successfully");
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoadingHandler(false);
    }
  };
  const mapFileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("Format not supported");
      } else {
        //setUploadedHotelImage("");
        setProgressData(0);
        setLoadingHandler(true);
        const response = await axios.post(`${config.PardikURL}/Images`, imageData, {
          headers: {
            accept: "text/plain",
            bucketName: "Buildings",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            let progressValue = Math.round((100 * data.loaded) / data.total);
            setProgressData(progressValue);
          },
        });
        if (response.status === 200) {
          setMapUploadedImageUrl(response.data.fileUrl);
          setMapUploadedImagefileKey(response.data.fileKey);
          toast.success("Map Image uploaded Successfully");
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoadingHandler(false);
    }
  };

  const handleSelectedDay = async (e) => {
    setColorizeValues(null);
    const myDay = (await e) ? e.target.value : selectedDay;
    setSelectedDay(myDay);
    const allServiceTypes = fetchedServiceTypes;
    let newServiceTypesTariff = allServiceTypes.schedules.filter((x) => x.day.toLowerCase() === myDay);

    setCurrentServiceTypesTarriffs(newServiceTypesTariff.length > 0 ? newServiceTypesTariff[0].rules : null);

    coloringTable(newServiceTypesTariff[0]);
  };

  const coloringTable = (rows) => {
    const colorizedIndexes = [];
    if (rows) {
      rows.rules.forEach((element) => {
        let sTime = Number(element.startTime.substring(0, 2));
        let eTime = Number(element.endTime.substring(0, 2));
        for (let index = sTime; index < eTime; index++) {
          colorizedIndexes.push({
            index,
            title: element.title,
            price: element.price,
          });
        }
      });
    }
    setColorizeValues(colorizedIndexes);
  };

  const handleColorizeRows = () => {
    let returnTags = [];
    if (colorizeValues) {
      for (let i = 0; i < 24; i++) {
        let arrayIndex = colorizeValues.find((item) => item.index === i);
        let myIndex = arrayIndex ? arrayIndex.index : null;
        let myTitle = arrayIndex ? arrayIndex.title : "تعریف نشده";
        let myPrice = arrayIndex ? arrayIndex.price : null;
        returnTags.push(
          <div className="SchedulerItem">
            <div className={myIndex !== null ? "SchedulerInnerItem SchedulerInnerItemSelected" : "SchedulerInnerItem"} id={i}>
              {myTitle} : {myPrice} تومان
            </div>
          </div>
        );
      }
    }
    return returnTags;
  };

  const handleSelectedRule = async (e) => {
    const { data, status } = await getServiceRuleById(e.target.value);

    if (status === 200) {
      setSelectedRuleId(data.id);
      setSelectedStartTime(data.startTime);
      setSelectedEndTime(data.endTime);
    }
  };
  const handleConfirmRule = async () => {
    try {
      const { data, status } = await PutAServiceTariff(selectedCheckBox, selectedDay, selectedRuleId);
      if (status === 200) {
        toast.success("Service Type Tariff Saved Successfully");
        document.getElementById("addSelectTag").options[0].selected = true;
        setSelectedStartTime("");
        setSelectedEndTime("");
        fillStates();
      }
    } catch (err) {
      toast.error(err.response.data.ErrorDescription);
    }
  };

  const editServiceTypeHandler = async (e) => {
    e.preventDefault();

    try {
      const { data, status } = await PutAServiceType(selectedCheckBox, ServiceTypesTitle, uploadedImagefileKey, ServiceTypesDescription, isActive, isSchedulingTrue, isMapIncluded);
      if (status === 200) {
        toast.success("Service Types Updated Successfully");
        dispatch(actionGetSelectedServiceTypes(selectedCheckBox));
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.errors.Description[0] : "Something went wrong!");
    }
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDevicesEditSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
    setDevicesDeleteSlideMenuIsOpen(false);
    setDevicesSlideMenuIsOpen(false);
    setDeviceDeleteModalOpan(false);
    setDevicesSlideMenuIsOpen(false);
    setDeviceDeleteModalOpan(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentRule(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
    fillStates();
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  function ShowScheduling() {
    setIsSchedulingTrue(true);
    setIsDeviceSettingTrue(false);
    setIsSelectableTrue(false);
  }
  function ShowDeviceSetting() {
    setIsSchedulingTrue(false);
    setIsDeviceSettingTrue(true);
    setIsSelectableTrue(false);
  }
  function ShowSelectable() {
    setIsSchedulingTrue(false);
    setIsDeviceSettingTrue(false);
    setIsSelectableTrue(true);
  }

  const handleTranslateDayToPersian = () => {
    let persianName = "";
    switch (selectedDay) {
      case "saturday":
        persianName = "شنبه";
        break;
      case "sunday":
        persianName = "یکشنبه";
        break;
      case "monday":
        persianName = "دوشنبه";
        break;
      case "tuesday":
        persianName = "سه شنبه";
        break;
      case "wednesday":
        persianName = "چهارشنبه";
        break;
      case "thursday":
        persianName = "پنجشنبه";
        break;
      case "friday":
        persianName = "جمعه";
        break;
      default:
        break;
    }
    return persianName;
  };

  const handleAddSeatsString = async () => {
    try {
      let seatsArray = [];
      seatsArray = fetchedmapDetailsToString.split(",");

      const { status, data } = await PutServiceTypeMapInformation(selectedCheckBox, seatsArray, mapUploadedImagefileKey, serviceIcon);
      if (status === 200) {
        toast.success("Saved Successfully");
        getMapDetails();
      }
    } catch (err) {
      toast.error(err.response.data.ErrorDescription);
    }
  };

  return (
    <>
      <div className="ContentBody">
        {fetchedServiceTypes ? (
          <div className="BodyPadding">
            <div className="EditPageTwoColumnNoBG">
              <div className="EditPageTwoColumnItemWithBGWithScroll" id="style-3">
                <div className="EditContainerWithScroll">
                  <div className="FullWidthContainer">
                    <div className="SlidingMenuItemTitle">نام سرویس</div>
                    <input className="SlidingMenuTextBox" id="ServiceTypesTitle" value={ServiceTypesTitle || ""} onChange={(e) => setServiceTypesTitle(e.target.value)} />
                    <div className="SlidingMenuMoreDetail">نامگذاری سرویس برای ارائه به مخاطب و مدیریت آن سرویس از طریق پنل مدیریت.</div>
                  </div>
                  <div className="EditMenuFullWidthContainer">
                    <div className="TitleAndSliderCheck EditMenuFullWidthPadding">
                      <div className="EditItemTitle">وضعیت فعال بودن</div>
                      <label className="switch">
                        <input type="checkbox" checked={isActive ? true : false} onChange={() => setIsActive(!isActive)} />
                        <span className="switchslider round"></span>
                      </label>
                    </div>
                    <div className="SlidingMenuMoreDetail EditMenuFullWidthPadding">در صورت غیر فعال کردن این سرویس، دیگر تا فعال کردن دوباره آن برای مخاطبین به نمایش گذاشته نمیشود.</div>
                  </div>

                  <div className="EditMenuFullWidthContainer">
                    <div className="TitleAndSliderCheck EditMenuFullWidthPadding">
                      <div className="EditItemTitle">رزرو محل</div>
                      <label className="switch">
                        <input type="checkbox" checked={isMapIncluded ? true : false} onChange={() => setIsMapIncluded(!isMapIncluded)} />
                        <span className="switchslider round"></span>
                      </label>
                    </div>
                    <div className="SlidingMenuMoreDetail EditMenuFullWidthPadding">با فعال کردن این گزینه قابلیت تعریف محل رزرو به این سرویس اضافه خواهد شد.</div>
                  </div>
                  <div className="EditMenuFullWidthContainer">
                    <div className="EditMenuUploadImageContainer">
                      {uploadedImageUrl ? <img className="UploadImage" src={uploadedImageUrl} alt=""></img> : <div className="UploadNoImageText"> {loadingHandler ? <LoadingUpload /> : "محل بارگذاری عکس"}</div>}
                    </div>
                    {loadingHandler ? <input type="file" id="file" className="uploadButton ButtonDisabled" disabled /> : <input type="file" id="file" className="uploadButton" onChange={fileSelectedHandler} />}
                    {loadingHandler ? (
                      <label htmlFor="file" className="ButtonDisabled" disabled>
                        بارگذاری عکس
                      </label>
                    ) : (
                      <label htmlFor="file">بارگذاری عکس</label>
                    )}
                  </div>
                  <div className="FullWidthContainer">
                    <div className="SlidingMenuItemTitle">توضیح سرویس</div>
                    <textarea className="SlidingMenuTextArea" id="ServiceTypesDescription" value={ServiceTypesDescription} onChange={(e) => setServiceTypesDescription(e.target.value)} />
                    <div className="SlidingMenuMoreDetail">توضیحی کوتاهی از سرویس ارائه شده برای مخاطبان.</div>
                  </div>
                  <div className=" FullWidthContainer">
                    <hr></hr>

                    <div className="ButtonsContainer">
                      {ServiceTypesDescription && uploadedImageUrl && ServiceTypesTitle !== null ? (
                        <button type="sumbit" className="SaveButton" onClick={(e) => editServiceTypeHandler(e)}>
                          <span className="icon-SaveButton"></span>
                          ذخیره
                        </button>
                      ) : (
                        <button type="sumbit" className="SaveButton ButtonDisabled" id="SaveButton">
                          <span className="icon-SaveButton"></span>
                          ذخیره
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="EditPageTwoColumnItemWithBG">
                <div className="ServiceSettingtypes">
                  {isSchedulingTrue ? (
                    <div type="sumbit" className="ServiceSettingSelected">
                      تنظیمات برنامه ریزی هفته
                    </div>
                  ) : (
                    <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowScheduling()}>
                      تنظیمات برنامه ریزی هفته
                    </div>
                  )}
                  {isDeviceSettingTrue ? (
                    <div type="sumbit" className="ServiceSettingSelected">
                      تنظیمات دستگاه
                    </div>
                  ) : (
                    <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowDeviceSetting()}>
                      تنظیمات دستگاه
                    </div>
                  )}
                  {isMapIncluded ? (
                    isSelectableTrue ? (
                      <div type="sumbit" className="ServiceSettingSelected">
                        تنظیمات مکان رزرو
                      </div>
                    ) : (
                      <div type="sumbit" className="ServiceUnSettingSelected" onClick={() => ShowSelectable()}>
                        تنظیمات مکان رزرو
                      </div>
                    )
                  ) : null}
                </div>
                {isSchedulingTrue ? (
                  <div>
                    <div className="DisplayFlex">
                      <div className="FullWidthContainer">
                        <div className="SlidingMenuItemTitle"> لطفا روز مورد نظر را انتخاب نمایید.</div>
                        <div className="SlidingMenuTextBox DropDownContainer">
                          <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId" onChange={(e) => handleSelectedDay(e)}>
                            <option value="Choose a Device Type" disabled selected>
                              لطفا روز مورد نظر را انتخاب نمایید.
                            </option>
                            <option value="saturday">شنبه</option>
                            <option value="sunday">یکشنبه</option>
                            <option value="monday">دوشنبه</option>
                            <option value="tuesday">سه شنبه</option>
                            <option value="wednesday">چهار شنبه</option>
                            <option value="thursday">پنجشنبه</option>
                            <option value="friday">جمعه</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="schedularScroll" id="style-3">
                      <div className="SchedulerContainer">
                        <div className="HoursOftheDay">
                          <div className="SchedulerItem">ساعت</div>
                          <div className="SchedulerItem">00:00 - 01:00</div>
                          <div className="SchedulerItem">01:00 - 02:00</div>
                          <div className="SchedulerItem">02:00 - 03:00</div>
                          <div className="SchedulerItem">03:00 - 04:00</div>
                          <div className="SchedulerItem">04:00 - 05:00</div>
                          <div className="SchedulerItem">05:00 - 06:00</div>
                          <div className="SchedulerItem">06:00 - 07:00</div>
                          <div className="SchedulerItem">07:00 - 08:00</div>
                          <div className="SchedulerItem">08:00 - 09:00</div>
                          <div className="SchedulerItem">09:00 - 10:00</div>
                          <div className="SchedulerItem">10:00 - 11:00</div>
                          <div className="SchedulerItem">11:00 - 12:00</div>
                          <div className="SchedulerItem">12:00 - 13:00</div>
                          <div className="SchedulerItem">13:00 - 14:00</div>
                          <div className="SchedulerItem">14:00 - 15:00</div>
                          <div className="SchedulerItem">15:00 - 16:00</div>
                          <div className="SchedulerItem">16:00 - 17:00</div>
                          <div className="SchedulerItem">17:00 - 18:00</div>
                          <div className="SchedulerItem">18:00 - 19:00</div>
                          <div className="SchedulerItem">19:00 - 20:00</div>
                          <div className="SchedulerItem">20:00 - 21:00</div>
                          <div className="SchedulerItem">21:00 - 22:00</div>
                          <div className="SchedulerItem">22:00 - 23:00</div>
                          <div className="SchedulerItem">23:00 - 23:59</div>
                        </div>
                        <div className="HoursOftheDay">
                          <div className="SchedulerItem">{selectedDay ? handleTranslateDayToPersian() : "روز نامشخص"}</div>
                          {handleColorizeRows()}
                        </div>
                      </div>
                    </div>
                    <div className="FullWidthContainer">
                      <div className="AddNewRuleBtnContainer">
                        <div className="SlidingMenuItemTitle">لطفا تعرفه های مورد نظر را در ساعات مورد نظر انتخاب کنید</div>
                        <div onClick={slideMenuOpenerHandler} className="AddNewRuleBtn">
                          برای اضافه کردن قانون جدید اینجا کلیک کنید
                        </div>
                      </div>
                      {currentServiceTypesTarriffs
                        ? currentServiceTypesTarriffs.map((rule, index) => (
                            <div key={index} className="SchedulerRuleAddContainer">
                              <div className="SchedulerRuleTimeAdd">
                                <span className="icon-add"></span>
                              </div>
                              <div className="SchedulerRuleAddTextBox SchedulerRuleTimeAdd">
                                <select disabled className="DropDownSelectNoFlag" name="deleteSelectTag" id="deleteSelectTag" onChange={(e) => handleSelectedRule(e)}>
                                  <option value="-1" disabled>
                                    لطفا روز مورد نظر را انتخاب نمایید.
                                  </option>
                                  {/* <option value="saturday">تعرفه رایگان </option> */}
                                  {myServiceRules.entities
                                    ? myServiceRules.entities.map((item, index) => (
                                        <option key={index} value={item.id} selected={item.id === rule.id ? true : false}>
                                          {item.title} : {item.price} تومن
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                              <div className="SchedulerRuleTimeAdd">
                                <div>از</div>
                                <input disabled type={"time"} className="SlidingMenuTextBox" id="ServiceTypesStartTime" value={rule.startTime || ""}></input>
                              </div>
                              <div className="SchedulerRuleTimeAdd">
                                <div>تا</div>
                                <input disabled type={"time"} className="SlidingMenuTextBox" id="ServiceTypesEndTime" value={rule.endTime || ""}></input>
                              </div>
                              <div className="SchedulerRuleTimeAdd">
                                <button type="sumbit" className="CancelButton" onClick={() => deleteModalOpenerHandler(rule)}>
                                  <span className="icon-minus"></span>
                                  حذف
                                </button>
                              </div>
                            </div>
                          ))
                        : null}
                      {selectedDay ? (
                        <div className="SchedulerRuleAddContainer">
                          <div className="SchedulerRuleTimeAdd">
                            <span className="icon-add"></span>
                          </div>
                          <div className="SchedulerRuleAddTextBox SchedulerRuleTimeAdd">
                            <select className="DropDownSelectNoFlag" name="addSelectTag" id="addSelectTag" defaultValue="0" onChange={(e) => handleSelectedRule(e)}>
                              <option id="newOption" value="0" disabled>
                                لطفا روز مورد نظر را انتخاب نمایید.
                              </option>
                              {/* <option value="saturday">تعرفه رایگان </option> */}
                              {myServiceRules.entities
                                ? myServiceRules.entities.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.title} : {item.price} تومن - از {item.startTime} تا {item.endTime}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </div>
                          <div className="SchedulerRuleTimeAdd">
                            <div>از</div>
                            <input disabled type={"time"} className="SlidingMenuTextBox" id="newServiceTypeFromTime" value={selectedStartTime || ""}></input>
                          </div>
                          <div className="SchedulerRuleTimeAdd">
                            <div>تا</div>
                            <input disabled type={"time"} className="SlidingMenuTextBox" id="newServiceTypeToTime" value={selectedEndTime || ""}></input>
                          </div>
                          <div className="SchedulerRuleTimeAdd">
                            <button type="sumbit" className="SaveButton" onClick={() => handleConfirmRule()}>
                              <span className="icon-add"></span>
                              تایید
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {isDeviceSettingTrue ? (
                  <div className="DevicesPadding">
                    <>
                      <div className="CrudContainer">
                        <div className="ButtonsContainer">
                          <div onClick={() => slideMenuAddDeviceOpenerHandler()} className="BackgorundPrimaryColor button-left">
                            <span className="icon-add"></span>
                            اضافه
                          </div>
                          {isDeviceSelected === true ? (
                            <>
                              <div onClick={() => slideMenuEditDeviceOpenerHandler()} className="BackgorundPrimaryColor button-left">
                                <span className="icon-pencil"></span>
                                ویرایش
                              </div>
                              <div onClick={() => slideMenuDeleteDeviceOpenerHandler()} className="BackgorundSecondaryColor button-left">
                                <span className="icon-trash"></span>
                                حذف
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="BackgorundPrimaryColor button-left ButtonDisabled">
                                <span className="icon-pencil"></span>
                                ویرایش
                              </div>
                              <div className="BackgorundSecondaryColor button-left ButtonDisabled">
                                <span className="icon-trash"></span>
                                حذف
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {ServiceTypeDevices ? (
                        <div className="GridViewAndPaginationContainer">
                          <div className="TableGridViewContainer">
                            <ol className="collection collection-container">
                              <li className="item item-container">
                                <div className="attribute Firstchild">شماره ردیف</div>
                                <div className="attribute">نام دستگاه</div>
                                <div className="attribute">نوع دستگاه</div>
                                <div className="attribute">آی پی دستگاه</div>
                              </li>
                              <form>
                                {ServiceTypeDevices.map((item, index) => (
                                  <li key={index + 1} className="item item-container">
                                    <div className="attribute Firstchild" data-name="#">
                                      <input
                                        className="Checkbox"
                                        type="radio"
                                        name="ServiceTypesItem"
                                        value={item.id}
                                        id={item.id}
                                        onChange={() => {
                                          setSelectedDeviceCheckBox(item);
                                          setIsDeviceSelected(true);
                                        }}
                                      />
                                      {index + 1}
                                    </div>
                                    <div className="attribute-container part-id">
                                      <div className="attribute" data-name="ServiceTypes Name">
                                        {item.deviceName}
                                      </div>
                                    </div>
                                    <div className="attribute-container part-id">
                                      <div className="attribute" data-name="ServiceTypes Name">
                                        {item.deviceType}
                                      </div>
                                    </div>
                                    <div className="attribute-container part-id">
                                      <div className="attribute" data-name="ServiceTypes Name">
                                        {item.deviceIp}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </form>
                            </ol>
                          </div>
                        </div>
                      ) : (
                        <div className="GridViewAndPaginationContainer">
                          <div className="TableGridViewContainer">
                            <ol className="collection collection-container">
                              <li className="item item-container">
                                <div className="attribute Firstchild">شماره ردیف</div>
                                <div className="attribute">نام سرویس</div>
                                <div className="attribute">وضعیت</div>
                              </li>
                            </ol>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                ) : null}
                {isSelectableTrue ? (
                  <>
                    <div className="LocationPickerContainer">
                      <div className="LocationPickerIconItemWidth">
                        <div>لطفا یک آیکون را برای سرویس مربوطه انتخاب کنید.</div>
                        <div>
                          <ReservationIconPicker
                            SelectedIcon={(e) => {
                              setServiceIcon(e);
                            }}
                            CurrentIcon={serviceIcon}
                            multiSelect={0}
                          />
                        </div>
                        <div>در صورت موجود بودن لطفا عکس پلان محل مورد نظر را برای راحتی بهتر کاربران در انتخاب آپلود کنید.</div>
                        <div className="EditMenuFullWidthContainer">
                          <div className="EditMenuUploadImageContainer">
                            {mapUploadedImageUrl ? <img className="UploadImage" src={mapUploadedImageUrl} alt=""></img> : <div className="UploadNoImageText"> {loadingHandler ? <LoadingUpload /> : "محل بارگذاری عکس"}</div>}
                          </div>
                          {loadingHandler ? <input type="file" id="mapfile" className="uploadButton ButtonDisabled" disabled /> : <input type="file" id="mapfile" className="uploadButton" onChange={mapFileSelectedHandler} />}
                          {loadingHandler ? (
                            <label htmlFor="mapfile" className="ButtonDisabled" disabled>
                              بارگذاری عکس
                            </label>
                          ) : (
                            <label htmlFor="mapfile">بارگذاری عکس</label>
                          )}
                        </div>
                      </div>
                      <div className="LocationPickerItemWidth">
                        <div>تعداد صندلی های موجود مورد نظر را مشخص کنید.</div>
                        <div className="LocationPickerItem">
                          <input className="SlidingMenuTextBox" id="ServiceTypesTitle" value={fetchedmapDetailsToString || ""} onChange={(e) => setFetchedmapDetailsToString(e.target.value)} />
                          <button type="sumbit" className="SaveButton" onClick={() => handleAddSeatsString()}>
                            <span className="icon-SaveButton "></span>
                            ذخیره
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {devicesEditSlideMenuIsOpen && <EditServiceTypeDevice onClickCloseHandler={slideMenuCloserHandler} ServiceId={selectedCheckBox} SelectedDeviceItem={selectedDeviceCheckBox} />}
      {devicesDeleteSlideMenuIsOpen && <DeleteServiceTypeDevice onClickCloseHandler={slideMenuCloserHandler} ServiceId={selectedCheckBox} SelectedDeviceItem={selectedDeviceCheckBox} />}
      {devicesSlideMenuIsOpen && <AddServiceTypeDevice onClickCloseHandler={slideMenuCloserHandler} ServiceId={selectedCheckBox} />}
      {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
      {slideMenuIsOpen && <AddServiceRulesSlidingMenu onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={fetchAllServiceRyles} />}
      {deleteModalOpan && <DeleteScheduleModal ServiceID={selectedCheckBox} CurrentServiceTypes={currentRule} SelectedDay={selectedDay} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={fillStates} />}
      {/* {deleteModalOpan && <DeleteScheduleModal ServiceID={myServiceRules.id} CurrentServiceTypes={currentRule} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={handleSelectedDay(selectedDay)} />} */}
    </>
  );
}

export default withRouter(EditServiceTypes);

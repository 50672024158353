export const devicesReducer = (state = [], action) => {
  switch (action.type) {
    case "GETDEVICETYPES":
      return [...action.payload];
    default:
      return state;
  }
};
export const devicesCategoriesReducer = (state = [], action) => {
  switch (action.type) {
    case "GETDEVICECATEGORIES":
      return [...action.payload];
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import DevicesGridTable from "./DevicesGridTable";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { useDispatch } from "react-redux";

import AddDevicesSlidingMenu from "./AddDevicesSlidingMenu";
import { actionGetAllDeviceTypes } from "../../../actions";

export default function Devices() {
  useEffect(() => {
    getDevices();
  }, []);

  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(8);
  // const [currentDevices, setCurrentDevices] = useState();

  const getDevices = (myPageIndex) => {
    dispatch(actionGetAllDeviceTypes());
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);

  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    // setCurrentDevices(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }

  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddDevicesSlidingMenu onClickCloseHandler={slideMenuCloserHandler} />}
        {/* {deleteModalOpan && <DeleteDevicesModal CurrentDevices={currentDevices} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getDevices} />} */}
        <DevicesGridTable PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GrayedOut from "./../../../components/ui/grayedout/GrayedOut";
import AddUnitSlidingMenu from "./AddUnitSlidingMenu";
import DeleteUnitModal from "./DeleteUnitModal";
import UnitGridTable from "./UnitGridTable";
import { actionGetAllUnits } from "../../../actions";

export default function Unit() {
  useEffect(() => {
    getUnits();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentUnit, setCurrentUnit] = useState();

  const getUnits = async (myPageIndex) => {
    dispatch(actionGetAllUnits(1, pageSize));
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentUnit(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddUnitSlidingMenu onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getUnits} />}
        {deleteModalOpan && <DeleteUnitModal CurrentUnit={currentUnit} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getUnits} />}
        <UnitGridTable onClickRefresh={getUnits} PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";

export default function ProjectsGridTable(props) {
  const myProjects = useSelector((state) => state.allProjects);
  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox.id} IsSelected={isSelected} NavlinkAdress={"/EditProject"} />
      {myProjects.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام پروژه</div>
                <div className="attribute">تعداد واحد ها</div>
              </li>
              <form>
                {myProjects.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="ProjectItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Project Name">
                        {item.name}
                      </div>
                    </div>
                    <div className="attribute-container vendor-information">
                      <div className="attribute" data-name="Description">
                        {item.numberOfUnits}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myProjects.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myProjects.totalCount}
            TotalPages={myProjects.totalPages}
            HasPreviousPage={myProjects.hasPreviousPage}
            HasNextPage={myProjects.hasNextPage}
            actionType="Projects"
          />
        </div>
      ) : (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام پروژه</div>
                <div className="attribute">تعداد واحد ها</div>
              </li>
              <form></form>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}

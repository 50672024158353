import React, { useEffect, useState } from "react";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { useDispatch } from "react-redux";
import GatesGridTable from "./GatesGridTable";
import { actionGetAllGates } from "../../../actions";
import AddGateSlidingMenu from "./AddGateSlidingMenu";
// import DeleteGateModal from "./DeleteGateModal";

export default function Gate() {
  useEffect(() => {
    getGate();
  }, []);

  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(8);
  //   const [currentGate, setCurrentGate] = useState();

  const getGate = async (myPageIndex) => {
    dispatch(actionGetAllGates());
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);

  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    // setCurrentGate(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }

  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddGateSlidingMenu onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getGate} />}
        {/* {deleteModalOpan && <DeleteGateModal CurrentGate={currentGate} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getGate} />} */}
        <GatesGridTable onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}

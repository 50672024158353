export const ResidentsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETRESIDENTS":
      return { ...action.payload };
    default:
      return state;
  }
};

export const selectedResidentReducer = (state = {}, action) => {
  switch (action.type) {
    case "GETSELECTEDRESIDENT":
      return { ...action.payload };
    default:
      return state;
  }
};

export const deleteResidentReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETESELECTEDRESIDENT":
      return { ...action.payload };
    default:
      return state;
  }
};

import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllResidents } from "../../../actions";

export default function BuildingResidentsGridTable(props) {
  const myResidents = useSelector((state) => state.allResidents);
  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);

  const [residentSearchUsername, setResidentSearchUsername] = useState("");
  const [residentSearchname, setResidentSearchname] = useState("");

  const dispatch = useDispatch();
  const filterData = (searchVal, field) => {
    let filterData = [];
    let filterUsername = field === "username" ? searchVal : residentSearchUsername;
    let filtername = field === "name" ? searchVal : residentSearchname;

    if (field === "username") {
      setResidentSearchUsername(searchVal);
    } else if (field === "name") {
      setResidentSearchname(searchVal);
    }

    // if (filterUsername) filterData.push({ filterUsername, filtername });

    dispatch(actionGetAllResidents(filterUsername, filtername, "", "", "", 1, 8));
  };
  const ResetSearch = () => {
    setResidentSearchUsername("");
    setResidentSearchname("");
    dispatch(actionGetAllResidents("", "", "", "", "", 1, 8));
  };

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox.id} IsSelected={isSelected} NavlinkAdress={"/EditBuildingResident"} />
      {myResidents.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container DataGridHeader">
                <div className="attribute Firstchild">شماره ردیف</div>

                <div className="attribute">نام ساکن</div>
                <div className="attribute">نام کاربری (موبایل)</div>
                <div className="attribute"></div>
              </li>
              <li className="item item-container SearchRow">
                <div className="attribute Firstchild">
                  <span className="icon-Search"></span>
                  <div>جستجو</div>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="جستجو با نام" name="Name" value={residentSearchname} onChange={(e) => filterData(e.target.value, "name")}></input>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" placeholder="جستجو با شماره همراه" name="PhoneNumber" value={residentSearchUsername} onChange={(e) => filterData(e.target.value, "username")}></input>
                </div>
                <div className="GridItemSearchContainer">
                  <div onClick={(e) => ResetSearch()} className="ClearButton">
                    <span className="icon-trash"></span>
                    پاک کردن فیلتر
                  </div>
                </div>
              </li>
              <form>
                {myResidents.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="BuildingResidentsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="BuildingResidents Name">
                        {item.displayName}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="BuildingResidents Name">
                        {item.username}
                      </div>
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="BuildingResidents Name"></div>
                    </div>

                    {/* <div className="attribute-container part-id">
                      <div className="attribute" data-name="Role Name">
                        {item.isActive ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>}
                      </div>
                    </div> */}
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myResidents.pageIndex}
            SearchField1={residentSearchUsername}
            SearchField2={residentSearchname}
            SearchField3={""}
            SearchField4={""}
            SearchField5={""}
            PageSize={props.PassPageSize}
            TotalCount={myResidents.totalCount}
            TotalPages={myResidents.totalPages}
            HasPreviousPage={myResidents.hasPreviousPage}
            HasNextPage={myResidents.hasNextPage}
            actionType="Residents"
          />
        </div>
      ) : null}
    </>
  );
}

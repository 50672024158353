import React, { useEffect, useState } from "react";
import "../../slidingmenu/slidingmenu.css";

export default function IconPicker(props) {
  const Icons = ["icon-Squash", "icon-FootballCourt", "icon-TennisCourt", "icon-billiards", "icon-Gazebo", "icon-seat", "icon-AirHockey", "icon-Gamingroom", "icon-PingPongTable"];

  const [Icon, setIcon] = useState([]);

  useEffect(() => {
    setIcon(props.CurrentIcon);
  }, []);

  if (props.multiSelect === 1) {
    return (
      <div className="ReservationIconSelectorContainer">
        {Icons.map((item, index) => (
          <button
            key={index}
            type="button"
            className={props.CurrentIcon.includes(item) ? item + " IconSelectorIcon IconSelectorIconSelected" : item + " IconSelectorIcon"}
            value={item}
            onClick={(e) => {
              setIcon(e.target.value);
              props.SelectedIcon(e.target.value);
            }}
          />
        ))}
      </div>
    );
  } else {
    return (
      <div className="ReservationIconSelectorContainer">
        {Icons.map((item, index) => (
          <button
            key={index}
            type="button"
            className={Icon === item ? item + " IconSelectorIcon IconSelectorIconSelected" : item + " IconSelectorIcon"}
            value={item}
            onClick={(e) => {
              setIcon(e.target.value);
              props.SelectedIcon(e.target.value);
            }}
          />
        ))}
      </div>
    );
  }
}

import React, { useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import { useSelector } from "react-redux";

export default function UnitTransactionsGridTable(props) {
  const myTransactions = useSelector((state) => state.allSelectedTransactionByUnitId);

  return (
    <>
      {myTransactions.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container DataGridHeader">
                <div className="attribute Firstchild">شماره ردیف</div>

                <div className="attribute">عنوان شارژ</div>
                <div className="attribute">هزینه</div>
                <div className="attribute">وضعیت پرداخت</div>
              </li>

              <form>
                {myTransactions.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate firstNumber">
                        {item.chargeTitle}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="License Plate secondNumber">
                        {item.cost}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Role Name">
                        {item.isPayed ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myTransactions.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myTransactions.totalCount}
            TotalPages={myTransactions.totalPages}
            ChargeId={props.UnitId}
            HasPreviousPage={myTransactions.hasPreviousPage}
            HasNextPage={myTransactions.hasNextPage}
            actionType="UnitTransactions"
          />
        </div>
      ) : null}
    </>
  );
}

export const UnitResidency = [
  {
    english: "Owner",
    farsi: "مالک",
  },
  {
    english: "Tenant",
    farsi: "مستاجر",
  },
];

export const hotelServicesReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETHOTELSERVICES':
            return {...action.payload}
        default:
           return state;
    }
}
export const selectedhotelServiceReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETSELECTEDHOTELSERVICE':
            return {...action.payload}
        default:
           return state;
    }
}
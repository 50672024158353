import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAMirrorComplimentItem } from "../../../Services/mirrorServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddMirrorComplimentsItemSlidingMenu(props) {
  const [MealTimeTitle, setMealTimeTitle] = useState("");
  const [MealTimeLanguageId, setMealTimeLanguageId] = useState("");

  const [languageDDlist, setLanguageDDlist] = useState("");

  const reset = () => {
    setMealTimeTitle("");
  };

  const addMealTimeItemHandler = async (e) => {
    e.preventDefault();
    const { status } = await PostAMirrorComplimentItem(props.MirrorComplimenttId, MealTimeTitle, MealTimeLanguageId);
    if (status === 200) {
      reset();
      toast.success("Meal time item added Successfully");
      props.onClickCloseHandler();
      props.ResetPage();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">Add new Service</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="TitleAndSliderCheck">
        <div className="HalfWidthInsideFlex">
          <div className="FullWidthContainer">
            <div className="SlidingMenuItemTitle">Choose Language</div>
            <div className="SlidingMenuTextBox DropDownContainer">
              <img className="DropDownMenuImage" src={"/images/countries/" + languageDDlist + ".png"} alt="" />

              <select
                onChange={(e) => {
                  setLanguageDDlist(e.target.value.substring(0, 2));
                  setMealTimeLanguageId(e.target.value.substring(2));
                }}
                className="DropDownSelect"
                name="LangId"
                id="LangId"
                defaultValue="SelectLanguage">
                <option value="SelectLanguage" disabled>
                  Select Language
                </option>

                {props.AvailableLanguages.map((item, index) => (
                  <option key={index} value={item.code + item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">Compliment</div>
        <input className="SlidingMenuTextBox" id="announcementTitle" value={MealTimeTitle} onChange={(e) => setMealTimeTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">This would be the translation of the compliment in the selected language.</div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            Cancel
          </div>

          {MealTimeTitle && languageDDlist ? (
            <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addMealTimeItemHandler(e)}>
              Save
            </button>
          ) : (
            <button disabled className="SlidingMenuSavebutton ButtonDisabled">
              Save
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddMirrorComplimentsItemSlidingMenu);

import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";
import ProgressBar from "@ramonak/react-progress-bar";
import { PostAServiceType } from "../../../Services/managementServices";
import axios from "axios";

function AddServiceTypesSlidingMenu(props) {
  const [serviceTypesTitle, setServiceTypesTitle] = useState("");
  const [serviceTypesDescription, setServiceTypesDescription] = useState("");

  const [uploadedImage, setUploadedImage] = useState(null);
  const [loadingHandler, setLoadingHandler] = useState(false);
  const [progressData, setProgressData] = useState(0);

  const fileValidTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];

  const reset = () => {
    setServiceTypesTitle("");
    setServiceTypesDescription("");
  };

  const fileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("Format not supported");
      } else {
        setProgressData(0);
        setLoadingHandler(true);
        const response = await axios.post("https://pbmapi.pardikiot.com/api/Images", imageData, {
          headers: {
            accept: "text/plain",
            bucketName: "Buildings",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            let progressValue = Math.round((100 * data.loaded) / data.total);
            setProgressData(progressValue);
          },
        });
        if (response.status === 200) {
          setUploadedImage(response.data);
          toast.success("Image uploaded Successfully");
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoadingHandler(false);
    }
  };

  const addNewServiceTypeHandler = async (e) => {
    e.preventDefault();

    try {
      const { data, status } = await PostAServiceType(uploadedImage.fileKey, serviceTypesTitle, serviceTypesDescription);
      if (status === 200) {
        reset();
        toast.success("ServiceTypes added Successfully");
        props.onClickCloseHandler();
        props.onClickRefresh();
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.ErrorDescription : "Something went wrong!");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن سرویس جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">نام سرویس</div>
        <input className="SlidingMenuTextBox" id="ServiceTypesTitle" value={serviceTypesTitle} onChange={(e) => setServiceTypesTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">نامگذاری سرویس برای ارائه به مخاطب و مدیریت آن سرویس از طریق پنل مدیریت.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="FullWidthUploadImageContainer">
          {uploadedImage ? <img className="UploadImage" src={uploadedImage.fileUrl} alt=""></img> : <div className="UploadNoImageText"> {loadingHandler ? <LoadingUpload /> : "محل بارگذاری عکس"}</div>}
        </div>
        {loadingHandler ? <input type="file" id="file" className="uploadButton ButtonDisabled" disabled /> : <input type="file" id="file" className="uploadButton" onChange={fileSelectedHandler} />}
        {loadingHandler ? (
          <label htmlFor="file" className="ButtonDisabled" disabled>
            بارگذاری عکس
          </label>
        ) : (
          <label htmlFor="file">بارگذاری عکس</label>
        )}
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">توضیح سرویس</div>
        <textarea className="SlidingMenuTextArea" id="ServiceTypesDescription" value={serviceTypesDescription} onChange={(e) => setServiceTypesDescription(e.target.value)} />
        <div className="SlidingMenuMoreDetail">توضیح کوتاهی از سرویس ارائه شده برای مخاطبان.</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>

        <div className="ButtonsContainer">
          <button type="sumbit" className="SaveButton" onClick={(e) => addNewServiceTypeHandler(e)}>
            <span className="icon-SaveButton"></span>
            ذخیره
          </button>
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddServiceTypesSlidingMenu);

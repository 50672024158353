import React from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ResourcesGridTable(props) {
  const myResources = useSelector((state) => state.allResources);
  return (
    <>
      <div className="CrudContainer">
        <div className="ButtonsContainer">
          <div onClick={props.onClickOpenHandler} className="BackgorundPrimaryColor button-left">
            <span className="icon-add"></span>
            اضافه
          </div>
        </div>
      </div>

      {myResources.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام منبع</div>
                <div className="attribute">توضیحات</div>
                <div className="attribute"></div>
              </li>

              {myResources.entities.map((item, index) => (
                <li key={index + 1} className="item item-container">
                  <div className="attribute Firstchild" data-name="#">
                    {index + 1}
                  </div>
                  <div className="attribute-container part-id">
                    <div className="attribute" data-name="Role Name">
                      {item.title}
                    </div>
                  </div>
                  <div className="attribute-container vendor-information">
                    <div className="attribute" data-name="Description">
                      {item.description}
                    </div>
                  </div>
                  <div className="attribute-container vendor-information">
                    <div className="attribute" data-name="Description">
                      <Link
                        to={{
                          pathname: "/ResourceDetail",
                          state: { ResourceId: item.id },
                        }}
                        className="button BackgorundPrimaryColor">
                        جزیئیات
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
          <Pagination
            PageIndex={myResources.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myResources.totalCount}
            TotalPages={myResources.totalPages}
            HasPreviousPage={myResources.hasPreviousPage}
            HasNextPage={myResources.hasNextPage}
            actionType="Resources"
          />
        </div>
      ) : null}
    </>
  );
}

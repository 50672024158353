import jwt_decode from 'jwt-decode';

const decodeToken = (token) => {
    // console.log('before :',token)
    var decoded = jwt_decode(token);

    // console.log('After :',decoded)
    return decoded;
};

export default decodeToken;

import { withRouter } from "react-router-dom";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { isLoggedIn, logoutAction } from "../../../actions";

const Logout = ({ history }) => {
  const dispatch = useDispatch();

  const token = JSON.parse(localStorage.getItem("residentialManagement"));
  if (token) {
    localStorage.removeItem("residentialManagement");
    dispatch(logoutAction(null));
    dispatch(isLoggedIn(false));

    toast.success("شما با از پنل  کاربری خود خارج شدید", {
      position: "top-right",
      closeOnClick: "true",
      theme: "dark",
    });
    history.replace("/");
  } else {
    history.replace("/");
  }

  return null;
};

export default withRouter(Logout);

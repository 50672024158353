import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAResource } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddResourceSlidingMenu(props) {
  const [ResourceTitle, setResourceTitle] = useState("");
  const [ResourceDescription, setResourceDescription] = useState("");

  const reset = () => {
    setResourceTitle("");
    setResourceDescription("");
  };

  const addNewResourceHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await PostAResource(ResourceTitle, ResourceDescription);
    if (status === 200) {
      reset();
      toast.success("Resource added Successfully");
      props.onClickCloseHandler();
      props.history.push("/Resources");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن منبع جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">عنوان منبع</div>
        <input className="SlidingMenuTextBox" id="ResourceTitle" value={ResourceTitle} onChange={(e) => setResourceTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">عنوان منبع برای مشخص کردن منبع در قسمت تخصیص منابع به افراد.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">توضیحات منبع</div>
        <textarea className="SlidingMenuTextArea" id="ResourceDescription" value={ResourceDescription} onChange={(e) => setResourceDescription(e.target.value)} />
        <div className="SlidingMenuMoreDetail">توضیحی اجمالی برای تعریف این منبع جهت آشنایی بیشتر کاربر با این منبع.</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="ButtonsContainer">
          <button type="sumbit" className="SaveButton" onClick={(e) => addNewResourceHandler(e)}>
            <span className="icon-SaveButton"></span>
            ذخیره
          </button>
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddResourceSlidingMenu);

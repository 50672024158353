import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { actionGetSelectedRole } from "../../../actions";
import "../../../components/slidingmenu/slidingmenu.css";
import { PutARole } from "../../../Services/managementServices";

function EditRole({ history }) {
  const dispatch = useDispatch();

  const mySelectedRole = useSelector((state) => state.selectedRole);
  const selectedCheckBox = history.location.state.id;

  const [selectedResource, setSelectedResource] = useState([]);
  const [currentResources, setCurrenctResources] = useState([]);
  const [selectedResourceTitle, setSelectedResourceTitle] = useState("");
  const [selectedResourceDescription, setSelectedResourceDescription] = useState("");

  const [roleTitle, setRoleTitle] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [roleUserType, setRoleUserType] = useState("");

  useEffect(() => {
    dispatch(actionGetSelectedRole(selectedCheckBox));
  }, []);

  const handleFillSelectedResources = (x) => {
    setSelectedResource(x.resourceItemOfRole);
    setCurrenctResources(x.resourceItemOfRole);
    setSelectedResourceTitle(x.title);
    setSelectedResourceDescription(x.description);
  };

  const handleSelectedRolesLength = (resourceItem) => {
    let count = 0;
    if (resourceItem.length > 0) {
      var result = resourceItem.filter((x) => x.isSelected === true);
      count = result.length;
    }
    return count;
  };

  const handleCheckSelectedResources = (e, selectedResource) => {
    if (selectedResource) {
      //------------------
      const copyUserCurrentResources = [...currentResources];
      const newResources = copyUserCurrentResources.find((x) => x.id === selectedResource.id);
      newResources.isSelected = e;

      setCurrenctResources(copyUserCurrentResources);
      //-----------------
    }
  };
  const updateRoleHandler = async (e) => {
    e.preventDefault();

    const selectedRolesId = [];
    const title = roleTitle ? roleTitle : mySelectedRole.title;
    const description = roleDescription ? roleDescription : mySelectedRole.description;
    const userType = roleUserType ? roleUserType : mySelectedRole.userType;
    mySelectedRole.resourceOfRoles.map((item) => {
      item.resourceItemOfRole.forEach((x) => {
        if (x.isSelected) {
          selectedRolesId.push(x.id);
        }
      });
    });

    const { status } = await PutARole(selectedCheckBox, title, description, selectedRolesId, userType);
    if (status === 200) {
      toast.success("Updated Successfully");
    }
  };

  return (
    <div className="ContentBody">
      {mySelectedRole ? (
        <div className="BodyPadding">
          <form className="EditPageThreeColumnFlex">
            <div className="EditPageThreeColumnFlexItem">
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">عنوان نقش</div>
                <input className="SlidingMenuTextBox" id="roleTitle" value={roleTitle ? roleTitle : mySelectedRole.title} onChange={(e) => setRoleTitle(e.target.value)} />
                <div className="SlidingMenuMoreDetail">این عنوان معرف نقش تعریف شده برای سهولت در تشخیص نقش در تخصیصبه کاربر می باشد.</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuTextBox DropDownContainer">
                  <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId" value={roleUserType ? roleUserType : mySelectedRole.userType} onChange={(e) => console.log(e.target.value)}>
                    <option value="Choose a Device Type" disabled selected>
                      نوع کاربری
                    </option>
                    <option value="Admin">ادمین</option>
                    <option value="BuildingAdmin">ادمین ساختمان</option>
                    <option value="UnitAdmin">ادمین واحد</option>
                    <option value="Resident">ساکن</option>
                  </select>
                </div>
                <div className="SlidingMenuMoreDetail">نوع کاربری مورد نظر را برای این نقش انتخاب کنید</div>
              </div>

              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">توضیحات نقش</div>
                <textarea className="SlidingMenuTextArea" id="roleDescription" value={roleDescription ? roleDescription : mySelectedRole.description} onChange={(e) => setRoleDescription(e.target.value)} />
                <div className="SlidingMenuMoreDetail">توضیحاتی مختصر برای نقش برای تعریف شده.</div>
              </div>
            </div>
            <div className="EditPageThreeColumnFlexItem">
              <div className="EditPageItemBorder">
                <div className="EditPageItemHeader">
                  <div className="SlidingMenuTitle">دسترسی ها</div>
                </div>
                <div id="style-3" className="RoleAssignmentScroll">
                  {mySelectedRole.resourceOfRoles
                    ? mySelectedRole.resourceOfRoles.map((item, index) => (
                        <div key={index} className="EditPageColomnSelectableItem" onClick={() => handleFillSelectedResources(item)}>
                          <div>{item.title}</div>
                          <div>
                            {handleSelectedRolesLength(item.resourceItemOfRole)}/{item.resourceItemOfRole.length}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div className="EditPageThreeColumnFlexItem">
              <div className="EditPageItemBorderSmallerDescription">
                <div className="EditTitle">{selectedResourceTitle}</div>
                <div className="EditDescriptions">{selectedResourceDescription}</div>
              </div>
              <div className="EditPageItemBorderSmaller">
                <div className="EditPageItemHeader">
                  <div className="SlidingMenuTitle">منابع</div>
                </div>
                {currentResources.length > 0
                  ? currentResources.map((item) => (
                      <div className="EditPageColomnSelectableItem">
                        <div>{item.resourceName}</div>
                        <input className="Checkbox" type="checkbox" name="" id="" onChange={(e) => handleCheckSelectedResources(e.target.checked, item)} checked={item.isSelected} />
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <div className="RoleAssignmentSlidingMenuFooterContainer RoleAssignmentFullWidthContainer">
              <div className="EditPgefooter">
                <div className="SlidingMenuSavebutton" onClick={(e) => updateRoleHandler(e)}>
                  ذخیره
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
}
export default withRouter(EditRole);

export const usersReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETUSERS':
            return {...action.payload}
        default:
           return state;
    }
}


export const selectedUserReducer=(state={},action)=>{
    switch (action.type) {
        case 'GETSELECTEDUSER':
            return {...action.payload}
        default:
           return state;
    }
}

export const deleteUserReducer=(state={},action)=>{
    switch (action.type) {
        case 'DELETESELECTEDUSER':
            return {...action.payload}
        default:
           return state;
    }
}
import React, { useState } from "react";
import { toast } from "react-toastify";
import { PostALicensePlate } from "../../../Services/managementServices";
import { PlateCharacters } from "./PlatesCharacters";

export default function AddLicensePlate(props) {
  const [licensePlateFirstNumber, setLicensePlateFirstNumber] = useState("");
  const [licensePlateSecondNumber, setlicensePlateSecondNumber] = useState("");
  const [licensePlateCharacter, setlicensePlateCharacter] = useState("");
  const [licensePlateCityCode, setlicensePlateCityCode] = useState("");
  const [licensePlateunitId, LicensePlateUnitId] = useState(props.UnitId);
  const [licensePlateNote, setLicensePlateNote] = useState("");

  const reset = () => {
    setLicensePlateFirstNumber("");
    setlicensePlateSecondNumber("");
    setlicensePlateCharacter("");
    setlicensePlateCityCode("");
    LicensePlateUnitId("");
  };

  const addLicensePlateHandler = async (e) => {
    e.preventDefault();
    const { status } = await PostALicensePlate(licensePlateFirstNumber, licensePlateSecondNumber, licensePlateCharacter, licensePlateCityCode, props.UnitId, licensePlateNote);
    if (status === 200) {
      reset();
      toast.success("پلاک باموفقیت ثبت شد");

      props.onClickCloseHandler();
      props.onClickRefresh();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن پلاک جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="FullWidthContainer plateAddHeight">
        <div className="PlateMainContainer">
          <span className="PlateCharactertemplate" />
          <input className="PlateCharacterHolder" maxLength="2" value={licensePlateFirstNumber} onChange={(e) => setLicensePlateFirstNumber(e.target.value)} type="tel" placeholder="⚊ ⚊" id="carPlateSection1" />
          <select
            value={licensePlateCharacter}
            className="PlateCharacterHolder"
            name="UsertypeId"
            id="UsertypeId"
            onChange={(e) => {
              setlicensePlateCharacter(e.target.value);
            }}>
            <option value="ChooseaDeviceType">حرف</option>

            {PlateCharacters
              ? PlateCharacters.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.farsi}
                  </option>
                ))
              : null}
          </select>
          <input className="PlateCharacterHolder PlateDivider" maxLength="3" value={licensePlateSecondNumber} onChange={(e) => setlicensePlateSecondNumber(e.target.value)} type="tel" placeholder="⚊ ⚊ ⚊" id="carPlateSection1" />
          <input className="PlateCharacterHolder" maxLength="2" value={licensePlateCityCode} onChange={(e) => setlicensePlateCityCode(e.target.value)} type="tel" placeholder="⚊ ⚊" id="carPlateSection1" />
        </div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">پیام ورود پلاک</div>
        <textarea className="SlidingMenuTextArea" id="ProjectDescription" value={licensePlateNote} onChange={(e) => setLicensePlateNote(e.target.value)} />
        <div className="SlidingMenuMoreDetail">این پیام هنگام ورود پلاک به پارکینگ به مدیران واحد نمایش داده می شود.</div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>

        <div className="ButtonsContainer">
          {licensePlateFirstNumber && licensePlateSecondNumber && licensePlateCityCode && licensePlateCharacter && licensePlateCharacter !== "ChooseaDeviceType" ? (
            <button onClick={(e) => addLicensePlateHandler(e)} type="sumbit" className="SaveButton">
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <div type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </div>
          )}

          <div onClick={props.onClickCloseHandler} className="CancelButton ">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

import { useDispatch } from "react-redux";
import { loginAction, isLoggedIn } from "./../actions/index";

const CheckLogin = () => {

  const dispatch = useDispatch();

  let isLogin = false;
  const token = JSON.parse(localStorage.getItem("residentialManagement"));
  if (token) {
    const dateNow = Date.now() / 1000;
    if (token.decodedToken.exp < dateNow) {
      localStorage.removeItem("residentialManagement");
      dispatch(loginAction(null));
      dispatch(isLoggedIn(false));
      isLogin = false;
    } else {
      
      dispatch(loginAction(token));
      dispatch(isLoggedIn(true));
      isLogin = true;
    }
  }
  return isLogin;
};

export default CheckLogin;

import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAnAnnouncement } from "../../../Services/mirrorServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../Services/config.json";
import { actionGetAllLanguages } from "../../../actions";
import axios from "axios";
import LoadingUpload from "../../../components/ui/loadingupload/LoadingUpload";

function AddAnnouncementSlidingMenu(props) {
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementDescription, setAnnouncementDescription] = useState("");
  const [announcementLanguageId, setAnnouncementLanguageId] = useState("04750147-c98a-4c81-b476-2e777c80ade8");
  const [announcementLocation, setAnnouncementLocation] = useState("");
  const [announcementTime, setAnnouncementTime] = useState("");
  const [announcementImageKey, setAnnouncementImageKey] = useState("");
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [expireDate, setExpireDate] = useState();

  const [languageDDlist, setLanguageDDlist] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [expiryDateHandler, setExpiryDateHandler] = useState(false);

  const [loadingHandler, setLoadingHandler] = useState(false);

  const reset = () => {
    setAnnouncementTitle("");
    setAnnouncementDescription("");
    setAnnouncementLanguageId("");
    setAnnouncementLocation("");
    setAnnouncementTime("");
    setAnnouncementImageKey("");
    setIsHighlight("");
  };

  const addNewAnnouncementHandler = async (e) => {
    e.preventDefault();
    const { status, data } = await PostAnAnnouncement(announcementTitle, announcementLanguageId, announcementTime, announcementLocation, announcementDescription, isComingSoon, expireDate, announcementImageKey, isHighlight);
    if (status === 200) {
      reset();
      toast.success("Announcement added Successfully");
      props.onClickCloseHandler();
      props.history.push({
        pathname: "/EditAnnouncement",
        state: { id: data.data },
      });
    }
  };

  const dispatch = useDispatch();

  const getLanguages = async () => {
    dispatch(actionGetAllLanguages());
  };
  const myLanguages = useSelector((state) => state.allLanguages);

  const fileValidTypes = ["image/png", "image/jpeg", "image/jpg"];

  const fileSelectedHandler = async (event) => {
    var binaryImage = event.target.files[0];
    let imageData = new FormData();
    imageData.append("file", binaryImage ? binaryImage : null);

    try {
      if (binaryImage && !fileValidTypes.includes(binaryImage.type)) {
        toast.error("Format not supported");
      } else {
        setUploadedImage("");
        setLoadingHandler(true);
        const response = await axios.post(`${config.PardikURL}/Images`, imageData, {
          headers: {
            accept: "text/plain",
            bucketName: config.BucketName,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          setUploadedImage(response.data.fileUrl);
          setAnnouncementImageKey(response.data.fileKey);
          toast.success("Image uploaded Successfully");
          setLoadingHandler(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoadingHandler(false);
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن سرویس جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="ScrollableSlidingmenu" id="style-3">
        <div>
          <div className="FullWidthContainer">
            <div className="SlidingMenuItemTitle">عنوان اطلاعیه</div>
            <input className="SlidingMenuTextBox" id="announcementTitle" value={announcementTitle} onChange={(e) => setAnnouncementTitle(e.target.value)} />
            <div className="SlidingMenuMoreDetail">این عنوان در تمامی صفحات ارائه شده برای کاربران قابل مشاهده خواهد بود.</div>
          </div>

          <div className="TitleAndSliderCheck">
            <div className="HalfWidthInsideFlex">
              <div className="FullWidthContainer">
                <div className="TitleAndSliderCheck">
                  <div className="SlidingMenuItemTitle">بزودی</div>
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => (isComingSoon ? setIsComingSoon(false) : setIsComingSoon(true))} />
                    <span className="switchslider round"></span>
                  </label>
                </div>
                <div className="SlidingMenuMoreDetail">یه روبان کوچک به با عنوان "بزودی" به اطلاعیه اضافه میشود</div>
              </div>
            </div>

            <div className="HalfWidthContainer">
              <div className="UploadImageContainer">{uploadedImage ? <img className="UploadImage" src={uploadedImage} alt=""></img> : <div className="UploadNoImageText"> {loadingHandler ? <LoadingUpload /> : "عکس"}</div>}</div>
              <input type="file" id="file" className="uploadButton" onChange={fileSelectedHandler} />
              <label htmlFor="file">بارگذاری عکس</label>
            </div>
          </div>
          <div className="TitleAndSliderCheck">
            <div className="HalfWidthInsideFlex">
              <div className="FullWidthContainer">
                <div className="TitleAndSliderCheck">
                  <div className="SlidingMenuItemTitle">نمایش به عنوان هایلایت</div>
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => (isHighlight ? setIsHighlight(false) : setIsHighlight(true))} />
                    <span className="switchslider round"></span>
                  </label>
                </div>
                <div className="SlidingMenuMoreDetail">در صورت فعال کردن این بخش، اطلاعیه تعریف شده در صفحه اصلی اپلیکیشن نیز نمایان می شود.</div>
              </div>
            </div>
          </div>

          <div className="FullWidthFlex">
            <div className="HalfWidthContainer">
              <div className="TitleAndSliderCheck">
                <div className="SlidingMenuItemTitle">تاریخ انقضا</div>
                <label className="switch">
                  <input type="checkbox" onChange={(e) => (expiryDateHandler ? setExpiryDateHandler(false) : setExpiryDateHandler(true))} />
                  <span className="switchslider round"></span>
                </label>
              </div>
            </div>

            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">زمان اتمام</div>
              {expiryDateHandler ? <input onChange={(e) => setExpireDate(e.target.value)} className="SlidingMenuTextBox" type="Date" id="DueDate" /> : <input className="SlidingMenuTextBox" type="Date" id="DueDate" disabled />}

              <div className="SlidingMenuMoreDetail">از تاریخ نتظیم شده به بعد این اطلاعیه به کاربران نشان داده نخواهد شد</div>
            </div>
          </div>

          <div className="FullWidthFlex">
            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">محل اطلاعیه</div>
              <input className="SlidingMenuTextBox" id="announcementLocation" value={announcementLocation} onChange={(e) => setAnnouncementLocation(e.target.value)} />
              <div className="SlidingMenuMoreDetail">محلی که اطلاعیه به آن اشاره دارد یا در آن رویداد مربوطه برگزار میشود.</div>
            </div>

            <div className="HalfWidthContainer">
              <div className="SlidingMenuItemTitle">زمان اطلاعیه</div>
              <input className="SlidingMenuTextBox" id="announcementTime" value={announcementTime} onChange={(e) => setAnnouncementTime(e.target.value)} />
              <div className="SlidingMenuMoreDetail">زمانی که اطلاعیه به آن اشاره دارد یا در آن رویداد مربوطه برگزار میشود.</div>
            </div>
          </div>
          <div className="FullWidthContainer">
            <div className="SlidingMenuItemTitle">توضیحات اطلاعیه</div>
            <textarea className="SlidingMenuTextArea" id="roleDescription" value={announcementDescription} onChange={(e) => setAnnouncementDescription(e.target.value)} />
            <div className="SlidingMenuMoreDetail">متن اطلاعیه ای می خواهید با آن ساکنین یا میهمانان را مطلع سازید.</div>
          </div>
        </div>
        <div>
          <div className="SlidingMenuFooterContainerScrollable FullWidthContainer">
            <hr></hr>
            <div className="ButtonsContainer">
              {uploadedImage && announcementTitle && announcementDescription ? (
                <button type="sumbit" className="SaveButton" onClick={(e) => addNewAnnouncementHandler(e)}>
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              ) : (
                <button disabled type="sumbit" className="SaveButton">
                  <span className="icon-SaveButton"></span>
                  ذخیره
                </button>
              )}
              <div onClick={props.onClickCloseHandler} className="CancelButton">
                <span className="icon-closeX"></span>
                لغو
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddAnnouncementSlidingMenu);

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionGetSelectedServiceRules } from "../../../actions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { PutAServiceRule } from "../../../Services/managementServices";

function EditServiceRules({ history }) {
  const dispatch = useDispatch();

  const mySelectedServiceRules = useSelector((state) => state.selectedServiceRules);
  const selectedCheckBox = history.location.state.id;
  const selectedCheckBoxData = history.location.state.selectedData;

  useEffect(() => {
    dispatch(actionGetSelectedServiceRules(selectedCheckBox));
    setServiceRulesTitle(selectedCheckBoxData.title);
    setServiceRulesPrice(selectedCheckBoxData.price);
    setPrivate(selectedCheckBoxData.isPrivate);
    setEndTimeRestriction(selectedCheckBoxData.endTime);
    setStartTimeRestriction(selectedCheckBoxData.startTime);
  }, []);

  const [ServiceRulesTitle, setServiceRulesTitle] = useState("");
  const [ServiceRulesPrice, setServiceRulesPrice] = useState("");
  const [isPrivate, setPrivate] = useState(null);
  const [endTimeRestriction, setEndTimeRestriction] = useState("");
  const [startTimeRestriction, setStartTimeRestriction] = useState("");
  const [isFree, setIsFree] = useState("");

  const updateServiceRulesHandler = async (e) => {
    e.preventDefault();

    const title = ServiceRulesTitle ? ServiceRulesTitle : mySelectedServiceRules.title;
    const price = ServiceRulesPrice ? ServiceRulesPrice : mySelectedServiceRules.price;
    const startTime = startTimeRestriction ? startTimeRestriction : mySelectedServiceRules.startTime;
    const endTime = endTimeRestriction ? endTimeRestriction : mySelectedServiceRules.endTime;
    const isPrivateStatus = isPrivate ? isPrivate : mySelectedServiceRules.isPrivate;

    try {
      const { status } = await PutAServiceRule(selectedCheckBox, title, price, startTime, endTime, isPrivateStatus);
      if (status === 200) {
        toast.success("Updated Successfully");
        history.push("/ServiceRules");
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.title : "Something went wrong!");
    }
  };

  return (
    <div className="ContentBody">
      {mySelectedServiceRules ? (
        <div className="BodyPadding">
          <div className="EditPageTwoColumnNoBG">
            <div className="EditPageTwoColumnItemWithBGWithScroll" id="style-3">
              <div className="EditContainerWithScroll">
                <div className="EditMenuFullWidthContainer">
                  <div className="TitleAndSliderCheck EditMenuFullWidthPadding">
                    <div className="EditItemTitle">وضعیت فعال بودن</div>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="switchslider round"></span>
                    </label>
                  </div>
                  <div className="SlidingMenuMoreDetail EditMenuFullWidthPadding">در صورت غیر فعال کردن این سرویس، دیگر تا فعال کردن دوباره آن برای مخاطبین به نمایش گذاشته نمیشود.</div>
                </div>
                <div className="FullWidthContainer">
                  <div className="SlidingMenuItemTitle">نام سرویس</div>
                  <input className="SlidingMenuTextBox" id="ServiceRulesTitle" value={ServiceRulesTitle} onChange={(e) => setServiceRulesTitle(e.target.value)} />
                  <div className="SlidingMenuMoreDetail">نامگذاری سرویس برای ارائه به مخاطب و مدیریت آن سرویس از طریق پنل مدیریت.</div>
                </div>
                <div className="FullWidthFlex">
                  {isFree ? (
                    <div className="HalfWidthContainer">
                      <div className="SlidingMenuItemTitle">هزینه سرویس (به ریال)</div>
                      <input disabled className="SlidingMenuTextBox ButtonDisabled" id="ServiceRulesPrice" value={"0"} />
                      <div className="SlidingMenuMoreDetail">هزینه سرویس ارائه شده. در صورت رایگان بودن گزینه مقابل را انتخاب نمایید.</div>
                    </div>
                  ) : (
                    <div className="HalfWidthContainer">
                      <div className="SlidingMenuItemTitle">هزینه سرویس (به ریال)</div>
                      <input className="SlidingMenuTextBox" id="ServiceRulesPrice" value={ServiceRulesPrice} onChange={(e) => setServiceRulesPrice(e.target.value)} />
                      <div className="SlidingMenuMoreDetail">هزینه سرویس ارائه شده. در صورت رایگان بودن گزینه مقابل را انتخاب نمایید.</div>
                    </div>
                  )}

                  <div className="HalfWidthContainer">
                    <div className="TitleAndSliderCheck">
                      <div className="SlidingMenuItemTitle">رایگان</div>
                      <label className="switch">
                        <input type="checkbox" onChange={(e) => (isFree ? (setIsFree(false), setServiceRulesPrice("")) : (setServiceRulesPrice("0"), setIsFree(true)))} />
                        <span className="switchslider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="FullWidthFlex">
                  <div className="HalfWidthContainer">
                    <div className="SlidingMenuItemTitle">زمان شروع</div>
                    <div className="SlidingMenuTextBox DropDownContainer">
                      <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId" value={startTimeRestriction} onChange={(e) => setStartTimeRestriction(e.target.value)}>
                        <option value="Choose a Device Type" disabled selected>
                          لطفا زمان شروع مورد نظر را انتخاب نمایید.
                        </option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                        <option value="23:59">24:00</option>
                      </select>
                    </div>
                    <div className="SlidingMenuMoreDetail">زمان شروع این قانون. توجه داشته باشید این ساعت باید کوچکتر از ساعت پایان باشد.</div>
                  </div>

                  <div className="HalfWidthContainer">
                    <div className="SlidingMenuItemTitle">زمان پایان</div>
                    <div className="SlidingMenuTextBox DropDownContainer">
                      <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId" value={endTimeRestriction} onChange={(e) => setEndTimeRestriction(e.target.value)}>
                        <option value="Choose a Device Type" disabled selected>
                          لطفا زمان شروع مورد نظر را انتخاب نمایید.
                        </option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                        <option value="23:59">24:00</option>
                      </select>
                    </div>
                    <div className="SlidingMenuMoreDetail">زمان پایان این قانون. توجه داشته باشید این ساعت باید بزرگتر از ساعت شروع باشد.</div>
                  </div>
                </div>
                <div className="FullWidthContainer">
                  <div className="SlidingMenuItemTitle">خصوصی / عمومی</div>
                  <div className="FullWidthFlex">
                    <div className="HalfWidthContainer">
                      <div className="PrivecyTypeContainer">
                        <input type="radio" checked={!isPrivate} onClick={() => setPrivate(false)} id="Public" name="PrivacyType" value="عمومی"></input>
                        <label>عمومی</label>
                      </div>
                    </div>
                    <div className="HalfWidthContainer">
                      <div className="PrivecyTypeContainer">
                        <input type="radio" checked={isPrivate} onClick={() => setPrivate(true)} id="Private" name="PrivacyType" value="خصوصی"></input>
                        <label>خصوصی</label>
                      </div>
                    </div>
                  </div>

                  <div className="SlidingMenuMoreDetail">در صورت خصوصی بودن قانون، انتخاب سرویس بعد از رزرو توسط اولین فرد برای دیگران در ساعت انتخاب شده مقدور نخواهد بود.</div>
                </div>
                <div className=" FullWidthContainer">
                  <hr></hr>

                  <div className="ButtonsContainer">
                    {endTimeRestriction > startTimeRestriction && ServiceRulesTitle && ServiceRulesPrice && endTimeRestriction && startTimeRestriction && isPrivate !== null ? (
                      <button type="sumbit" className="SaveButton" id="SaveButton" onClick={(e) => updateServiceRulesHandler(e)}>
                        <span className="icon-SaveButton"></span>
                        ذخیره
                      </button>
                    ) : (
                      <button type="sumbit" className="SaveButton ButtonDisabled" id="SaveButton">
                        <span className="icon-SaveButton"></span>
                        ذخیره
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="EditPageTwoColumnItemWithNoBG"></div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(EditServiceRules);

import React from "react";
import { Link } from "react-router-dom";
import "./CrudOperators.css";

export default function CrudOperators(props) {
  return (
    <div className="CrudContainer">
      <div className="ButtonsContainer">
        <div onClick={props.onClickOpenHandler} className="BackgorundPrimaryColor button-left">
          <span className="icon-add"></span>
          اضافه
        </div>
        {props.IsSelected === true ? (
          <>
            <Link
              to={{
                pathname: props.NavlinkAdress,
                state: { id: props.SelectedId, selectedData: props.selectedData ? props.selectedData : null },
              }}
              className="BackgorundPrimaryColor button-left">
              <span className="icon-pencil"></span>
              ویرایش
            </Link>
            <div onClick={props.onClickDeleteOpenHandler} className="BackgorundSecondaryColor button-left">
              <span className="icon-trash"></span>
              حذف
            </div>
          </>
        ) : (
          <>
            <div className="BackgorundPrimaryColor button-left ButtonDisabled">
              <span className="icon-pencil"></span>ویرایش
            </div>
            <div className="BackgorundSecondaryColor button-left ButtonDisabled">
              <span className="icon-trash"></span>حذف
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export const userReducer=(state={},action)=>{
    switch (action.type) {
        case 'LOGIN':
            return {...action.payload}
        case 'CLEAR_USER':
                return {...action.payload}
        default:
           return state;
    }
}


export const isLoggedReducer=(state=false,action)=>{
    switch (action.type) {
        case 'ISLOGGED':
            return action.payload
        default:
           return state;
    }
}

import React, { useEffect, useState } from "react";
import ResourcesGridTable from "./ResourcesGridTable";
import { actionGetAllResources } from "../../../actions";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";

import { useDispatch } from "react-redux";
import AddResourceSlidingMenu from "./AddResourceSlidingMenu";

export default function Resources() {
  useEffect(() => {
    getRoles();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);

  const getRoles = async (myPageIndex) => {
    dispatch(actionGetAllResources(1, pageSize));
  };

  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);

  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setGrayoutHandler(false);
  }

  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddResourceSlidingMenu onClickCloseHandler={slideMenuCloserHandler} />}
        <ResourcesGridTable resetGrid={getRoles} PassPageSize={pageSize} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { actionGetAllUnitTransactions } from "../../../actions";
import UnitTransactionsGridTable from "./UnitTransactionsGridTable";

export default function UnitTransactions(props) {
  useEffect(() => {
    getTransactions();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentTransactions, setCurrentTransactions] = useState();

  const getTransactions = async (myPageIndex) => {
    dispatch(actionGetAllUnitTransactions(props.UnitId, 1, pageSize));
  };

  return (
    <>
      <UnitTransactionsGridTable UnitId={props.UnitId} onClickRefresh={getTransactions} PassPageSize={pageSize} />
    </>
  );
}

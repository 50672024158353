import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";

export default function GatesGridTable(props) {
  const myGates = useSelector((state) => state.allGates);
  const [selectedCheckBox, setSelectedCheckBox] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  console.log(myGates);

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox} IsSelected={isSelected} NavlinkAdress={"/EditGate"} />
      {myGates ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">خوشامدگویی</div>
                <div className="attribute">وضعیت</div>
                <div className="attribute">تاریخ انقضا</div>
              </li>
              <form>
                {myGates.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    {/* ----------------------Row Number---------------------- */}
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="AnnouncementsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    {/* ----------------------Title---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Title">
                        {item.title}
                      </div>
                    </div>
                    {/* ----------------------IsActive---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Title">
                        {item.code}
                      </div>
                    </div>
                    {/* ----------------------expireDate---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Title">
                        {item.projectId}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
        </div>
      ) : (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">خوشامدگویی</div>
                <div className="attribute">وضعیت</div>
                <div className="attribute">تاریخ انقضا</div>
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { useDispatch, useSelector } from "react-redux";
import { actionDownloadAllTransactions, actionGetAllTransactions } from "../../../actions";
import TransactionsGridTable from "./TransactionsGridTable";
import { useLocation } from "react-router-dom";
import Pagination from "../../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { DownloadAllTransactionsByChargeId } from "../../../Services/managementServices";
import config from "../../../Services/config.json";

export default function Transactions() {
  const location = useLocation();
  const { ChargeId } = location.state || {};
  console.log(ChargeId && { ChargeId });
  const token = JSON.parse(localStorage.getItem("residentialManagement"));
  useEffect(() => {
    getBuildingTransactions();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const [currentBuildingTransactions, setCurrentBuildingTransactions] = useState();

  const getBuildingTransactions = async (myPageIndex) => {
    dispatch(actionGetAllTransactions(ChargeId, 1, pageSize));
  };
  const myTransactions = useSelector((state) => state.selectedTransAction);
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentBuildingTransactions(item);
    setGrayoutHandler(true);
  }
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  const DownloadExpenses = () => {
    const fileUrl = `${config.PardikURL}/charge/charge-report/${ChargeId}`;

    fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.userData.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "transactions.csv";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        <div className="CrudContainer">
          <div className="ButtonsContainer">
            <div onClick={DownloadExpenses} className="BackgorundPrimaryColor button-left">
              <span className="icon-add"></span>
              خروجی
            </div>
          </div>
        </div>
        {myTransactions.entities ? (
          <div className="GridViewAndPaginationContainer">
            <div className="TableGridViewContainer">
              <ol className="collection collection-container">
                <li className="item item-container">
                  <div className="attribute Firstchild">شماره</div>
                  <div className="attribute">شماره واحد</div>
                  <div className="attribute">نام واحد (درصورت وجود)</div>
                  <div className="attribute">مبلغ</div>
                  <div className="attribute">وضعیت پرداخت</div>
                </li>
                <form>
                  {myTransactions.entities.map((item, index) => (
                    <li key={index + 1} className="item item-container">
                      {/* ----------------------Row Number---------------------- */}
                      <div className="attribute Firstchild" data-name="#">
                        {index + 1}
                      </div>
                      {/* ----------------------Title---------------------- */}
                      <div className="attribute-container part-id">
                        <div className="attribute" data-name="Title">
                          {item.unitNumber}
                        </div>
                      </div>
                      <div className="attribute-container part-id">
                        <div className="attribute" data-name="Title">
                          {item.unitTitle}
                        </div>
                      </div>
                      <div className="attribute-container part-id">
                        <div className="attribute" data-name="Title">
                          {item.cost}
                        </div>
                      </div>
                      <div className="attribute-container part-id">
                        <div className="attribute" data-name="Role Name">
                          {item.isPayed ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>}
                        </div>
                      </div>
                    </li>
                  ))}
                </form>
              </ol>
            </div>
            <Pagination
              PageIndex={myTransactions.pageIndex}
              PageSize={pageSize}
              TotalCount={myTransactions.totalCount}
              TotalPages={myTransactions.totalPages}
              HasPreviousPage={myTransactions.hasPreviousPage}
              HasNextPage={myTransactions.hasNextPage}
              ChargeId={ChargeId}
              actionType="Transactions"
            />
          </div>
        ) : (
          <div className="GridViewAndPaginationContainer">
            <div className="TableGridViewContainer">
              <ol className="collection collection-container">
                <li className="item item-container">
                  <div className="attribute Firstchild">شماره ردیف</div>
                  <div className="attribute">خوشامدگویی</div>
                  <div className="attribute">وضعیت</div>
                  <div className="attribute">تاریخ انقضا</div>
                </li>
              </ol>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

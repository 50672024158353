import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllWeatherForecastCities } from "../../../actions";

export default function WeatherForecast() {
  const myWeatherForecast = useSelector((state) => state.allWeatherCities);

  useEffect(() => {
    getWeatherForecastCities();
  }, []);

  const dispatch = useDispatch();

  const getWeatherForecastCities = async () => {
    dispatch(actionGetAllWeatherForecastCities());
  };

  return (
    <>
      {Object.values(myWeatherForecast).length > 0 ? ( // Check if myWeatherForecast has any values
        <div className="ContentBody">
          <div className="BodyPadding">
            <div className="EditPageTwoColumnNoBG">
              <div className="EditPageTwoColumnItemWithBG">
                <div className="FullWidthFlex FullWidthFlexGap">
                  <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId">
                    <option value="Choose a Device Type" disabled>
                      لطفا انتخاب کنید
                    </option>
                    {Object.values(myWeatherForecast).map((item, index) => (
                      <option key={item.id} value={item.id}>
                        {item.cityTitle}
                      </option>
                    ))}
                  </select>
                </div>

                <div className=" FullWidthContainer">
                  <hr></hr>
                  <div className="ButtonsContainer">
                    <button type="submit" className="SaveButton ButtonDisabled" id="SaveButton">
                      <span className="icon-SaveButton"></span>
                      ذخیره
                    </button>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

import React from "react";

export default function CardViewExpenses() {
  return (
    <div className="CardViewContainerExpenses">
      <div className="CardIconContainer">
        <span className="icon-user CardIconBackground"></span>
      </div>
      <div className="CardViewElements ">
        <div className="CardViewTitleInfo">هزینه جاری مجموعه: 1,152,000,000 ریال</div>
        <div className="CardViewSupplementaryInfo">پرداخت شده: 895,700,000</div>
        <div className="CardViewSupplementaryInfo">پرداخت نشده: 256,300,000</div>
      </div>
    </div>
  );
}

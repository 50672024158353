import React from "react";
import "../modal.css";

export default function ImageModal(props) {
  return (
    <form className="ModalContainer">
      <div className="ImageModalHeader">
        <div className="ModalTitle">Image Preview</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX ModalCloseButton"></span>
      </div>
      <div className="FullWidthImageContainer">{props.FileType === "Image" ? <img alt="" className="ModalImage" src={props.UploadedImage} /> : <span className={props.UploadedImage + " ModalIcon ModalImage"} />}</div>
    </form>
  );
}
